export default {
  allPositions: [],
  assetsGallery: [],
  positionsTransactions: [],
  positionById: {
    entity: {
      id: '',
      legalForm: '',
      title: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      streetAddress: '',
      logo: '',
      bankName: '',
      bankAddress: '',
      bankBranchAddress: '',
      bankCode: '',
      bankSwift: '',
      bankAccountName: '',
      bankAccountNumber: '',
      contacts: [],
      owner: {},
    },
    security: {
      vehicle: {
        assets: [],
        project:[],
      },
    },
  },
};
