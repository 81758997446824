import {joinParts} from "@/lib/api/url";
import {FloteRequest} from "@/lib/api/request/FloteRequest";
import {ListResponse} from "@/lib/api/response/ListResponse";
import {Pojo} from "@/lib/types";
import qs from "qs/lib/stringify";

export class BaseEndpointGroup {
    protected parent?: BaseEndpointGroup;

    constructor(parent?: BaseEndpointGroup) {
        this.parent = parent;
    }

    public readonly relativeUri: string = "";

    public getFullUrl(...uri: string[]) {
        if (this.parent) {
            return this.parent.getFullUrl(this.relativeUri, ...uri);
        } else {
            return joinParts(this.relativeUri, ...uri);
        }
    }

    public withQuery(url: string, query: Pojo) {
        return url + "?" + qs(query);
    }

    public create() {
        return this.post(this.getFullUrl());
    }

    public edit(id: string) {
        return this.patch(this.getFullUrl(id));
    }

    public downloadCsv() {
        return this.download(this.getFullUrl("download"));
    }

    public delete(id: string) {
        return this.request(this.getFullUrl(id)).method("delete");
    }

    public get(id: string) {
        return this.request(this.getFullUrl(id));
    }

    public list(): FloteRequest<ListResponse<any>> {
        return this.request(this.getFullUrl()).perPage(1000).asResponse(ListResponse);
    }

    public post(url: string) {
        return this.request(url).method("post");
    }

    public patch(url: string) {
        return this.request(url).method("patch");
    }

    public download(url: string) {
        return this.request(url).asDownload();
    }

    public request(url: string) {
        return new FloteRequest(url);
    }
}
