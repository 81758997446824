













import {Component, Vue} from "vue-property-decorator";
import ConfirmationModal from '@/components/ui/modals/ConfirmationModal.vue';
import {ModalState} from "@/core/modals/ModalState";
import {config} from "@/lib/config";

@Component({
    components: {ConfirmationModal}
})
export default class AuthErrorModal extends Vue {
    public errorModalState = new ModalState();

    public get message() {
        let message = config.MODAL_ERROR_MESSAGES[this.errorName] || {};
        message = {
            title: message.title || "Error occurred",
            body: message.body || "",
        }
        message.body = message.body.replace("{{ contact_link }}", this.contactLink);

        return message;
    }

    public get errorName() {
        if (typeof this.$route.query['error'] === "string") {
            return this.$route.query['error'];
        }
        return null;
    }

    public get hasError() {
        return !!this.errorName;
    }

    public get contactAtParam() {
        return this.$route.query['contact_at'];
    }

    public get contactLink() {
        if (!this.contactAtParam) {
            return "";
        }
        return `at <a href="mailto:${this.contactAtParam}" target="_blank">${this.contactAtParam}</a>`
    }

    public created() {
        if (this.hasError) {
            this.errorModalState.open();
        }
    }
}
