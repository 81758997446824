








import {namespace} from "vuex-class";
import {Component, Vue} from 'vue-property-decorator'
import {Sponsor} from "@/services/entities/investor/Sponsor";
import ErrorModal from "@/components/ui/modals/ErrorModal.vue";

const DEFAULT_TITLE = "";
let sponsorModule = namespace('Sponsor');
@Component({
    components: {ErrorModal}
})
export default class App extends Vue {
    @sponsorModule.State
    private sponsor!: Sponsor;

    get pageTitle() {
        return this.sponsor.platformName || DEFAULT_TITLE;
    }
}
