













import {guidGenerator} from "@/services/helpers/sponsor/randomId";
import constants from "../../../config/sponsor/constants";
import {SET_MODALS} from "@/services/store/sponsor/storeConfigs/mutationTypes";
import FloteComponent from "@/components/sponsor/core/FloteComponent";
import {Component, Prop} from "vue-property-decorator";
import MapModal from "../../sponsor/ui/modals/MapModal.vue";
import {PointCoordinates} from "@/lib/types";

@Component({
    components: {
        MapModal
    },
})
export default class LocationInput extends FloteComponent {
    public mapModal = constants.modals.mapModal;

    @Prop({default: null})
    public placeholder!: string;

    @Prop({default: () => guidGenerator()})
    public id!: string;

    @Prop({default: null})
    public value: PointCoordinates;

    public get displayValue(): string {
        return this.value ? this.value.join(", ") : null;
    }

    public updateCoordinates(coordinates: PointCoordinates) {
        this.$emit("input", coordinates);
    }

    public openModal(data) {
        this.$store.commit(SET_MODALS, data);
    }

    public checkModal(data) {
        return this.$store.getters.checkModals.includes(data);
    }
}
