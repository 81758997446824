import {decimalOrZero, NumericValue} from "@/services/helpers/sponsor/math/Decimal.ts";
import {config} from "@/lib/config";

const HUMAN_READABLE_SUFFIXES = ["K", "M", "B"];

export function formatNumberHumanReadable(value: NumericValue) {
    const maxDecimalPlaces = 2;
    value = decimalOrZero(value);
    let suffix = "";
    for (const s of HUMAN_READABLE_SUFFIXES) {
        if (value.isLessThan(1000)) {
            break;
        }
        value = value.dividedBy(1000);
        suffix = s;
    }

    return formatNumbersInNormalForm(value.decimalPlaces(maxDecimalPlaces)) + suffix;
}

export function formatNumericValue(value: NumericValue, decimalPoints = config.NUMBER_FORMATTING_DECIMAL_PLACES) {
    value = decimalOrZero(value);
    return value.toFormat(decimalPoints);
}

export function formatNumericValueLong(value: NumericValue, decimalPoints = config.LONG_NUMBER_FORMATTING_DECIMAL_PLACES) {
    return formatNumericValue(value, decimalPoints);
}

export function formatAsInteger(value: NumericValue) {
    return formatNumericValue(value, 0);
}

export function formatNumbersInNormalForm(value: NumericValue) {
    value = decimalOrZero(value);
    return value.toFixed();
}

export function toStringOrNull(value) {
    if (!value) {
        return null;
    }
    return value.toString();
}

