import {ModuleMember} from "@/lib/store/modules/metadata/ModuleMember";
import {BaseModule} from "@/lib/store/modules/BaseModule";

export class Getter extends ModuleMember {
    public toVuex(module: BaseModule) {
        const key = this.key;
        if (this.descriptor.value) {
            return function (state) {
                return (...args) => module[key](...args);
            }
        }
        return function (state) {
            return module[key];
        }
    }
}
