import {
  SET_OFFERINGS,
  SET_SINGLE_OFFER,
  SET_INVESTORS_LIST,
  SET_PENDING_POSITIONS_LIST,
} from "../../storeConfigs/mutationTypes";

export default {
  [SET_OFFERINGS](state, data) {
    state.offerings = data;
  },
  [SET_SINGLE_OFFER](state, data) {
    state.singleOffer = data;
  },
  [SET_INVESTORS_LIST](state, data) {
    state.investorsInviteList = data;
  },
  [SET_PENDING_POSITIONS_LIST](state, data) {
    state.pendingPositions = data;
  },
};
