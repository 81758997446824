import {FloteApiError} from "@/lib/api/errors/FloteApiError";

export class InvalidRequestError extends FloteApiError {

}

export class InvalidInputError extends FloteApiError {
    public getErrors(): {[k: string]: string} {
        return this.rawError.response.data["errors"];
    }
}

export class UniqueConstraintError extends FloteApiError {

}

export class WrongCredentialsError extends FloteApiError {

}

export class UnauthorizedError extends FloteApiError {

}

export class InvalidTokenError extends FloteApiError {

}

export class ExportFailedError extends FloteApiError {

}

export const map = {
    "INVALID_REQUEST": InvalidRequestError,
    "INVALID_INPUT": InvalidInputError,
    "UNIQUE_CONSTRAINT_ERROR": UniqueConstraintError,
    "WRONG_CREDENTIALS": WrongCredentialsError,
    "UNAUTHORIZED": UnauthorizedError,
    "INVALID_TOKEN": InvalidTokenError,
    "EXPORT_FAILED": ExportFailedError,
}
