import actions from "./vehicleActions";
import getters from "./vehicleGetters";
import state from "./vehicleState";
import mutations from "./vehicleMutations";

const vehicle = {
  actions,
  getters,
  state,
  mutations,
};

export default vehicle;
