import axios, {AxiosRequestConfig, Method} from "axios";
import axiosRetry from "axios-retry";
import {AxiosInstance} from "axios";
import {Constructor, Pojo} from "@/lib/types";
import {FloteResponse, ResponseModel} from "@/lib/api/response/FloteResponse";
import {FileResponse} from "@/lib/api/response/FileResponse";
import {RequestInterceptor, RequestInterceptorClass} from "@/lib/api/request/RequestInterceptor";
import qs from "qs/lib/stringify";
import {BaseTokenManager} from "@/lib/api/auth/TokenManager";
import {BaseRequestInterceptor} from "@/lib/api/request/BaseRequestInterceptor";
import {RenewTokenInterceptor} from "@/lib/api/request/RenewTokenInterceptor";
import {FloteApiError} from "@/lib/api/errors/FloteApiError";
import {RequestErrorHandler} from "@/lib/api/request/RequestErrorHandler";
import {clonePojo} from "@/services/helpers/utils";
import {RequestQueue} from "@/lib/api/endpoints/RequestQueue";

export class FloteRequest<R extends FloteResponse = FloteResponse> implements Promise<R> {
    public ResponseClass: ResponseModel = FloteResponse;
    public params: AxiosRequestConfig;
    public forceResolveResponse: FloteResponse;
    public interceptors: RequestInterceptor[] = [];
    public waitPromise: (request: FloteRequest) => Promise<void> = null;
    public queue: RequestQueue = null;
    public tokenManager: BaseTokenManager = null;
    public errorHandlers: RequestErrorHandler<any, R>[] = [];

    constructor(url: string) {
        this.params = this.getInitialParams();
        this.params.url = url;
        this.interceptors.push(new BaseRequestInterceptor());
    }

    protected getInitialParams(): AxiosRequestConfig {
        return {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            paramsSerializer: function (params) {
                return qs(params, {arrayFormat: 'comma'})
            },
        };
    }

    protected getClient() {
        const client = axios.create(this.params);
        axiosRetry(client, {
            retries: 3,
            retryDelay: axiosRetry.exponentialDelay,
        });
        return client;
    }

    protected registerInterceptors(client: AxiosInstance) {
        for (const interceptor of this.interceptors) {
            client.interceptors.response.use(
                (r) => interceptor.onResponse(r),
                (e) => interceptor.onResponseError(e)
            );
            client.interceptors.request.use(
                (r) => interceptor.onRequest(r),
                (e) => interceptor.onRequestError(e)
            );
        }
    }

    public waitFor(promise?: (request: FloteRequest) => Promise<void>) {
        this.waitPromise = promise;
        return this;
    }

    public withQueue(queue: RequestQueue) {
        this.queue = queue;
        return this;
    }

    public on<T extends FloteApiError>(errorClass: Constructor<T>, handler: (request: FloteRequest, error: T) => Promise<R>) {
        this.errorHandlers.push(new RequestErrorHandler<T, R>(errorClass, handler));
        return this;
    }

    public interceptor(interceptorClass: RequestInterceptorClass) {
        const interceptor = new interceptorClass(this);
        this.interceptors.push(interceptor);
        return this;
    }

    public asResponse<NRM extends FloteResponse>(responseClass: Constructor<NRM>) {
        this.ResponseClass = responseClass as any as ResponseModel;
        return this as any as FloteRequest<NRM>;
    }

    public withCredentials(value: boolean) {
        this.params.withCredentials = value;
        return this;
    }

    public method(method: Method) {
        this.params.method = method;
        return this;
    }

    public url(url: string) {
        this.params.url = url;
        return this;
    }

    public filter(params: Pojo) {
        if (!this.params.params) {
            this.params.params = {};
        }
        this.params.params = {
            ...this.params.params,
            ...params
        };
        return this;
    }

    public query(params: Pojo) {
        return this.filter(params);
    }

    public data(data: Pojo) {
        this.params.data = this.params.data || {};
        this.params.data = {
            ...this.params.data,
            ...data
        };
        return this;
    }

    public form(form: FormData|Pojo) {
        const data = this.params.data;
        const newData = new FormData();

        if (data) {
            if (data instanceof FormData) {
                for (let [field, value] of data.entries()) {
                    newData.append(field, value);
                }
            } else {
                for (let field of Object.keys(data)) {
                    newData.append(field, data[field]);
                }
            }
        }

        if (form instanceof FormData) {
            for (let [field, value] of form.entries()) {
                newData.append(field, value);
            }
        } else {
            for (let field of Object.keys(form)) {
                newData.append(field, form[field]);
            }
        }

        this.params.data = newData;
        return this;
    }

    public attachFile(name: string, file: File) {
        this.form({
            [name]: file
        });
        return this;
    }

    public asDownload() {
        this.params.responseType = "blob";
        return this.asResponse(FileResponse);
    }

    public addHeader(name: string, value: string) {
        if (!this.params.headers) {
            this.params.headers = {};
        }
        this.params.headers[name] = value;
    }

    public resolve(response: Pojo | R) {
        if (response instanceof FloteResponse) {
            this.forceResolveResponse = response;
        } else {
            this.forceResolveResponse = new FloteResponse({
                data: response,
                headers: {},
                status: 200,
            });
        }
        return this;
    }

    public async execute(): Promise<R> {
        if (this.forceResolveResponse) {
            return this.forceResolveResponse as any;
        }
        if (this.waitPromise) {
            await this.waitPromise(this);
        }
        const executor = () => {
            const client = this.getClient();
            this.registerInterceptors(client);
            if (this.tokenManager) {
                this.addAuthToken(this.tokenManager.getAccessToken());
            }

            for (let handler of this.errorHandlers) {
                client.interceptors.response.use((v) => v, (error) => handler.handle(this, error));
            }
            return client.request(this.params).then((payload) => {
                return new this.ResponseClass(payload || {}) as R;
            });
        }

        if (this.queue) {
            return this.queue.enqueue(executor);
        } else {
            return executor();
        }
    }

    public addAuthToken(token: string) {
        this.addHeader("Authorization", `Bearer ${token}`);
    }

    public reAuth(tokenManager: BaseTokenManager) {
        this.interceptors = this.interceptors.filter(i => !(i instanceof RenewTokenInterceptor));
        this.tokenManager = tokenManager;
        return this;
    }

    public authenticate(tokenManager: BaseTokenManager) {
        this.tokenManager = tokenManager;
        this.interceptors.push(new RenewTokenInterceptor(tokenManager));
        return this;
    }

    public page(page: number) {
        return this.query({
            page: page
        });
    }

    public perPage(perPage: number) {
        return this.query({
            per_page: perPage
        });
    }

    protected copyParams(): AxiosRequestConfig {
        return clonePojo(this.params);
    }

    public copy() {
        const copy = new FloteRequest<R>(this.params.url);
        copy.ResponseClass = this.ResponseClass;
        copy.forceResolveResponse = this.forceResolveResponse;
        copy.queue = this.queue;
        copy.tokenManager = this.tokenManager;
        copy.interceptors = [...this.interceptors];
        copy.errorHandlers = [...this.errorHandlers];
        copy.params = this.copyParams();
        return this;
    }

    public readonly [Symbol.toStringTag]: string = "FloteRequest";

    catch<TResult = never>(onRejected?: ((reason: any) => (PromiseLike<TResult> | TResult)) | undefined | null): Promise<R | TResult> {
        return this.execute().catch(onRejected);
    }

    finally(onFinally?: (() => void) | undefined | null): Promise<R> {
        return this.execute().finally(onFinally);
    }

    then<TResult1 = R, TResult2 = never>(onFulfilled?: ((value: R) => (PromiseLike<TResult1> | TResult1)) | undefined | null, onRejected?: ((reason: any) => (PromiseLike<TResult2> | TResult2)) | undefined | null): Promise<TResult1 | TResult2> {
        return this.execute().then(onFulfilled, onRejected);

    }
}
