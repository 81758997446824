import {Router} from "@/lib/router/Router";
import {compose} from "@/lib/router/util";
import {Component} from "vue";
import {AsyncComponentFactory} from "vue/types/options";
import {RouteConfig} from "vue-router";

export abstract class Route {
    // @ts-ignore
    protected static instance: Route = null;
    protected children: Route[] = [];

    protected constructor() {
    }

    public static getInstance() {
        if (!this.instance) {
            // @ts-ignore
            this.instance = new this();
        }
        return this.instance;
    }

    public static get Name(): string {
        return this.name.replace(/Route$/, "");
    }

    public getName(): string {
        return (this.constructor as RouteClass).Name;
    }

    public abstract getPath(): string;

    public abstract getComponent();

    public getConfig(): {} {
        return {};
    }

    public getChildren(): Router {
        return compose(...this.children);
    }

    public toVueRoute(): RouteConfig {
        const params = {
            path: this.getPath(),
            name: this.getName(),
            component: lazyLoadView(this.getComponent()) as AsyncComponentFactory,
            ...this.getConfig()
        }
        const children = this.getChildren();
        if (children.getRoutes().length) {
            params["children"] = children.getVueRoutes();
        }

        return params;
    }

    public static addChild(child: Route) {
        this.getInstance().children.push(child);
    }

    public static addRouter(router: Router) {
        for (const route of router.getRoutes()) {
            this.addChild(route);
        }
    }
}

function lazyLoadView(AsyncView): Component {
    const AsyncHandler: AsyncComponentFactory = () => ({
        component: AsyncView,
        // A component to use while the component is loading.
        loading: require('@/components/sponsor/ui/loaders/DotLoader.vue').default,
        // Delay before showing the loading component.
        // Default: 200 (milliseconds).
        delay: 200,
        // A fallback component in case the timeout is exceeded
        // when loading the component.
        // todo: implement error page
        // error: require('@/components/sponsor/ui/loaders/DotLoader.vue').default,
        // Time before giving up trying to load the component.
        // Default: Infinity (milliseconds).
        // timeout: 10000,
    });

    return {
        functional: true,
        render(h, {data, children}) {
            // Transparently pass any props or children
            // to the view component.
            return h(AsyncHandler, data, children)
        },
    };
}

export type RouteClass = (typeof Route);
