import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {
    SET_ERROR_HANDLER,
    SET_NOTIFICATION_PREVIEW,
    SET_TRANSACTION_NOTIFICATIONS,
} from "@/services/store/sponsor/storeConfigs/mutationTypes";
import O_o from "@/services/helpers/sponsor/promiseHandler";
import http from "@/services/helpers/sponsor/managers/requestManager";
import {GET,  POST} from "@/services/helpers/sponsor/actionMethods";
import {
    NOTIFICATIONS,
    PREVIEW,
    TRANSACTIONS,
    VEHICLE_TRANSACTIONS,
} from "@/services/store/sponsor/storeConfigs/actionUrls";
import constants from "@/config/sponsor/constants";
import {Document} from "@/services/store/modules/documents/Document";
import Router from "@/services/router/router";
import {NotificationPreview} from "@/services/store/sponsor/modules/notifications/interfaces";
import {getNotificationStatus} from "@/lib/notifications/NotificationStatus";
import {NotificationChannel} from "@/services/store/sponsor/modules/notifications/enums";
import {Entity} from "@/services/store/modules/entities/Entity";
import {Vehicle} from "@/services/store/modules/vehicles/Vehicle";
import {decimal} from "@/services/helpers/sponsor/math/Decimal";

export interface Notification {
    capitalType: string,
    isPublished: boolean,
    isNotified: boolean,
    vehicle: Vehicle,
    description: string,
    positionTransactions: PositionTransactions[]
}

interface PositionTransactions {
    description: string,
    documents: Document[],
    id: string,
    recordedAmount
    latestNotification: {},
    positionState: PositionState,
    transactionType: string,
}

interface PositionState {
    entity: Entity
}

@Module({namespaced: true})
class NotificationsModule extends VuexModule {
    public transactionNotifications: Notification = {
        positionTransactions: [],
        capitalType: null,
        isPublished: null,
        isNotified: null,
        vehicle: null,
        description: null,
    };
    public notificationPreview: NotificationPreview = {
        notSendingReasons: [],
        payloads: []
    };

    @Mutation
    public [SET_TRANSACTION_NOTIFICATIONS](data) {
        this.transactionNotifications = data;
    };

    @Mutation
    public [SET_NOTIFICATION_PREVIEW](data) {
        this.notificationPreview = data;
    }

    @Action
    public async getTransactionNotifications(data) {
        const [error, response] = await O_o(http.send(GET(`${VEHICLE_TRANSACTIONS}/${data.id}${NOTIFICATIONS}`, constants.isLoadingStates.notificationTable)));
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_TRANSACTION_NOTIFICATIONS, response);
        return response;
    };

    @Action
    public async getNotificationPreview(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});
        const [error, response] = await O_o(http.send(GET(`${NOTIFICATIONS}${TRANSACTIONS}${PREVIEW}/${data.id}`)));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_NOTIFICATION_PREVIEW, response);
        return response;
    }

    @Action
    public async sendTestNotification(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});

        const [error, response] = await O_o(http.send(POST(`${NOTIFICATIONS}${TRANSACTIONS}/test/${data.positionTransactionId}`), {
            email: data.email,
            attachDocs: data.attachDocs
        }));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

        return response;
    }

    @Action
    async notify(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});

        const [error, response] = await O_o(http.send(POST(`${NOTIFICATIONS}${TRANSACTIONS}/send`), {
            triggerIds: data.ids,
            attachDocs: data.attachDocs
        }));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

        return response;
    }

    get preview() {
        return this.notificationPreview.payloads.find(i => i.channel === NotificationChannel.Email)
    }

    //TODO: Remove by transaction type filtering after backend filtering is ready.
    get notificationsList() {
        const transactionType = Router.currentRoute.params.transactionType;
        return this.transactionNotifications.positionTransactions.map((item) => {
            return {
                id: item.id,
                firstName: item.positionState.entity.owner.firstName,
                lastName: item.positionState.entity.owner.lastName,
                entityName: item.positionState.entity.entityName,
                recordedAmount: item.recordedAmount,
                email: item.positionState.entity.owner.email,
                isActive: item.positionState.entity.owner.isActive,
                isZeroAmount: decimal(item.recordedAmount).isZero(),
                isRegistered: item.positionState.entity.owner.isRegistered,
                notificationStatus: getNotificationStatus(item),
                transactionType: item.transactionType,
                isChecked: false,
                documents: item.documents
            }
        }).filter(item => item.transactionType === transactionType);
    }
}

export default NotificationsModule;
