import {InvestorApi, SponsorApi} from "@/services/api/PortalApi";
import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {config} from "@/lib/config";

export class FloteApi extends FloteEndpointGroup {
    public readonly sponsor = new SponsorApi(this);
    public readonly investor = new InvestorApi(this);

    public relativeUri = config.API_BASE_URL;
}

export const api = new FloteApi();
