function sortEquityListByOwnerName(equityList) {
    return equityList.sort((a, b) => a.entity.owner.firstName.localeCompare(b.entity.owner.firstName));
}

function sortPositionGroupByUserName(group) {
    return group.sort((a, b) => a.user.firstName.value.localeCompare(b.user.firstName.value));
}

function sortEquityListByProjectName(equityList) {
    return equityList.sort((a, b) => a.security.vehicle.project.name.localeCompare(b.security.vehicle.project.name));
}

export default {
    getSortedCommonEquityList(state) {
        return {
            ...state.commonEquity,
            items: sortEquityListByOwnerName(state.commonEquity.items),
        };
    },
    getSortedUserPositionsList(state) {
        return {
            ...state.positions,
            items: sortEquityListByProjectName(state.positions.items),
        }
    },
    getCommonEquityListWithCheckboxes(state) {
        let mappedItems = state.commonEquity.items.map(item => {
            return {isChecked: false, ...item}
        });
        let commonEquity = state.commonEquity;
        commonEquity.items = sortEquityListByOwnerName(mappedItems);
        return commonEquity;
    },
    getPositionFirstGroup(state) {
        let firstGroup = state.newPositionData.groups[0];
        return {
            ...firstGroup,
            positions: sortPositionGroupByUserName(firstGroup.positions),
        };
    },
    getSortedNewPositionGroups(state) {
        return state.newPositionData.groups.map(group => {
            return {
                ...group,
                positions: sortPositionGroupByUserName(group.positions),
            };
        });
    },
};
