//TODO: use axios params instead of this query params builder
interface QueryParams {
    search?: string;
    startDate?: Date;
    endDate?: Date;
    groupStartDate?: Date;
    groupEndDate?: Date;
    startYear?: string;
    endYear?: string;
    types?: string[];
    tags?: string[];
    onlyRelatedToVehicle?: boolean;
    onlyRelatedToPosition?: boolean;
    onlyRelatedToProject?: boolean;
    vehicleId?: string;
    vehicleIdArray?: string[];
    typesArray?: string[];
    projectId?: string;
    perPage?: number;
    page?: number;
}

export function getDocumentQuery(params: QueryParams): string {
    if (params.startDate && params.startYear) {
        throw new Error("Cannot specify startDate and startYear at the same time.");
    }

    if (params.endDate && params.endYear) {
        throw new Error("Cannot specify endDate and endYear at the same time.");
    }

    let query = "";
    let separator = "?";
    if (params.search) {
        query += `${separator}q=${params.search}`;
        separator = "&";
    }

    if (params.startDate) {
        query += `${separator}f_range_from=${params.startDate}`;
        separator = "&";
    }

    if (params.endDate) {
        query += `${separator}f_range_to=${params.endDate}`;
        separator = "&";
    }

    if (params.groupStartDate) {
        query += `${separator}f_effective_date_from=${params.groupStartDate}`;
        separator = "&";
    }

    if (params.groupEndDate) {
        query += `${separator}f_effective_date_to=${params.groupEndDate}`;
        separator = "&";
    }

    if (params.types && params.types.length && params.types.join() !== "") {
        query += `${separator}f_type=${params.types.join()}`;
        separator = "&";
    }

    if (params.typesArray && params.typesArray.length && params.typesArray.join() !== "") {
        query += `${separator}f_type=${params.typesArray.join()}`;
        separator = "&";
    }

    if (params.tags && params.tags.length && params.tags.join() !== "") {
        query += `${separator}f_tags_overlap=${params.tags.join()}`;
        separator = "&";
    }

    if (typeof params.onlyRelatedToVehicle === "boolean") {
        query += `${separator}f_is_vehicle_related=${params.onlyRelatedToVehicle}`;
        separator = "&";
    }

    if (typeof params.onlyRelatedToPosition === "boolean") {
        query += `${separator}f_is_position_related=${params.onlyRelatedToPosition}`;
        separator = "&";
    }

    if (typeof params.onlyRelatedToProject === "boolean") {
        query += `${separator}f_is_project_related=${params.onlyRelatedToProject}`;
        separator = "&";
    }

    if (params.vehicleId) {
        query += `${separator}f_vehicle_id=${params.vehicleId}`;
        separator = "&";
    }

    if (params.projectId) {
        query += `${separator}f_project_id=${params.projectId}`;
        separator = "&";
    }

    if (params.perPage) {
        query += `${separator}per_page=${params.perPage}`;
        separator = "&";
    }

    if (params.page) {
        query += `${separator}page=${params.page}`;
        separator = "&";
    }
    if (params.vehicleIdArray && params.vehicleIdArray.length && params.vehicleIdArray.join() !== "") {
        query += `${separator}f_vehicle_id=${params.vehicleIdArray}`;
        separator = "&";
    }

    if (params.startYear) {
        query += `${separator}f_range_from=${params.startYear}`;
        separator = "&";
    }

    if (params.endYear) {
        query += `${separator}f_range_to=${params.endYear}`;
        separator = "&";
    }

    return query;
}
