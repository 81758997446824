import {Pojo} from "@/lib/types";

export function datesMax(...dates: Date[]) {
    return dates.reduce((dateOne, dateTwo) => {
        return dateOne > dateTwo ? dateOne : dateTwo;
    });
}

export function now() {
    return new Date();
}

export function clonePojo(obj: Pojo) {
    if (obj instanceof Array) {
        return obj.map(i => clonePojo(i));
    }
    if (typeof obj === "object") {
        const copy: Pojo = {};
        for (let key in obj) {
            copy[key] = clonePojo(obj[key]);
        }
        return copy;
    }
    return obj;
}
