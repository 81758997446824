// Chart Mutations
const SET_LINE_CHART_DATA = 'setLineChartData';
const SET_DOUGHNUT_DATA = 'setDoughnutData';

// Document Mutations
const SET_OFFERING_DOCUMENTS = 'setOfferingDocuments';

// Vehicles
const SET_SUGGESTED_VEHICLES_LIST = 'setSuggestedVehiclesList';

// Entity Mutations
const SET_ENTITIES = 'setEntities';
const SET_ENTITY_BY_ID_INFO = 'setEntityByIdInfo';
const SET_CONTACTS = 'setContacts';

// Position Mutations
const SET_ALL_POSITIONS = 'setAllPositions';
const SET_POSITION_BY_ID = 'setPositionById';
const SET_POSITIONS_TRANSACTION = 'setPositionsTransaction';

// User Mutations
const SET_CURRENT_USER_INFO = 'setCurrentUserInfo';
const SET_PASSWORD_CHANGE = 'setPasswordChange';

//  Load
const REMOVE_IS_LOADED = 'removeIsLoaded';
const SET_IS_LOADED = 'setIsLoaded';
const SET_IS_LOADING = 'setIsLoading';
const REMOVE_IS_LOADING = 'removeIsLoading';

// Offerings
const SET_SINGLE_OFFER = 'setSingleOffer';
const SET_OFFERINGS = 'setOfferings';
const SET_IS_AMOUNT_ENTERED = 'SetIsAmountEntered';

// NDA
const SET_NDA = 'setNda';

// Risk Factors
const SET_RISK_FACTORS = 'setRiskFactors';

// State, country
const SET_ENTITY_STATES_LIST = 'setEntityStatesList';
const SET_BANK_STATES_LIST = 'setBankStatesList';
const SET_SIGNATORY_STATES_LIST = 'setSignatoryStatesList';
const SET_COUNTRIES_LIST = 'setCountriesList';

// Modals
const SET_IS_MODALS_SHOWN = 'setIsModalsShown';
const REMOVE_IS_MODAL_SHOWN = 'removeIsModalShown';
const SET_SUCCESS_MODAL_TEXT = 'setSuccessModalText';

const SET_SPONSOR_INFO = 'setSponsorInfo';
const SET_ERROR_HANDLER = 'setErrorHandler';

export {
    SET_LINE_CHART_DATA,
    SET_DOUGHNUT_DATA,
    SET_OFFERING_DOCUMENTS,
    SET_ENTITIES,
    SET_ENTITY_BY_ID_INFO,
    SET_CONTACTS,
    SET_ALL_POSITIONS,
    SET_POSITION_BY_ID,
    SET_POSITIONS_TRANSACTION,
    SET_CURRENT_USER_INFO,
    SET_PASSWORD_CHANGE,
    REMOVE_IS_LOADED,
    SET_IS_LOADED,
    SET_SINGLE_OFFER,
    SET_OFFERINGS,
    SET_NDA,
    SET_IS_AMOUNT_ENTERED,
    SET_RISK_FACTORS,
    SET_ENTITY_STATES_LIST,
    SET_BANK_STATES_LIST,
    SET_SIGNATORY_STATES_LIST,
    SET_COUNTRIES_LIST,
    SET_SUGGESTED_VEHICLES_LIST,
    SET_IS_MODALS_SHOWN,
    REMOVE_IS_MODAL_SHOWN,
    REMOVE_IS_LOADING,
    SET_IS_LOADING,
    SET_SUCCESS_MODAL_TEXT,
    SET_ERROR_HANDLER,
    SET_SPONSOR_INFO,
};
