import {Validator} from "@/lib/forms/validators/Validator";
import {FormatterClass, ValueFormatter} from "@/lib/formatters/ValueFormatter";
import {ValidationError} from "@/lib/forms/validators/ValidationError";
import {validationErrorMessages} from "@/config/sponsor/validationErrorConfigs";
import {FloteApi} from "@/services/api/FloteApi";
import {FlotePortalApi} from "@/services/api/PortalApi";
import {FloteRequest} from "@/lib/api/request/FloteRequest";

export type InputRefTypes = HTMLInputElement | HTMLSelectElement | HTMLAreaElement;

export abstract class FormField<T> {
    public name: string;
    public value: T;
    public initialValue: T;
    public isRequired: boolean = false;
    public isDisabled: boolean = false;
    public inputType: string = "text";
    public inputMode: string = "text";
    public localErrors: ValidationError[] = [];
    public backendErrors: ValidationError[] = [];
    public abstract validator: Validator<T>;
    public abstract formatter: ValueFormatter<T>;
    private showingErrors: boolean = false;
    public inputRef: InputRefTypes;
    public requiredValidationErrorMessage: string = validationErrorMessages.required;

    constructor(name: string, value: T) {
        this.name = name;
        this.initValue(value);
    }

    public initValue(value: any) {
        this.value = value;
        this.initialValue = value;
    }

    public load(api: FloteApi, portalApi: FlotePortalApi) {

    }
    public get loaded() {
        return true;
    }

    public get hasChanged() {
        return this.value !== this.initialValue;
    }

    public reset() {
        this.value = this.initialValue;
        this.clearErrors();
    }

    public get isValid() {
        return this.allErrors.length === 0;
    }

    public get validationErrors() {
        const errors = this.validator.validate(this.value);
        if (this.isRequired && this.value === null) {
           errors.push(new ValidationError(this.requiredValidationErrorMessage));
        }

        return errors;
    }

    public get allErrors() {
        return this.validationErrors.concat(this.backendErrors);
    }

    public get errorMessages() {
        if (!this.showingErrors) {
            return [];
        }
        return this.allErrors.map(i => i.message);
    }

    public clearErrors() {
        this.localErrors = [];
        this.backendErrors = [];
        this.showingErrors = false;
    }

    public validate() {
        this.clearErrors();
        this.showingErrors = true;
    }

    public serialize(): any {
        return this.value;
    }

    public modifyRequest(req: FloteRequest) {
        return req;
    }

    public resetBackEndErrors() {
        this.backendErrors = [];
    }

    public addBackEndError(key: string, message: string) {
        this.backendErrors.push(new ValidationError(message));
    }

    public elementRef(el: InputRefTypes) {
        this.inputRef = el;
    }

    public focusOnError() {
        if (this.inputRef) {
            this.inputRef.focus();
        }
    }

    public onFocus() {

    }

    public onBlur() {
        this.validate();
    }

    public required() {
        this.isRequired = true;
        return this;
    }

    public optional() {
        this.isRequired = false;
        return this;
    }

    public disabled(){
        this.isDisabled = true;
        return this;
    }
    public editable(){
        this.isDisabled = false;
        return this;
    }

    public withValidator(validator: () => Validator<T>) {
        this.validator = validator();
        return this;
    }

    public withFormatter(formatterClass: FormatterClass<T>) {
        this.formatter = new formatterClass();
        return this;
    }
}
