import {formatNumericValue, formatNumericValueLong} from './filters/numberFormatter';
import {EINFormatter, SSNFormatter} from "@/lib/formatters/TaxIdFormatter";
import {PhoneNumberFormatter} from "@/lib/formatters/PhoneNumberFormatter";
//TODO write test for these functions.

const masks = {
    currency: {
        mask(value) {
            if (typeof value !== 'string' || value === null) {
                return "";
            }
            if (value !== 0 || value) {
                return "$ " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        unmask(value) {
            value = parseFloat(value.toString().replace(/[^\d\.]/g, ""));
            return value === '' || isNaN(value) ? null : value;
        },
    },
    percentage: {
        mask(value) {
            if (value === null) {
                return "";
            }
            if (value === 0 || value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " %";
            }
        },
        unmask(value) {
            value = parseFloat(value.toString().replace(/[^\d\.]/g, ""));
            return isNaN(value) || value === '' ? null : value;
        },
    },

    showTwentyDigits: {
        mask(value) {
            if (typeof value !== 'string' || value === null) {
                return "";
            }
            if (value === 0 || value) {
                return (formatNumericValueLong(value)) + "%";
            }
        },
        unmask(value) {
            if (value === '') {
                return null;
            }
            return (formatNumericValueLong(value)).toString().replace('%', "");
        },
    },
    addPercentage: {
        mask(value) {
            if (typeof value !== 'string' || value === null) {
                return "";
            }
            if (value === 0 || value) {
                return (formatNumericValue(value)) + "%";
            }
        },
        unmask(value) {
            if (value === '') {
                return null;
            }
            return (formatNumericValue(value)).toString().replace('%', "");
        },
    },
    //TODO when refactoring the inputs, remove these masks
    taxIdSSN: new SSNFormatter(),
    taxIdEIN: new EINFormatter(),
    phoneNumber: new PhoneNumberFormatter(),
};

export default masks;
