export default {
    singleVehicle: {
        assets: [],
        project: {},
    },
    allVehicles: [],
    transactionsById: [],
    projectVehicles: [],
    vehiclesByUserId: [],
};
