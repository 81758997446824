import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const DocumentsRouter = new Router();

@DocumentsRouter.register()
export class DocumentsRoute extends Route {
    getComponent() {
        return import("@/views/Documents.vue");
    }

    getPath(): string {
        return "documents";
    }
}

@DocumentsRouter.register()
export class NonTransactionalDocumentUploadRoute extends Route {
    getComponent() {
        return import('@/components/sponsor/sections/DocumentUploadPages/NonTransactionalDocuments/NonTransactionalDocumentUpload.vue');
    }

    getPath(): string {
        return "vehicles/:vehicleId/documents/upload";
    }
}

@DocumentsRouter.register()
export class SingleNonTransactionalDocumentRoute extends Route {
    getComponent() {
        return import('@/components/sponsor/sections/DocumentUploadPages/NonTransactionalDocuments/DocumentSinglePage.vue');
    }

    getPath(): string {
        return "vehicles/:vehicleId/documents/nonTransactional/:documentGroupId";
    }
}

@DocumentsRouter.register()
export class TransactionalDocumentUploadRoute extends Route {
    getComponent() {
        return import('@/components/sponsor/sections/DocumentUploadPages/TransactionalDocuments/TransactionalDocumentUpload.vue');
    }

    getPath(): string {
        return "vehicles/:vehicleId/documents/:documentGroupId/upload";
    }
}

@DocumentsRouter.register()
export class EditTransactionalDocumentRoute extends Route {
    getComponent() {
        return import('@/components/sponsor/sections/DocumentUploadPages/TransactionalDocuments/TransactionalDocumentUpload.vue');
    }

    getPath(): string {
        return "vehicles/:vehicleId/documents/:documentGroupId";
    }
}
