import "reflect-metadata";
import {BaseModule, ModuleClass} from "@/lib/store/modules/BaseModule";
import {Constructor} from "@/lib/types";

export class SubModule {
    public readonly parentModuleClass: ModuleClass;
    public readonly moduleClass: ModuleClass;
    public readonly key: string;
    public readonly isNamespaced: boolean;
    public readonly name: string;
    public readonly descriptor: PropertyDescriptor;
    constructor(parentModule: ModuleClass, module: ModuleClass, key: string, descriptor: PropertyDescriptor, isNamespaced: boolean) {
        this.parentModuleClass = parentModule;
        this.moduleClass = module;
        this.key = key;
        this.name = key;
        this.descriptor = descriptor;
        this.isNamespaced = isNamespaced;
    }

    public static Register(namespaced: boolean = false) {
        return (target: BaseModule, key?: string, descriptor?: TypedPropertyDescriptor<BaseModule>) => {
            const parentModule = target.constructor as ModuleClass;
            const meta = parentModule.getOrInitMeta();
            const module = Reflect.getMetadata("design:type", target, key);
            const member = new this(parentModule, module, key, descriptor, namespaced);
            meta.addModule(member);
        }
    }

    public static Namespaced() {
        return this.Register(true);
    }

    public getInstance(parent: BaseModule) {
        return new this.moduleClass(parent);
    }

    public copy(): this {
        return new (this.constructor as Constructor<this>)(this.parentModuleClass, this.moduleClass, this.key, this.descriptor, this.isNamespaced);
    }
}
