import {FlotePortalApi} from "@/services/api/PortalApi";
import {Navigation} from "@/core/navigation/Navigation";
import {namespace} from "vuex-class";
import {api} from "@/services/api/FloteApi";
import router from "@/services/router/router";
import {BaseTokenManager} from "@/lib/api/auth/TokenManager";

enum FlotePortal {
    Investor = "investor",
    Sponsor = "sponsor"
}

export class Portal {
    public name: string;
    public api: FlotePortalApi;
    public navigator: Navigation;
    public tokenManager: BaseTokenManager;
    public isPrivileged: boolean;

    public module(name: string) {
        return namespace(`${this.name}/${name}`);
    }

    private static currentPortal: Portal;

    public static get Current() {
        if (!this.currentPortal) {
            this.currentPortal = getPortal();
        }

        return this.currentPortal;
    }
}

export class SponsorPortal extends Portal {
    public name = FlotePortal.Sponsor;
    public api = api.sponsor;
    public tokenManager = api.sponsor.tokenManager;
    public isPrivileged = true;
}

export function getSponsorPortal() {
    return new SponsorPortal();
}

export class InvestorPortal extends Portal {
    public name = FlotePortal.Investor;
    public api = api.investor;
    public tokenManager = api.investor.tokenManager;
    public isPrivileged = false;
}

export function getInvestorPortal() {
    return new InvestorPortal();
}

export function getPortal() {
    const route = router.currentRoute;
    if (route.path.startsWith("/admin/")) {
        return getSponsorPortal();
    }
    return getInvestorPortal();
}
