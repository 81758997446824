import ResourceListModule from "@/services/store/lib/ResourceListModule";
import {FiltersState} from "@/lib/store/state/filters/FiltersState";
import {ListState, SelectableItem} from "@/services/store/lib/ListState";
import {Action} from "@/lib/store/modules/Action";
import {VehicleTransactionsApi} from "@/services/api/VehicleTransactionsApi";
import {VehicleTransaction} from "@/services/store/modules/vehicles/VehicleTransaction";
import {VehicleTransactionFilters} from "@/services/store/modules/vehicles/VehicleTransactionFilters";

export type VehicleTransactionsListState = ListState<VehicleTransaction, {}, VehicleTransactionFilters>;

export class VehicleTransactionsListModule extends ResourceListModule<VehicleTransaction> {
    APIType!: VehicleTransactionsApi;
    public defaultExportFileName = "transactions.csv";

    public get api(): this["APIType"] {
        return this.portalApi.vehicleTransactions;
    }

    public getFilters(): FiltersState {
        return new VehicleTransactionFilters();
    }

    public getListItem(item: VehicleTransaction): SelectableItem<VehicleTransaction> {
        item = super.getListItem(item);
        return item as SelectableItem<VehicleTransaction>;
    }

    @Action.Register()
    public async downloadCsv(pld, ctx) {
        this.showGlobalLoader(null, ctx);
        const res = await this.api.downloadCsv();
        res.save("transactions.csv");
        this.hideGlobalLoader(null, ctx);
    }
}
