import Vue from "vue";
import Router from "vue-router";
import {SponsorRouter} from "@/services/router/sponsor";
import {InvestorRouter} from "@/services/router/investor";
import {FloteRouter} from "@/services/router/flote";

Vue.use(Router);

export default new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {selector: to.hash};
        }
        return {x: 0, y: 0};
    },
    // todo: use before route enter to load data and do authentication
    routes: [
        ...SponsorRouter.getVueRoutes(),
        ...FloteRouter.getVueRoutes(),
        ...InvestorRouter.getVueRoutes(),
    ],
});
