//TODO: Refactor investor and sponsor constants to have a single file
// or separate the parts that are common. ex. sponsor error message name constants.
export default {
    modals: {
        TRANSFER: 'TRANSFER',
        BUY_SHARES: 'BUY_SHARES',
        SELL_SHARES: 'SELL_SHARES',
        UPDATE_INFO: 'UPDATE_INFO',
        SUCCESS: 'SUCCESS',
        RESET_PASSWORD: 'RESET_PASSWORD',
        REQUESTENTITYCREATION: 'REQUEST_ENTITY_CREATION',
        NOTIFY_ABOUT_ENTITY_CREATION: 'NOTIFY_ABOUT_ENTITY_CREATION',
        ENTITY_CREATION_INFO: 'ENTITY_CREATION_INFO',
        OFFERING_INVEST_MODAL: 'OFFERING_INVEST_MODAL',
        OFFERING_SUCCESS_MODAL: 'OFFERING_SUCCESS_MODAL',
        OFFERING_GALLERY_MODAL: 'OFFERING_GALLERY_MODAL',
        ADD_NEW_ENTITY_MODAL: 'ADD_NEW_ENTITY_MODAL',
        OFFERING_ENTITY_MODAL: 'OFFERING_ENTITY_MODAL',
        SIGNATORY_INFORMATION_MODAL: 'SIGNATORY_INFORMATION_MODAL',
        REVIEW_REQUEST_MODAL: 'REVIEW_REQUEST_MODAL',
        RISK_FACTORS_MODAL: 'RISK_FACTORS_MODAL',
        ACCREDITATION_MODAL: 'ACCREDITATION_MODAL',
    },
    isLoadingStates: {
        chartLoads: 'CHART_LOADS',
        getPositionByID: 'GET_POSITION_BY_ID',
        getProfileInfo: 'GET_PROFILE_INFO',
        getAllEntities: 'GET_ALL_ENTITIES',
        getPositionsData: 'GET_POSITIONS_DATA',
        imageProcessed: 'IMAGE_PROCESSES',
        signInProcess: 'SIGN_IN_PROCESS',
        changePasswordProcess: 'CHANGE_PASSWORD_PROCESS',
        resetPasswordProcess: 'RESET_PASSWORD_PROCESS',
        entityStatesLoads: 'ENTITY_STATES_LOADS',
        bankStatesLoads: 'BANKS_STATES_LOADS',
        signatoryStatesLoads: 'SIGNATORY_STATES_LOADS',
        transactionsTableLoads: 'TRANSACTIONS_TABLE_LOADS',
    },
};
