import {config} from "@/lib/config";
import {Cloudinary} from "cloudinary-core";
import {BaseEndpointGroup} from "@/lib/api/endpoints/BaseEndpointGroup";

export interface ImageOptions {
    width?: number|string;
    height?: number|string;
    crop?: string;
    dpr?: string;
    quality?: string;
    gravity?: string;
    radius?: string;
}

export class CloudinaryApi extends BaseEndpointGroup {
    public relativeUri: string = config.CLOUDINARY_API_BASE_URL;
    public readonly sdk: Cloudinary;
    constructor() {
        super();
        this.sdk = new Cloudinary({
            cloud_name: config.CLOUDINARY_CLOUD_NAME,
            secure: true,
        });
        this.sdk.responsive();
    }

    public request(url: string) {
        return super.request(url).withCredentials(false);
    }

    public async upload(image: File, folder: string) {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("folder", folder);
        formData.append("upload_preset", config.CLOUDINARY_UPLOAD_PRESET);

        return this.request(this.getFullUrl("upload")).method("post").form(formData);
    }

    public getUrl(id: string, options: ImageOptions = {}) {
        return this.sdk.url(id, options);
    }
}

export const cloudinaryApi = new CloudinaryApi();
