import {formatPossibleEmptyValue} from "@/services/helpers/sponsor/filters/formatPossibleEmptyValue";

export function addAsterisks(value: string): string {
    let formattedValue: string = value;

    formattedValue = formatPossibleEmptyValue(value);

    if (formattedValue) {
        formattedValue = value.slice(0, -4).replace(/./g, "*") + value.slice(-4);
    }
    return formattedValue;
}
