import {BaseTokenManager} from "@/lib/api/auth/TokenManager";
import {navigation} from "@/core/navigation/Navigation";

export class SponsorTokenManager extends BaseTokenManager {
    protected keyNamePrefix: string = "sponsor";

    protected navigateToLoginPage(): void {
        navigation.sponsor.signIn().navigate();
    }
}

const manager = new SponsorTokenManager();
export default manager;
