import {Mutation} from "@/lib/store/modules/Mutation";
import {Action} from "@/lib/store/modules/Action";
import {BaseModule} from "@/lib/store/modules/BaseModule";
import {RepositoryState} from "@/services/store/lib/RepositoryState";
import {BaseResource} from "@/services/store/lib/BaseResource";
import {LoaderState} from "@/lib/store/state/loading/LoaderState";
import {ErrorState} from "@/lib/store/state/errors/ErrorState";
import {Getter} from "@/lib/store/modules/Getter";

export default class ResourceRepositoryModule<Resource extends BaseResource> extends BaseModule<RepositoryState<Resource>> {
    public state = new RepositoryState<Resource>();

    @Mutation.Register()
    public setItem(data: Resource) {
        if (!this.state[data.id]) {
            this.initItem(data.id);
        }

        this.state[data.id].data = data;
        this.state[data.id].errors = new ErrorState();
    }

    public getEmptyResourceInstance(id: string): Resource {
        return {
            id: id
        } as Resource;
    }

    @Mutation.Register()
    public initItem(id: string) {
        this.state[id] = {
            loader: new LoaderState(),
            errors: new ErrorState(),
            data: this.getEmptyResourceInstance(id),
        };
    }

    @Getter.Register()
    public getItem(id: string) {
        return this.state[id];
    }

    @Action.Register()
    public async loadItem({id}: { id: string }) {
        this.state[id].loader.startTask();
        const data = await this.api.get(id);
        this.setItem(data.getRawData() as Resource);
        this.state[id].loader.completeTask();
    }
}
