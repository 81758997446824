import {
    HIDE_MODALS,
    REMOVE_IMAGE_IS_PROCESSES,
    REMOVE_IS_LOADING,
    SET_ALL_VEHICLES,
    SET_ERROR_HANDLER,
    SET_VEHICLES_BY_PROJECT,
    SET_IS_LOADING,
    SET_SUGGESTED_VEHICLES_LIST,
    SET_TRANSACTION_BY_VEHICLE_ID,
    SET_VEHICLE_BY_ID,
    SET_VEHICLES_BY_USER,
} from "../../storeConfigs/mutationTypes";
import constants from '../../../../../config/sponsor/constants';
import http from '../../../../helpers/sponsor/managers/requestManager';
import {VEHICLE_TRANSACTIONS, VEHICLES} from '../../storeConfigs/actionUrls';
import router from '../../../../router/router';
import O_o from '../../../../helpers/sponsor/promiseHandler';
import {DELETE, GET, LIST, PATCH, POST} from "../../../../helpers/sponsor/actionMethods";
import {updateVehicleSinglePage} from '../../../../helpers/sponsor/updateVehicleSinglePage';

export default {
    //TODO use lib for joining the urls
    async getVehicleById({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.getVehicleById);
        const [error, response] = await O_o(http.send(GET(`${VEHICLES}/${data}`)));

        if (error) {
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.getVehicleById);
            throw error;
        }
        commit(SET_VEHICLE_BY_ID, response);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.getVehicleById);
        return response;
    },
    //TODO join getVehicleById & getVehicleByUserId
    async getVehicleByUserId({commit}, query) {
        const [error, response] = await O_o(http.send(LIST(VEHICLES, query)));
        if (error) {
            throw error;
        }
        commit(SET_VEHICLES_BY_USER, response.items);
        return response;
    },
    async createVehicle({commit}, data) {
        const [error, response] = await O_o(http.send(POST(VEHICLES)));
        if (error) {
            throw error;
        }
        router.push({
            name: 'VehicleCreation',
            params: {vehicleId: response.id},
        });
    },
    async getAllVehicles({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.getAllVehicles);

        const [error, response] = await O_o(http.send(GET(VEHICLES)));

        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.getAllVehicles);
            throw error;
        }
        commit(SET_ALL_VEHICLES, response.items);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.getAllVehicles);
    },
    async addAssetFromVehicle({commit, dispatch}, data) {
        const [error, response] = await O_o(http
            .send(POST(`${VEHICLES}/${data.vehicleId}/assets`), data.assetId));
        if (error) {
            throw error;
        }
        dispatch('editVehicle', {
            formatData: data.formatData,
            vehicleId: data.vehicleId,
        });
    },
    async deleteAttachedAsset({commit, dispatch}, data) {
        const [error, response] = await O_o(http
            .send(DELETE(`${VEHICLES}/${data.vehicleId}/assets`), data.assetId));
        if (error) {
            throw error;
        }
        dispatch('getVehicleById', data.vehicleId);
    },
    async editVehicle({commit, dispatch}, data) {
        dispatch('setLoaderForRequests');
        const [error, response] = await O_o(http.send(PATCH(`${VEHICLES}/${data.vehicleId}`), data.formatData));
        dispatch('removeLoaderForRequests');
        if (error) {
            throw error;
        }
        commit(SET_VEHICLE_BY_ID, response);
        if (data.ImageIsProcesses) {
            commit(REMOVE_IMAGE_IS_PROCESSES, data.ImageIsProcesses);
        }
        if (data.path) {
            router.push({
                name: data.path,
            });
        }
    },

    async vehiclePublish({commit, dispatch}, data) {
        commit(HIDE_MODALS, constants.modals.vehiclePublishModal);
        dispatch('setLoaderForRequests');
        const [error, response] = await O_o(http.send(POST(`${VEHICLES}/${data.vehicleId}/publish`), {alsoPublishTransactions: data.alsoPublishTransactions}));
        dispatch('removeLoaderForRequests');
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        commit(SET_IS_LOADING, constants.isLoadingStates.getVehicleById);
        updateVehicleSinglePage(data.vehicleId);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.getVehicleById);
        return response;
    },
    async vehicleUnPublish({commit, dispatch}, data) {
        dispatch('setLoaderForRequests');
        const [error, response] = await O_o(http.send(POST(`${VEHICLES}/${data.vehicleId}/unpublish`)));
        dispatch('removeLoaderForRequests');
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        return response;
    },

    async deleteVehicle({commit, dispatch}, data) {
        const [error, response] = await O_o(http.send(DELETE(`${VEHICLES}/${data.vehicleId}`)));
        if (error) {
            throw error;
        }
        commit(HIDE_MODALS, constants.modals.deleteVehicle);
        if (data.path) {
            router.push({
                name: data.path,
            });
        }
        if (data.fromProjects) {
            dispatch('getAllVehicles');
        }
        if (data.fromModal) {
            window.location.href = data.fromModal;
        }
    },
    async getTransactionByVehicleId({commit}, params) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionHistoryTable);

        const id =
            params && params.id && params.id.length
                ? `?f_vehicle_id=${params.id}` : "";
        const searchQuery =
            params && params.searchQuery && params.searchQuery.length
                ? `&q=${params.searchQuery}` : "";
        const transactionType =
            params && params.transactionType && params.transactionType.length
                ? `&f_transaction_type=${params.transactionType}` : "";
        const status =
            params && params.status && params.status.length
                ? `&f_is_published=${params.status}` : "";
        const [error, response] = await O_o(http.send(GET(`${VEHICLE_TRANSACTIONS}${id}${searchQuery}${transactionType}${status}`)));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionHistoryTable);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionHistoryTable);
        commit(SET_TRANSACTION_BY_VEHICLE_ID, response.items);
    },
    async vehiclesByProject({commit}, data) {
        const projectId =
            data && data.projectId && data.projectId.length
                ? `?f_project_id=${data.projectId}`
                : '';
        const [error, response] = await O_o(http.send(GET(`${VEHICLES}${projectId}`)));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        commit(SET_VEHICLES_BY_PROJECT, response.items);
    },
};
