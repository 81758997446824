<template>
    <div :class="isDisabled ? 'not-disabled' : 'active-text'" class="select-custom">
        <select
            v-model="selectedOption"
            @input="$emit('input', $event.target.value)"
            @change="execute"
            required=""
            :data-selected="selectedOption"
            class="form-control input-border-color input-style"
        >
            <option
                v-for="(option, index) in options"
                :value="option.value"
                :key="index"
            >{{ option.text }}
            </option
            >
        </select>
    </div>
</template>

<script>
export default {
    props: {
        value: String,
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedOption: null,
            data: '',
            isDisabled: false
        };
    },
    watch: {
        value: {
            immediate: true,
            handler: function (newValue) {
                if (newValue === '') {
                    newValue = null
                }
                this.selectedOption = newValue;
                this.checkIsDisabled(newValue);
            },
        },
    },

    methods: {
        execute() {
            this.$emit("changed");
        },
        checkIsDisabled(value) {
            this.options.find(item => {
                if (item.value === this.selectedOption && item.disabled) {
                    return this.isDisabled = true;
                } else if (item.value === this.selectedOption && !item.disabled) {
                    return this.isDisabled = false;
                }
            })
        }
    },
};
</script>

<style scoped lang="scss">
@import "../../../../assets/investor/styles/base/dropdowns";
</style>
