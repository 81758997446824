import {RouteClass} from "@/lib/router/Route";
import {Pojo} from "@/lib/types";
import router from "@/services/router/router";

export class Route {
    public name: string;
    public params: Pojo;

    constructor(route: RouteClass, params?: Pojo) {
        this.name = route.Name;
        this.params = params;
    }

    public navigate() {
        return router.push(this);
    }
}

export class BasicNavigator {
    public route(route: RouteClass, params?: Pojo) {
        return new Route(route, params);
    }
}
