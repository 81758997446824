// Chart urls
const DOUGHNUT_CHART_BY_VEHICLE = '/position-transactions/by-vehicle';
const LINE_CHART_BY_DATE = '/position-transactions/by-date';

// Documents urls
const DOCUMENTS = 'documents';

// Entity urls
//TODO Change this
const ENTITIES = '/legal-entities';
const ENTITY_CONTACTS = '/entity-contacts';

// Country url
const COUNTRIES_LIST = '/etc/countries';
const ETC = '/etc';
const STATE = '/state';

// Position urls
const POSITIONS = '/positions';
const POSITION_TRANSACTIONS = '/position-transactions';

// User urls
const USER = '/user';
const RESET_PASSWORD_REQUEST = '/auth/request-reset-password';
const RESET_PASSWORD = 'auth/reset-password';
const LOGOUT = 'auth/logout';
const LOGIN = 'auth/login';
const ACTIVATE_USER = 'auth/activate';
const SIGNUP = '/auth/register';
const REFRESH_TOKEN = '/auth/refresh-token';

const OFFERINGS = '/offerings';
const OFFERING_INVITES = '/offering-invites';

// Vehicle urls
const VEHICLES = '/vehicles';

//NDA urls
const NDA = '/nda';
const NDA_SIGN = '/nda/sign';

// Accreditation urls
const ACCREDITATION = '/sponsor/accreditation/submit';

//Sponsor urls
const SPONSOR_INFO = '/sponsor/info';
const SPONSOR_TERMS = '/sponsor/terms';
const SPONSOR_POLICY = '/sponsor/policy';


export {
  DOUGHNUT_CHART_BY_VEHICLE,
  LINE_CHART_BY_DATE,
  DOCUMENTS,
  ENTITY_CONTACTS,
  ENTITIES,
  COUNTRIES_LIST,
  POSITIONS,
  POSITION_TRANSACTIONS,
  USER,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD,
  LOGOUT,
  LOGIN,
  ACTIVATE_USER,
  SIGNUP,
  REFRESH_TOKEN,
  VEHICLES,
  OFFERINGS,
  OFFERING_INVITES,
  NDA,
  NDA_SIGN,
  ACCREDITATION,
  ETC,
  STATE,
  SPONSOR_INFO,
  SPONSOR_TERMS,
  SPONSOR_POLICY,
};
