import {
    SET_POSITIONS,
    SET_COMMON_EQUITY,
    SET_NEW_POSITION,
    ADD_INVESTOR_GROUP,
    DELETE_INVESTOR_GROUP,
} from "../../storeConfigs/mutationTypes";
import {guidGenerator} from '@/services/helpers/sponsor/randomId';
import {initEntity} from "@/services/entities/sponsor/BaseFloteEntity";
import {User} from "@/services/store/modules/auth/User";

export default {
    [SET_POSITIONS](state, data) {
        data.items = data.items.map(i => {
            i.entity.owner = initEntity(User, i.entity.owner);
            return i;
        });
        state.positions = data;
    },
    [SET_COMMON_EQUITY](state, data) {
        data.items = data.items.map(i => {
            i.entity.owner = initEntity(User, i.entity.owner);
            return i;
        });
        state.commonEquity = data;
    },
    [SET_NEW_POSITION](state, data) {
        state.newPositionData = data;
    },
    [ADD_INVESTOR_GROUP](state) {
        state.newPositionData.groups.push(
            {
                id: guidGenerator(),
                name: {
                    value: '',
                },
                positions: []
            },
        );
    },
    [DELETE_INVESTOR_GROUP](state, groupId) {
        let deletedGroupItems = state.newPositionData.groups.filter(group => group.id === groupId)[0].positions;
        state.newPositionData.groups = state.newPositionData.groups.filter(group => group.id !== groupId);
        state.newPositionData.groups[0].positions.push(...deletedGroupItems);
    },
};
