import {
  SET_ENTITIES,
  SET_CONTACTS,
  SET_ENTITY_BY_ID_INFO,
} from '../../config/mutationTypes';

export default {
  [SET_ENTITIES](state, data) {
    state.entities = data;
  },
  [SET_ENTITY_BY_ID_INFO](state, data) {
    state.entityById = data;
  },
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  checkLegalFormType(state, data) {
    state.legalForm = data;
  },
  setCreatingEntity(state, data) {
    state.creatingEntity = data;
  },
  removeEntityData(state, data) {
    state.entityCreation = data;
  },
  setSignatoryAdded(state, data) {
    state.isSignatoryAdded = data;
  },
  setInvestment(state, data) {
    state.investing = data;
  },
  setEntityReviewData(state, data) {
    state.entityReviewData = data;
  }
};
