import {VuexModule, Mutation, Action, Module} from 'vuex-module-decorators'
import O_o from '../../../helpers/sponsor/promiseHandler';
import {PROJECTS, ASSETS} from '../storeConfigs/actionUrls';
import constants from "../../../../config/sponsor/constants";
import http from '../../../helpers/sponsor/managers/requestManager';
import {GET, PATCH} from '../../../helpers/sponsor/actionMethods';
import {
    SET_PROJECTS_LIST,
    SET_SINGLE_PROJECT,
    SET_IS_LOADING,
    REMOVE_IS_LOADING,
    SET_ERROR_HANDLER, SET_PROJECT_ASSETS

} from "../storeConfigs/mutationTypes";
import router from '../../../router/router';
import {DropdownOptions} from "@/mixins/sponsor/TypedGetType";
import store from "@/services/store";

export enum ProjectStatus {
    PreClosing = "PreClosing",
    Active = "Active",
    Exited = "Exited",
    Dead = "Dead",
}

export interface ProjectAggregatedView {
    id: string,
    isPublished: boolean,
    name: string,
    projectType: string,
    projectProfile: string,
    projectStatus: ProjectStatus,
    targetHoldPeriodYears: string,
    projectOverview: string,
    targetIrr: string,
    targetEquityMultiple: string,
    purchasePrice: string,
    totalBudgetedDebt: string,
    totalBudgetedEquity: string,
    totalProjectCost: string,
    averageCashOnCash: string,
    totalCommonEquity: string,
    organizationalChart: string,
    closedDate: Date,
    numberOfVehicles: number,
    numberOfPositions: number,
    totalCommitment: string,
    totalContribution: string,
    totalDistribution: string,
    remainingCommitment: string,
}

export interface Project {
    name: string,
    projectType: string,
    projectProfile: string,
    projectStatus: ProjectStatus,
    targetHoldPeriodYears: string,
    projectOverview: string,
    totalBudgetedDebt?: string,
    totalBudgetedEquity?: string,
    totalProjectCost?: string,
    totalCommonEquity?: string,
}

@Module({namespaced: true})
class ProjectModule extends VuexModule {
    public projects: ProjectAggregatedView[] = [];
    public singleProject: object = {};
    public projectAssets: [] = [];

    @Mutation
    public [SET_PROJECTS_LIST](data: ProjectAggregatedView[]) {
        this.projects = data;
    };

    @Mutation
    public [SET_SINGLE_PROJECT](data: ProjectAggregatedView) {
        this.singleProject = data;
    };

    @Mutation
    public [SET_PROJECT_ASSETS](data) {
        this.projectAssets = data;
    }

    get getProjectsForOptions() {
        const projects = this.projects.map(item => ({
            text: item.name,
            value: item.id,
        }));
        return [...projects, {text: "Select project", value: null}];
    };

    @Action
    async getProjectsList(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.getAllProjects, {root: true});
        const [error, response] = await O_o(
            http.send(GET(PROJECTS), data)
        );
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.getAllProjects, {root: true});
            throw error;
        }
        this.context.commit(SET_PROJECTS_LIST, response.items);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.getAllProjects, {root: true});
    };

    @Action
    async getProject(data) {
        const [error, response] = await O_o(http.send(GET(`${PROJECTS}/${data.projectId}`)));
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_SINGLE_PROJECT, response);
    };

    @Action
    async assetsByProject(data) {
        const projectId =
            data && data.projectId && data.projectId.length
                ? `f_project_id=${data.projectId}`
                : '';
        const [error, response] = await O_o(http.send(GET(`${ASSETS}?${projectId}`)));

        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_PROJECT_ASSETS, response.items);
    };

    @Action
    async editProject(data) {
        store.dispatch('setLoaderForRequests')
        const [error, response] = await O_o(http.send(PATCH(`${PROJECTS}/${data.projectId}`), data.formatData));
        store.dispatch('removeLoaderForRequests')
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_SINGLE_PROJECT, response);
        router.push({
            name: data.path,
        })
    };

    get projectNameOptions(): DropdownOptions[] {
        const list = this.projects.map(item => ({
            text: item.name,
            value: item.id,
            disabled: false
        }));
        return [{value: null, text: 'Project Name', disabled: true}, ...list];
    };
}

export default ProjectModule;
