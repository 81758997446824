













import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import FloteDropdown from "@/components/ui/inputs/FloteDropdown.vue";
import {EnumField} from "@/lib/forms/fields/EnumField";

@Component({
    components: {FloteDropdown}
})
export default class FormDropdown extends Vue {
    @Prop({required: true})
    public field!: EnumField;

    public get errorMessage() {
        return this.field.errorMessages.join("\n");
    }

    public onChange() {
        this.field.validate();
        this.$emit("change", this.field.value);
    }

    @Watch("field.loaded")
    public onLoad() {
        this.setElemRef();
    }

    public setElemRef() {
        const elem = (this.$refs.selectElement as Vue);
        if (elem) {
            this.field.elementRef(
                elem.$el.getElementsByTagName("select")[0]as HTMLSelectElement
            );
        }
    }

    public mounted() {
        this.setElemRef();
    }
}
