import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const VehiclesRouter = new Router();

@VehiclesRouter.register()
export class VehiclesRoute extends Route {
    getComponent() {
        return import("@/views/Vehicles.vue");
    }

    getPath(): string {
        return "vehicles";
    }
}

@VehiclesRouter.register()
export class SingleVehicleRoute extends Route {
    getComponent() {
        return import("@/views/VehicleSinglePage.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId";
    }
}

@VehiclesRouter.register()
export class CreatePositionRoute extends Route {
    getComponent() {
        return import("@/views/CreatePositionFlow.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/position/create";
    }
}

@VehiclesRouter.register()
export class EditVehicleRoute extends Route {
    getComponent() {
        return import("@/components/sponsor/sections/VehicleSinglePage/EditVehicle.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/edit";
    }
}
