import axios from 'axios';
import TokenManager from '../../../../lib/api/auth/InvestorTokenManager';
import store from '../../../store';
import interceptor from './interceptor';
import { SignInRoute } from '../../../router/investor/index';
import { routerNavigateTo } from '@/lib/router/Navigator';
import { config } from '@/lib/config';


class Request {
  constructor() {
    this.axios = axios.create({
      baseURL: config.API_BASE_URL,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    });

    this.multipartAxios = axios.create({
      baseURL: config.API_BASE_URL,
      withCredentials: true,
    });

    this.multipartAxiosWithoutCredentials = axios.create({
      baseURL: config.API_BASE_URL,
      withCredentials: false,
    });

    this.axios.interceptors.response.use(null, interceptor);
  }

  send(op, data) {
    op.loading ? store.commit('setIsLoading', op.loading) : {};
    op.headers = {
      ...op.headers,
      Authorization: `Bearer ${TokenManager.getAccessToken()}`,
    } || {};

    return this.axios({
      method: op.method,
      url: op.url,
      responseType: op.responseType ? op.responseType : '',
      headers: {
        ...op.headers,
        'Content-Type': 'application/json',
      },
      params: op.qs,
      data,
    })
      .then((res) => {
        if (res.headers['x-renew-access-token']) {
          TokenManager.setAccessToken(res.headers['x-renew-access-token']);
        }
        if (res.headers['x-renew-refresh-token']) {
          TokenManager.setRefreshToken(res.headers['x-renew-refresh-token']);
        }
        return op.responseType ? res : res.data;
      })
      .catch((err) => {
        if (
            err.response.status === 401 &&
            err.response.statusText === "Unauthorized"
        ) {
          TokenManager.logout();
          routerNavigateTo(SignInRoute.Name);
        }
        throw err.response;
      });
  }

  sendMultipartFormDataWithoutCredentials(op, data) {
    op.headers = op.headers || {};
    return this.multipartAxiosWithoutCredentials({
      method: op.method,
      url: op.url,
      params: op.qs,
      data,
    }).then(res => res.data);
  }

  clearAxios() {
    this.axios = undefined;
  }
}

export default new Request();
