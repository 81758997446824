import {ModalManager} from "@/core/modals/ModalManager";

export class ModalState {
    public readonly modalManager: ModalManager;

    constructor(modalManager?: ModalManager) {
        this.modalManager = modalManager;
    }

    private _isOpen: boolean = false;
    public id: string = null;

    public get isOpen() {
        return this._isOpen;
    }

    public open(id?: string) {
        this._isOpen = true;
        this.id = id;
    }

    public close() {
        this._isOpen = false;
        this.id = null;
    }
}
