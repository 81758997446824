import {FloteRequest} from "@/lib/api/request/FloteRequest";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";

export class RequestInterceptor {
    constructor() {
    }

    public onResponse(response: AxiosResponse) {
        return response;
    }

    public onResponseError(error: AxiosError) {
        return Promise.reject(error);
    }

    public onRequest(config: AxiosRequestConfig) {
        return config;
    }

    public onRequestError(error: AxiosError) {
        return Promise.reject(error);
    }
}

export type RequestInterceptorClass = typeof RequestInterceptor & (new (r: FloteRequest) => RequestInterceptor);
