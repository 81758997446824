export default {
  entities: [],
  entityById: {},
  contacts: [],
  legalForm: '',
  entityCreation: {
    title: '',
    selectedEntityTypeDetail: '',
    country: 'US',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    taxIdType: '',
    taxId: '',
    bankName: '',
    bankRoutingNumber: '',
    bankAccountNumber: '',
    bankAccountName: '',
    bankStreetAddress: '',
    bankCity: '',
    bankState: '',
    bankPostalCode: '',
    bankNotes: '',
  },
  signatory: {
    signatory1Email: '',
    signatory1FirstName: '',
    signatory1LastName: '',
    signatory1Title: '',
    signatory1Phone: '',
    signatory2Email: '',
    signatory2FirstName: '',
    signatory2LastName: '',
    signatory2Title: '',
    signatory2Phone: '',
    signatory2RelTo1: '',
    signatory2AddressStreetAddress: '',
    signatory2AddressCity: '',
    signatory2AddressState: '',
    signatory2AddressCountry: 'US',
    signatory2AddressPostalCode: '',
  },
  creatingEntity: false,
  isSignatoryAdded: false,
  investing: {
    amount: '',
    entityId: '',
    inviteId: '',
  },
  entityReviewData: {},
};
