
































import {Prop, Vue, Component, Watch} from "vue-property-decorator";
import {guidGenerator} from "@/services/helpers/sponsor/randomId";
import {ValueFormatter} from "@/lib/formatters/ValueFormatter";
import {Formatters} from "@/lib/forms/formatters/Formatters";

@Component({
    directives: {
        handleCursorPosition: {
            update(e: any, options, vnode) {
                const component = vnode.context as FloteInput;
                if (component.isSelectable && document.activeElement === e) {
                    if (e.dataset.prevValue.length < 2) {
                        e.selectionEnd = e.value.length;
                        return;
                    }
                    let positionDiff = 0;
                    if (e.dataset.prevValue.length === (e.value.length - 1)) {
                        positionDiff = 1;
                    }
                    if (e.dataset.prevValue.length === (e.value.length + 1)) {
                        positionDiff = -1;
                    }
                    if (e.selectionEnd !== e.dataset.position) {
                        e.selectionEnd = Number(e.dataset.position) + positionDiff;
                    }
                }
            },
        },
    },
})
export default class FloteInput extends Vue {
    @Prop({default: "text"})
    public inputType!: string;

    @Prop({default: "text"})
    public mode!: string;

    public get isSelectable() {
        return this.inputType !== "email";
    }

    @Prop({default: null})
    public inputClass!: string;

    @Prop({default: null})
    public value!: string;

    @Prop({default: null})
    public placeholder!: string;

    @Prop({default: false})
    public isRequired!: boolean;

    @Prop({default: false})
    public isDisabled!: boolean;

    @Prop({default: null})
    public errorMessage!: string;

    @Prop({default: () => guidGenerator()})
    public id!: string;

    @Prop({default: () => Formatters.Default()})
    public formatter!: ValueFormatter;

    @Prop({default: false})
    public highlightedAsError!: boolean;

    public position: number = 0;
    public prevValue: string = "";
    public isFocused: boolean = false;
    public customType: string = "text";

    public formattedValue: string = null;
    public prevFormattedValue: string = null;

    public updateFormattedValue() {
        if (this.isFocused) {
            this.formattedValue = this.mask(this.value);
            this.prevFormattedValue = this.formattedValue;
        } else {
            this.formattedValue = this.format(this.value);
        }
    }

    @Watch("value")
    public updateValue(value: string) {
        this.updateFormattedValue();
    }

    @Watch("formatter")
    public updateFormatter(value: string) {
        this.updateFormattedValue();
    }

    public created() {
        this.updateFormattedValue();
        this.prevValue = this.formattedValue;
        this.position = this.prevValue.length;
        this.customType = this.inputType;
    }

    public mask(value: string) {
        return this.formatter.mask(value);
    }

    public unmask(value: string) {
        return this.formatter.unmask(value);
    }

    public format(value: string) {
        return this.formatter.format(value);
    }

    public onBlur(event: Event) {
        this.isFocused = false;
        this.updateFormattedValue();
        this.$emit("blur", event);
    }

    public onFocus(event: Event) {
        this.isFocused = true;
        this.updateFormattedValue();
        this.$emit("focus", event);
    }

    public handleInput(event: any) {
        const inputValue = event.target.value;
        const targetValue = this.unmask(inputValue);
        if (this.formatter.areEqual(targetValue, this.value)) {
            this.formattedValue = this.prevFormattedValue;
            this.position = event.target.selectionStart;
            return;
        }
        this.prevValue = inputValue;
        this.position = event.target.selectionStart;
        this.$emit("input", targetValue);
    }
}
