import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const ProjectsRouter = new Router();

@ProjectsRouter.register()
export class ProjectsRoute extends Route {
    getComponent() {
        return import("@/views/Project/Projects.vue");
    }

    getPath(): string {
        return "projects";
    }
}

@ProjectsRouter.register()
export class ProjectsSingleRoute extends Route {
    getComponent() {
        return import("@/views/Project/ProjectSinglePage.vue");
    }

    getPath(): string {
        return "projects/:id";
    }
}

@ProjectsRouter.register()
export class ProjectEditRoute extends Route {
    getComponent() {
        return import("@/components/sponsor/sections/ProjectEditingPage/ProjectEditingPage.vue");
    }

    getPath(): string {
        return "projects/:id/edit";
    }
}
