import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {
    REMOVE_IS_LOADING,
    SET_ENTITIES,
    SET_ERROR_HANDLER, SET_ERROR_IN_ERROR_HANDLER,
    SET_IS_LOADING,
    SET_MODALS,
    SET_USER,
    SET_USERS,
} from "@/services/store/sponsor/storeConfigs/mutationTypes";
import O_o from "@/services/helpers/sponsor/promiseHandler";
import RequestManager from "@/services/helpers/sponsor/managers/requestManager";
import {DELETE, GET, LIST, PATCH, POST} from "@/services/helpers/sponsor/actionMethods";
import {ENTITIES, LOGIN, NOTIFICATIONS, REFRESH_TOKEN, USERS} from "@/services/store/sponsor/storeConfigs/actionUrls";
import tokenManager from "@/lib/api/auth/SponsorTokenManager";
import constant from "@/config/sponsor/constants";
import http from "../../../helpers/sponsor/managers/requestManager";
import getQuery from "../storeConfigs/getQuery";
import {User} from "@/services/store/modules/auth/User";
import {initEntity} from "@/services/entities/sponsor/BaseFloteEntity";
import {DefaultRoute} from "@/services/router/sponsor";
import {routerNavigateTo} from "@/lib/router/Navigator";
import {Entity} from "@/services/store/modules/entities/Entity";

@Module({namespaced: true})
class UserStore extends VuexModule {
    public users: User[] = [];
    public entities: Entity[] = [];
    public user: User = initEntity(User, {});

    @Mutation
    public [SET_ENTITIES](data: Entity[]) {
        this.entities = data;
    };

    @Mutation
    public [SET_USERS](data: User[]) {
        this.users = data.map(i => initEntity(User, i));
    };

    @Mutation
    public [SET_USER](data: User) {
        this.user = initEntity(User, data);
    }

    @Action
    public async refreshToken() {
        const [error, response] = await O_o(RequestManager.send(
            POST(REFRESH_TOKEN),
            {refreshToken: tokenManager.getRefreshToken()},
        ));
        if (error) {
            tokenManager.setNextRoute();
            tokenManager.logout();
            throw error;
        }
        tokenManager.setAuthTokens(response.accessToken, response.refreshToken);
        return response;
    };

    @Action
    public async login(data) {
        this.context.commit(SET_IS_LOADING, constant.isLoadingStates.signInProcess, {root: true});
        const [error, response] = await O_o(http.send(POST(LOGIN), data));

        if (error) {
            this.context.commit(REMOVE_IS_LOADING, constant.isLoadingStates.signInProcess, {root: true});
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        tokenManager.setAuthTokens(response.accessToken, response.refreshToken);
        let nextRoute = tokenManager.getNextRoute();
        if (nextRoute) {
            let {name, params} = nextRoute;
            routerNavigateTo(name, params);
            tokenManager.removeNextRoute();
        } else {
            routerNavigateTo(DefaultRoute.Name);
        }
        this.context.commit(REMOVE_IS_LOADING, constant.isLoadingStates.signInProcess, {root: true});
    };

    @Action
    public async changeAccreditationStatus(data) {
        const [error, response] = await O_o(http.send(POST(`${USERS}/${data.userId}/accreditation-status`), data.accreditation));
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        await this.context.dispatch('getInvestorsList', data.offeringId);
    };

    @Action({rawError: true})
    public async getUsers(data) {
        const query = getQuery({
            search: data && data.searchQuery,
            vehicleId: data && data.vehicleId,
            projectId: data && data.projectId,
        });
        const [error, response] = await O_o(http.send(GET(`${USERS}${NOTIFICATIONS}${query}`, constant.isLoadingStates.getUsersList)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_USERS, response.items);
    };

    @Action
    public async getUser(data) {
        const [error, response] = await O_o(http.send(GET(`${USERS}/${data.userId}`, constant.isLoadingStates.getUserSingle)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_USER, response);
    };

    @Action({rawError: true})
    public async editUser(data) {
        this.context.commit(SET_IS_LOADING, constant.isLoadingStates.editUser, {root: true});
        try {
            const editedUser = await (http.send(PATCH(`${USERS}/${data.userId}`, constant.isLoadingStates.editUser), data.formData));
            this.context.commit(SET_USER, editedUser);
            this.context.commit(REMOVE_IS_LOADING, constant.isLoadingStates.editUser, {root: true});
        } catch (error) {
            this.context.commit(REMOVE_IS_LOADING, constant.isLoadingStates.editUser, {root: true});
            if (error.data.name === constant.errors.uniqueConstraint) {
                this.context.commit(SET_ERROR_IN_ERROR_HANDLER,
                    {fieldName: "email", message: "Email already in use."},
                    {root: true});
            }
            if (error.status === 500) {
                this.context.commit(SET_MODALS, constant.modals.generalErrorOccurred, {root: true});
            } else {
                throw error;
            }
        }
    };

    @Action
    public async deleteUser(data) {
        const [error, response] = await O_o(http.send(DELETE(`${USERS}/${data.userId}`)));
        if (error) {
            throw error;
        }
        await this.context.dispatch('getUsers');
    };

    @Action
    public async getEntities(data) {
        const [error, response] = await O_o(http.send(GET(ENTITIES, constant.isLoadingStates.getEntitiesList)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_ENTITIES, response.items);
    };

    @Action
    public async getEntitiesByUserId(query) {
        const [error, response] = await O_o(http.send(LIST(ENTITIES, query, constant.isLoadingStates.getUserSingle)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_ENTITIES, response.items);
    };

    get entitiesByUserIDForOptions() {
        const entities = this.entities.map(item => ({
            text: item.entityName,
            value: item.id,
            disabled: false
        }));
        return [
            {text: "Investor Entity", value: null, disabled: true},
            ...entities
        ];
    };
}

export default UserStore;
