import router from "../../../router/router";
import store from "../../../../../src/services/store";
import req from "./requestManager";

let isGetActiveSessionRequest = false;
let requestQueue = [];

const callRequestsFromQueue = (token) => {
  requestQueue.map(sub => sub(token));
};
const addRequestToQueue = (sub) => {
  requestQueue.push(sub);
};

export default (error) => {
  const { response = {}, config: sourceConfig } = error;
  if (response.status === 401 && router.currentRoute.name !== 'SignIn') {
    if (!isGetActiveSessionRequest) {
      isGetActiveSessionRequest = true;
      store
          .dispatch('refreshToken')
          .then(response => {
            isGetActiveSessionRequest = false;
            callRequestsFromQueue(response.accessToken);
            requestQueue = [];
          })
          .catch((error) => {
            isGetActiveSessionRequest = false;
            requestQueue = [];
            throw error;
          });
    }
    return new Promise((resolve) => {
      addRequestToQueue((token) => {
        sourceConfig.headers.Authorization = `Bearer ${token}`;
        resolve(req.axios(sourceConfig));
      });
    });
  } else {
    return Promise.reject(error);
  }
};
