import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const user = {
  actions,
  mutations,
  state,
  getters,
};

export default user;
