import {RequestInterceptor} from "@/lib/api/request/RequestInterceptor";
import {AxiosError} from "axios";
import {errorResolver} from "@/lib/api/errors/ErrorResolver";

export class BaseRequestInterceptor extends RequestInterceptor {
    public onResponseError(error: AxiosError) {
        const floteError = errorResolver.resolve(error);
        return Promise.reject(floteError);
    }
}
