export default {
  offerings: [],
  singleOffer: {
    vehicle: {
      project: {
        assets: []
      }
    },

  },
  investorsInviteList: [],
  pendingPositions: [],
};
