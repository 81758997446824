<template>
    <div class="input-container" :class="{'active-text': displayValue && displayValue.length}">
        <label class="placeholder" :for="id">{{ placeholder }} <span class="required" v-if="isRequired">*</span>
        </label>
        <input
            v-model="displayValue"
            @blur="handleInputBlurState"
            @focus="handleInputState"
            class="input-box"
            :type="type ? type : 'text'"
            :id="id"
            :class="error ? 'error' : ''"
            :disabled="isDisabled"
        />

        <div class="error-message" v-if="error">
            {{ errorMessage }}
        </div>

        <slot></slot>
    </div>
</template>

<script>
import {guidGenerator} from '@/services/helpers/sponsor/randomId';
import {validationErrorMessages} from "@/config/sponsor/validationErrorConfigs";
import masks from "../../../../services/helpers/sponsor/BaseInputHelper.js";

export default {
    data() {
        return {
            inputFocused: false,
        };
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        placeholder: String,
        maskType: String,
        isDisabled: {
            type: Boolean,
            default: false,
        },
        type: {type: String, default: 'text'},
        blurEffect: Function,
        focusEffect: Function,
        id: {
            type: String,
            default: () => guidGenerator(),
        },
        error: [String, Boolean],
    },
    computed: {
        displayValue: {
            get() {
                if (this.inputFocused) {
                    return this.value;
                }
                if (this.maskType) {
                    return this.mask(this.value);
                } else {
                    return this.value;
                }
            },
            set(value) {
                if (this.maskType) {
                    this.$emit('input', this.unmask(value))
                } else {
                    this.$emit('input', value);
                }
            },
        },
        errorMessage() {
            if (this.displayValue === '' && this.isRequired && this.error) {
                return validationErrorMessages.required;
            }
            if (this.displayValue !== '' && this.error) {
                return this.error;
            }
            return '';
        }
    },
    methods: {
        handleInputState(event) {
            if (this.focusEffect) {
                this.focusEffect();
            }
            this.inputFocused = event.type === 'focus';
        },
        handleInputBlurState(event) {
            if (this.blurEffect) {
                this.blurEffect();
            }
            this.inputFocused = event.type === 'blur';
        },
        unmask(value) {
            return masks[this.maskType].unmask(value);
        },
        mask(value) {
            return masks[this.maskType].mask(value);
        },
    },
};
</script>


