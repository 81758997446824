import {FloteResponse} from "@/lib/api/response/FloteResponse";
import {ResponseField} from "@/lib/api/response/decorators";

export interface StateConfig {
    name: string;
    abbreviation: string;
}

export interface EnumsConfig {
    states: {
        US: StateConfig[];
    };
}

export class ConfigResponse extends FloteResponse {
    @ResponseField()
    public enums: EnumsConfig;
}
