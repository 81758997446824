import errorHandler from "@/services/errorHandler/errorHandler";

const handleError = (error, errorCallback) => {
    errorHandler(errorCallback ? errorCallback(error) : error);
    return errorCallback ? [errorCallback(error)] : error;
};

export default function O_o(promise, successCallback?, errorCallback?) {
    return promise
        .then(data => {
            if (data instanceof Error) {
                return [handleError(data, errorCallback)];
            }
            if (successCallback) successCallback();
            return [null, data];
        })
        .catch(error => {
            return [handleError(error, errorCallback)];
        });
}
