import {BaseModule} from "@/lib/store/modules/BaseModule";
import {SubModule} from "@/lib/store/modules/SubModule";
import {DocumentsListModule} from "@/services/store/modules/documents/DocumentsListModule";
import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

export class DocumentsStore extends BaseModule {
    @SubModule.Register()
    public list: DocumentsListModule;

    public get api(): FloteEndpointGroup {
        return this.portalApi.documents;
    }
}
