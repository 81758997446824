import {formatNumericValue} from "@/services/helpers/sponsor/filters/numberFormatter";
import {ValueFormatter} from "@/lib/formatters/ValueFormatter.ts";
import {decimal, Decimal} from "@/services/helpers/sponsor/math/Decimal";
import {config} from "@/lib/config";

const NON_NUMERIC_VALUE_REGEX = /[^\d]/g;

export class MoneyFormatter extends ValueFormatter<Decimal> {
    private readonly decimalPoints: number;
    public readonly prefix: string;
    private wasNegativeZero: boolean = false;

    constructor(decimalPoints: number = config.NUMBER_FORMATTING_DECIMAL_PLACES, prefix: string = "$") {
        super();
        this.decimalPoints = decimalPoints;
        this.prefix = prefix;
    }

    public clearValue(value: string) {
        if (!value) {
            value = "";
        }
        value = value.replace(this.prefix, "");

        let isNegative = (value.startsWith("-") || (value == "0.00-"));
        const cleared = value.replace(NON_NUMERIC_VALUE_REGEX, "");
        const fractional = (cleared.slice(-this.decimalPoints) || "0").padStart(this.decimalPoints, "0");
        const whole = cleared.slice(0, -this.decimalPoints) || "0";

        return (isNegative ? "-" : "") + whole + "." + fractional;
    }

    public mask(value: Decimal): string {
        if (value === null) {
            return "";
        }

        let prefix = this.prefix;

        if (value.isNegative() && value.isZero() && !this.wasNegativeZero) {
            this.wasNegativeZero = true;
            prefix += "-";
        } else {
            this.wasNegativeZero = false;
        }

        return prefix + formatNumericValue(value);
    }

    public unmask(value: string): Decimal {
        return decimal(this.clearValue(value));
    }

    public format(value: Decimal): string {
        if (value === null) {
            return "";
        }
        let formatted = this.prefix + formatNumericValue(value.abs());
        if (value.isLessThan(0)) {
            formatted = `(${formatted})`;
        }
        return formatted;
    }

    public areEqual(vale1: Decimal, value2: Decimal): boolean {
        if (!vale1 || !value2) {
            return vale1 === value2;
        }
        return vale1.toString() === value2.toString();
    }
}
