import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

export class OfferingInvestorsListApi extends FloteEndpointGroup {
    public relativeUri: string = "offering-investors";

    constructor(parent: FloteEndpointGroup, offeringId: string) {
        super(parent);
        this.relativeUri = `offering-investors/${offeringId}`;
    }

    public revokeAccess(id: string) {
        return this.post(this.getFullUrl(id, "revoke-access"));
    }

    public copyLink(id: string) {
        return this.post(this.getFullUrl(id, "copy-link"));
    }
}
