import cloudinary from 'cloudinary-core';
import {config} from "@/lib/config";

class CloudinaryVue {
  constructor() {
    this.cloudinary = new cloudinary.Cloudinary({
      cloud_name: config.CLOUDINARY_CLOUD_NAME,
      secure: true,
    });
    this.cloudinary.responsive();
  }

  Image(publicId, height, width, gravity, crop, quality, dpr, radius) {
    return this.cloudinary
      .url(publicId, {gravity: gravity, width: 'auto', quality: quality, dpr: dpr ? dpr : 'auto', crop: crop ? crop : 'fill', radius: radius ? radius : 0})
  }
}

export default new CloudinaryVue();
