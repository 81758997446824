


















import {Component, Prop, Vue} from "vue-property-decorator";
import DatepickerCalendar from './DatepickerCalendar.vue';
import {CalendarDateValidator} from "@/components/ui/inputs/date/validator/CalendarDateValidator";
import {DateRangeValidator} from "@/components/ui/inputs/date/validator/DateRangeValidator";
import {StartDateValidator} from "@/components/ui/inputs/date/validator/StartDateValidator";
import {EndDateValidator} from "@/components/ui/inputs/date/validator/EndDateValidator";
import {DefaultCalendarDateValidator} from "@/components/ui/inputs/date/validator/DefaultCalendarDateValidator";
import {DateRounder} from "@/services/helpers/sponsor/date/DateRounder";
import {fromLocalDate, toLocalDate} from "@/services/helpers/sponsor/date/DateConverter";

@Component({
    components: {DatepickerCalendar},
})
export default class BaseDatepicker extends Vue {
    @Prop({default: null})
    public value: Date;

    @Prop({default: false})
    public isOpen: boolean;

    @Prop({default: false})
    public isDisabled: boolean;

    @Prop({default: null})
    public popupClass: string;

    @Prop({default: () => DateRounder.Down})
    public rounder: DateRounder;

    @Prop({default: null})
    public dateFrom: Date;

    @Prop({default: null})
    public dateTo: Date;

    @Prop({default: null})
    public dateValidator: CalendarDateValidator;

    public resolveDateValidator() {
        if (this.dateValidator) {
            return this.dateValidator;
        }
        if (this.dateFrom && this.dateTo) {
            return new DateRangeValidator(this.dateFrom, this.dateTo);
        }
        if (this.dateFrom) {
            return new StartDateValidator(this.dateFrom);
        }
        if (this.dateTo) {
            return new EndDateValidator(this.dateTo);
        }
        return new DefaultCalendarDateValidator();
    }

    public get valueProxy() {
        return toLocalDate(this.value, this.rounder);
    }

    public set valueProxy(value: Date) {
        this.$emit("input", fromLocalDate(value, this.rounder));
    }

    public clear() {
        this.$emit('input', null);
        this.close();
    }

    public changed({finished}) {
        if (finished) {
            this.close();
        }
    }

    public close() {
        this.$emit("close");
    }
};
