export function joinParts(...parts: string[]) {
    var resultArray = [];

    for (var i = 0; i < parts.length; i++) {
        var component = parts[i];

        if (component === '') {
            continue;
        }

        if (i > 0) {
            // Removing the starting slashes for each component but the first.
            component = component.replace(/^[\/]+/, '');
        }
        if (i < parts.length - 1) {
            // Removing the ending slashes for each component but the last.
            component = component.replace(/[\/]+$/, '');
        } else {
            // For the last component we will combine multiple slashes to a single one.
            component = component.replace(/[\/]+$/, '/');
        }

        resultArray.push(component);

    }

    return resultArray.join("/");
}
