import {RequestFilter} from "@/lib/store/state/filters/RequestFilter";

export class DateFilter extends RequestFilter<Date> {
    serialize(): string {
        if (this.value instanceof Date) {
            return this.value.toISOString();
        }
        return this.value;
    }
}
