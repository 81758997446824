














































import {Component, Vue, Prop, Emit} from 'vue-property-decorator'
import {mapGeneralStyles} from "@/config/sponsor/mapGeneralStyles";
import GmapApiCaller from "@/mixins/sponsor/GmapApiCaller";

const mapRedMarker = require("@/assets/sponsor/images/redMarker.svg");
const mapGreenMarker = require("@/assets/sponsor/images/GreenMarker.svg");
const mapOrangeMarker = require("@/assets/sponsor/images/orangeMarker.svg");

export interface mapStyleOptions {
    md: object;
    xs: object
}

export interface markerPositionOption {
    position: object;
}

export interface markersOptions {
    Owned: markerPositionOption[],
    Sold: markerPositionOption[],
    InContract: markerPositionOption[],
}

@Component({
    components: {},
    mixins: [GmapApiCaller],
})
export default class BaseMap extends Vue {
    @Prop({required: true})
    public markers!: markersOptions;

    @Prop({required: false, default: 12})
    public initialZoom!: number;

    @Prop({required: false, default: null})
    public styleOption!: any;

    @Prop({required: false, default: 3})
    public minZoom!: number;

    @Prop({required: false, default: 15})
    public maxZoom!: number;

    @Prop({required: false, default: true})
    public zoomControl!: boolean;

    @Prop({required: false, default: false})
    public streetViewControl!: boolean;

    @Prop({required: false, default: false})
    public mapTypeControl!: boolean;

    @Prop({required: false, default: false})
    public isDraggable!: boolean;

    @Prop({required: false, default: false})
    public isClickable!: boolean;

    @Prop({required: false, default: '300px'})
    public largeScreenHeight!: string;

    @Prop({required: false, default: true})
    public isMarkerIconShown!: boolean;

    @Prop({default: false})
    public hasDefaultCoordinates!: boolean;

    @Emit('dragEnded')
    dragEnded(event) {
        return event;
    };

    public OwnedMarkerOptions: object = {
        url: mapGreenMarker,
    };
    public SoldMarkerOptions: object = {
        url: mapRedMarker,
    };
    public InContractMarkerOptions: object = {
        url: mapOrangeMarker,
    };
    public center: object = {lat: 37.773972, lng: -122.431297};
    public places = [];
    public currentPlace = null;
    public mapStyle: object = {
        disableDefaultUI: true,
        autobindAllEvents: true,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        scrollwheel: false,
        zoomControl: this.zoomControl,
        mapTypeControl: this.mapTypeControl,
        fullscreenControl: false,
        streetViewControl: this.streetViewControl,
        fullscreenOptions: {
            position: 7,
        },
        styles: mapGeneralStyles,
    };
    public mapZoom: number = this.initialZoom;
    public mapStylesOptions: mapStyleOptions = {
        md: {
            width: '100%',
            height: this.largeScreenHeight,
            border: '1px solid #ABABAB',
        },
        xs: {
            width: '100%',
            minHeight: '300px',
            border: '1px solid #ABABAB',
        }
    };
    public mapStyles = this.styleOption;

    mounted() {
        if (!this.hasDefaultCoordinates) {
            this.fitMapBounds();
        }
        this.resizeMap();
        window.onresize = () => {
            this.resizeMap();
        }
    };

    public resizeMap() {
        if (window.outerWidth < 768) {
            this.mapZoom = 11;
            this.mapStyles = this.mapStylesOptions.xs;
        } else {
            this.mapZoom = 12;
            this.mapStyles = this.mapStylesOptions.md;
        }
    };

    public fitMapBounds() {
        //@ts-ignore
        this.$refs.map.$mapPromise.then((map) => {
            const ownedMarkers = this.markers.Owned || [];
            const soldMarkers = this.markers.Sold || [];
            const inContractMarkers = this.markers.InContract || [];
            const markers = [...ownedMarkers, ...soldMarkers, ...inContractMarkers];
            if (markers.length > 1) {
                //@ts-ignore
                let bounds = new this.google.maps.LatLngBounds();
                for (let marker of markers) {
                    bounds.extend(marker.position);
                }
                return map.fitBounds(bounds);
            }
            if (markers.length == 1) {
                map.setCenter(markers[0].position);
            }
            return map.setZoom(this.initialZoom);
        });
    };
}
