import moment, {Moment} from "moment";

export type DateType = Date | Moment;
export type DateValue = DateType | string;

export function toDate(value: DateValue): Date {
    return value ? moment(value).toDate() : null;
}

export function serializeDate(value: Date): string {
    return value ? value.toISOString() : null;
}
