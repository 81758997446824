import {parsePhoneNumberFromString} from 'libphonenumber-js/min';
import {config} from "@/lib/config";

export function formatPhoneNumber(value) {
    if (!value) {
        return "";
    }
    const parsed = parsePhoneNumberFromString(value);
    if (!parsed) {
        return value;
    }
    if (parsed.country === config.DEFAULT_COUNTRY) {
        return parsed.formatNational();
    }
    return parsed.formatInternational();
}
