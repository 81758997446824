import {PaginationData} from "@/lib/api/response/ListResponse";

export class Pagination implements PaginationData {
    public hasNext: boolean = false;
    public totalPageCount: number = 1;
    public page: number = 1;
    public perPage: number = 20;
    public totalCount: number = 1;

    constructor(data?: PaginationData) {
        if (data) {
            this.hasNext = data.hasNext;
            this.totalPageCount = data.totalPageCount;
            this.page = data.page;
            this.perPage = data.perPage;
            this.totalCount = data.totalCount;
        }
    }
}
