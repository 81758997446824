import {isEmpty} from "@/lib/utils/empty";

export abstract class RequestFilter<T> {
    constructor(propName: string) {
        this.paramName = propName;
    }

    public value: T = null;
    public paramName: string;
    public isPredefined: boolean = false;

    public abstract serialize(): string;

    public reset() {
        this.value = null;
    }

    public get isSet() {
        return !isEmpty(this.value);
    }
}
