import store from "../store";
import {SET_ERROR_HANDLER, SET_MODALS, HIDE_ALL_MODALS} from "../store/sponsor/storeConfigs/mutationTypes";
import constants from "@/config/sponsor/constants";

export default (error) => {
    if (error.status === 500) {
        hideAllModals();
        store.state.errorHandler.data.name = constants.errors.internalServerError;
    }
    store.commit(SET_ERROR_HANDLER, error);
}

const openModal = (data) => {
    store.commit(SET_MODALS, data);
};
const hideAllModals = () => {
    store.commit(HIDE_ALL_MODALS);
};
