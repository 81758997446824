import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Asset} from "@/services/store/modules/assets/Asset";
import {initEntity} from "@/services/entities/sponsor/BaseFloteEntity";
import {
    HIDE_MODALS, REMOVE_IMAGE_IS_PROCESSES,
    SET_ALL_ASSETS,
    SET_ASSET_BY_ID, SET_ASSET_GALLERY_PHOTO,
    SET_ASSET_MAIN_PHOTO, SET_ERROR_HANDLER,
} from "@/services/store/sponsor/storeConfigs/mutationTypes";
import O_o from "@/services/helpers/sponsor/promiseHandler";
import http from "@/services/helpers/sponsor/managers/requestManager";
import {DELETE, GET, PATCH, POST} from "@/services/helpers/sponsor/actionMethods";
import {ASSETS} from "@/services/store/sponsor/storeConfigs/actionUrls";
import constants from "@/config/sponsor/constants";
import {replaceEmptyValueWithNull} from "@/services/helpers/sponsor/replaceEmptyValueWithNull";
import {AssetSingleRoute} from "@/services/router/sponsor/assets";
import {routerNavigateTo} from "@/lib/router/Navigator";

@Module({namespaced: true})
class AssetStore extends VuexModule {
    public allAssets: Asset[] = [];
    public assetById: Asset = initEntity(Asset, {});

    @Mutation
    public [SET_ALL_ASSETS](data: Asset[]) {
        this.allAssets = data.map(i => initEntity(Asset, i));
    };

    @Mutation
    public [SET_ASSET_BY_ID](data: Asset) {
        this.assetById = initEntity(Asset, data);
    };

    @Mutation
    public [SET_ASSET_MAIN_PHOTO](data) {
        this.assetById.mainPhoto = data;
    };

    @Mutation
    public [SET_ASSET_GALLERY_PHOTO](data) {
        this.assetById.images.push(data);
    };

    @Action
    public async getAllAssets(data) {
        const [error, response] = await O_o(http.send(GET(ASSETS, constants.isLoadingStates.portfolio)));
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_ALL_ASSETS, response.items);
        return response;
    };

    @Action
    public async deleteAsset(data) {
        this.context.commit(HIDE_MODALS, constants.modals.deleteAsset);
        await this.context.dispatch("setLoaderForRequests", {root: true});
        const [error, response] = await O_o(http.send(DELETE(`${ASSETS}/${data.assetId}`)));
        await this.context.dispatch("removeLoaderForRequests", {root: true});
        if (error) {
            throw error;
        }

        await this.context.dispatch("getAllAssets");

        return response;
    };

    @Action
    public emptyAsset() {
        this.context.commit(SET_ASSET_BY_ID, {});
    };

    @Action
    public async getAssetById(data) {
        const [error, response] = await O_o(http.send(GET(`${ASSETS}/${data.assetId}`, constants.isLoadingStates.getAssetById)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_ASSET_BY_ID, response);
        return response;
    };

    @Action
    public async addMainPhoto(data) {
        this.context.commit(SET_ASSET_MAIN_PHOTO, data);
    };

    @Action
    public async addAssetGalleryPhoto(data) {
        this.context.commit(SET_ASSET_GALLERY_PHOTO, data);
    };

    @Action
    public async editOrCreateAsset(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});
        const request = data.assetId ?
            PATCH(`${ASSETS}/${data.assetId}`) :
            POST(ASSETS)
        ;
        const [error, response] = await O_o(
            http.send(request, data.formatData),
        );
        await this.context.dispatch("removeLoaderForRequests", null, {root: true});
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_ASSET_BY_ID, response);
        if (data.ImageIsProcesses) {
            this.context.commit(REMOVE_IMAGE_IS_PROCESSES, data.ImageIsProcesses);
        }
        if (response.id) {
            routerNavigateTo(AssetSingleRoute.Name, {id: response.id});
        }
    };

    get assets() {
        return this.allAssets;
    };

    get filterAssetType() {
        let assetTypes = {
            ownedAssets: [],
            inContractAssets: [],
            soldAssets: [],
        };
        //TODO change this.
        this.allAssets.forEach((item) => {
            if (item.status === "Owned") {
                assetTypes.ownedAssets.push(item);
            }
            if (item.status === "Sold") {
                assetTypes.soldAssets.push(item);
            }
            if (item.status === "InContract") {
                assetTypes.inContractAssets.push(item);
            }
        });
        return assetTypes;
    };
}

export default AssetStore;
