import {OptionFilter} from "@/lib/store/state/filters/OptionFilter";

export class TransactionStatusFilter extends OptionFilter {
    public defaultValue: string = null;
    public defaultText: string = "Status";

    constructor(propName: string) {
        super(propName, [
            {text: "Published", value: "true"},
            {text: "Unpublished", value: "false"},
        ]);
    }
}
