import {
    ContactEditingRoute,
    ContactsRoute,
    ContactsSingeRoute,
    CreateContactRoute
} from "@/services/router/sponsor/contacts";
import {BasicNavigator} from "@/core/navigation/BasicNavigator";

export class ContactsNavigator extends BasicNavigator {
    public list() {
        return this.route(ContactsRoute);
    }

    public create() {
        return this.route(CreateContactRoute);
    }

    public single(id: string) {
        return this.route(ContactsSingeRoute, {id: id});
    }

    public edit(id: string) {
        return this.route(ContactEditingRoute, {id: id});
    }
}
