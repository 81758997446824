import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {FloteResponse} from "@/lib/api/response/FloteResponse";

export class UserApi extends FloteEndpointGroup {
    public relativeUri: string = "user";

    public current() {
        return this.get("");
    }
}
