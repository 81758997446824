import actions from "./csvActions";
import getters from "./csvGetters";
import state from "./csvState";
import mutations from "./csvMutations";

const csv = {
  actions,
  getters,
  state,
  mutations,
};

export default csv;
