import actions from "./offeringsActions";
import getters from "./offeringsGetters";
import state from "./offeringsState";
import mutations from "./offerringsMutations";

const offering = {
  actions,
  getters,
  state,
  mutations
};

export default offering;
