import moment from "moment-timezone";
import {DateRounder} from "@/services/helpers/sponsor/date/DateRounder";
import {Moment} from "moment";
import {config} from "@/lib/config";

export class DateConverter {
    constructor(private readonly timezone: string = config.DEFAULT_TIMEZONE) {
    }

    public fromLocal(date: Date, rounder?: DateRounder): Date {
        let convertedDate = moment
            .tz(moment(date.toISOString()).utcOffset(0, true)
                .format("YYYY-MM-DDTHH:mm:ss"), this.timezone);

        return this.toUtcDate(this.round(convertedDate, rounder));
    }

    public toLocal(date: Date, rounder?: DateRounder): Date {
        let convertedDate = moment(
            moment(date.toISOString()).tz(this.timezone)
                .format("YYYY-MM-DDTHH:mm:ss")
        );
        return this.toUtcDate(this.round(convertedDate, rounder));
    }

    private round(date: Moment, rounder?: DateRounder): Moment {
        return rounder ? rounder.round(date) : date;
    }

    private toUtcDate(date: Moment): Date {
        return date
            .utc()
            .toDate();
    }
}

const dateConverter = new DateConverter();

export function toLocalDate(date: Date, rounder?: DateRounder) {
    return date ? dateConverter.toLocal(date, rounder) : null;
}

export function fromLocalDate(date: Date, rounder?: DateRounder) {
    return date ? dateConverter.fromLocal(date, rounder) : null;
}
