import {
    SET_ERROR_HANDLER,
    REMOVE_ERROR_HANDLER,
    SET_MODALS,
    HIDE_MODALS,
    HIDE_ALL_MODALS,
    SET_IS_LOADING,
    SET_IS_REQUEST_PROCESSING,
    REMOVE_IS_REQUEST_PROCESSING,
    REMOVE_IS_LOADING,
    SET_IMAGE_IS_PROCESSES,
    REMOVE_IMAGE_IS_PROCESSES,
    SET_ERROR_IN_ERROR_HANDLER,
    EMPTY_ERRORS,
    SET_SCROLLABLE_MAIN_CONTAINER
} from "./sponsor/storeConfigs/mutationTypes";
import {validationErrorMessages} from "@/config/sponsor/validationErrorConfigs";
import {
    REMOVE_IS_LOADED,
    REMOVE_IS_MODAL_SHOWN,
    SET_IS_LOADED,
    SET_IS_MODALS_SHOWN, SET_SUCCESS_MODAL_TEXT, SET_SUGGESTED_VEHICLES_LIST
} from "@/services/store/investor/config/mutationTypes";

export default {
    [SET_ERROR_HANDLER](state, data) {
        if (!data.data) {
            data.data = {};
        }
        if (!data.data.errors) {
            data.data.errors = {};
        }
        state.errorHandler = data;
    },
    [SET_ERROR_IN_ERROR_HANDLER](state, props) {
        state.errorHandler = {
            ...state.errorHandler,
            data: {
                ...state.errorHandler.data,
                errors: {
                    ...state.errorHandler.data.errors,
                    [props.fieldName]: props.message ? props.message : validationErrorMessages.required,
                }
            }
        }
    },
    [REMOVE_ERROR_HANDLER](state, fieldName) {
        delete state.errorHandler.data.errors[fieldName];
    },
    [SET_MODALS](state, data) {
        state.modals.push(data);
    },
    [HIDE_MODALS](state, data) {
        state.modals = state.modals.filter(item => item !== data);
    },
    [HIDE_ALL_MODALS](state) {
        state.modals = [];
    },
    [SET_IS_LOADING](state, data) {
        state.isLoading.push(data);
    },
    [REMOVE_IS_LOADING](state, data) {
        state.isLoading = state.isLoading.filter(item => item !== data);
    },
    [SET_IS_REQUEST_PROCESSING](state, data) {
        state.isRequestProcessing = true;
    },
    [REMOVE_IS_REQUEST_PROCESSING](state, data) {
        state.isRequestProcessing = false;
    },
    [EMPTY_ERRORS](state) {
        state.errorHandler.data = {errors: {}};
    },

    // TODO: Check this later...
    setDocumentId(state, data) {
        state.documentId = data;
    },
    [SET_SCROLLABLE_MAIN_CONTAINER](state, data) {
        state.scrollableMainContainer = data;
    },

    [SET_IS_MODALS_SHOWN](state, data) {
        state.checkModal.push(data);
    },
    [REMOVE_IS_MODAL_SHOWN](state, data) {
        state.checkModal = state.checkModal.map(item => item !== data);
    },
    [SET_IS_LOADED](state, data) {
        state.isLoaded.push(data);
    },
    [REMOVE_IS_LOADED](state, data) {
        state.isLoaded = state.isLoaded.map(item => item !== data);
    },
    [SET_SUGGESTED_VEHICLES_LIST](state, data) {
        state.suggestedVehiclesList = data;
    },
    [SET_SUCCESS_MODAL_TEXT](state, data) {
        state.successModalText = data
    },
};
