import {Metadata} from "@/lib/metadata/Metadata";
import {ResponseField} from "@/lib/api/response/meta/ResponseField";

export class ResponseMetadata extends Metadata {
    public fields: ResponseField[] = [];

    public copy(): Metadata {
        const meta = new ResponseMetadata(this.model, this.baseMeta);
        meta.fields = this.fields.map(f => f.copy());

        return meta;
    }

    public addField(field: ResponseField) {
        this.fields.push(field);
    }
}
