import {FiltersState} from "@/lib/store/state/filters/FiltersState";
import {SearchFilter} from "@/lib/store/state/filters/SearchFilter";
import {TransactionStatusFilter} from "@/services/store/lib/filters/TransactionStatusFilter";

export class TransactionFilters extends FiltersState {
    constructor() {
        super();
    }

    public status = new TransactionStatusFilter("f_is_published");
    public search = new SearchFilter();
}
