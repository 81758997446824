




import {Component, Prop, Vue} from "vue-property-decorator";
import {cloudinaryApi} from "@/core/integrations/cloudinary/CloudinaryApi";

@Component
export default class FloteImage extends Vue {
    @Prop({required: true})
    public value: string;

    @Prop({required: true})
    public alt: string;

    @Prop()
    public height: string|number;

    @Prop()
    public width: string|number;

    @Prop({default: "fill"})
    public crop: string;

    @Prop({default: "2.0"})
    public dpr: string;

    @Prop({default: "auto:best"})
    public quality: string;

    @Prop({default: null})
    public gravity: string;

    @Prop({default: null})
    public radius: string;

    public get url() {
        return cloudinaryApi.getUrl(this.value, {
            height: this.height,
            width: this.width,
            crop: this.crop,
            dpr: this.dpr,
            quality: this.quality,
            gravity: this.gravity,
            radius: this.radius,
        });
    }
};
