import actions from './chartsActions';
import getters from './chartsGetters';
import state from './chartsState';
import mutations from './chartsMutations';

const charts = {
    actions,
    getters,
    mutations,
    state,
};

export default charts;
