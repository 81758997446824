import {Router} from "@/lib/router/Router";
import {Route} from "@/lib/router/Route";

export const InvestmentRouter = new Router();

@InvestmentRouter.register()
export class InvestorDocumentsRoute extends Route {
    getComponent() {
        return import("@/views/Documents.vue")
    }

    getPath() {
        return "/documents";
    }
}

@InvestmentRouter.register()
export class InvestmentsRoute extends Route {
    getComponent() {
        return import("@/views/investor/Investments.vue")
    }

    getPath() {
        return "/investments";
    }
}

@InvestmentRouter.register()
export class UserProfileRoute extends Route {
    getComponent() {
        return import("@/views/Contacts/UserProfilePage.vue")
    }

    getPath() {
        return "/user/:type";
    }
}

@InvestmentRouter.register()
export class ManageInvestmentRoute extends Route {
    getComponent() {
        return import("@/views/investor/ManageInvestment.vue")
    }

    getPath() {
        return "/investments/manage/:positionId";
    }
}

@InvestmentRouter.register()
export class InvestmentAssetRoute extends Route {
    getComponent() {
        return import("@/views/Asset/AssetSinglePage.vue");
    }

    getPath() {
        return "/investments/manage/:positionId/asset/:id";
    }
}

@InvestmentRouter.register()
export class OfferingReservationRoute extends Route {
    getComponent() {
        return import("@/views/investor/CreateReservation.vue");
    }

    getPath(): string {
        return "/offerings/:id/reserve/:inviteId";
    }
}

@InvestmentRouter.register()
export class OfferingSingleRoute extends Route {
    getComponent() {
        return import("@/views/investor/OfferingsSinglePage.vue")
    }

    getPath() {
        return "/offerings/:offeringId";
    }
}

@InvestmentRouter.register()
export class InvestorOfferingsRoute extends Route {
    getComponent() {
        return import("@/views/investor/Offers.vue")
    }

    getPath() {
        return "/offerings";
    }
}
