

















import {Component, Prop, Vue} from "vue-property-decorator";
import BaseModal from "./BaseModal.vue";
import {ModalState} from "@/core/modals/ModalState";

@Component({
    components: {BaseModal},
})
export default class ConfirmationModal extends Vue {
    @Prop({required: true})
    public modalState: ModalState;

    @Prop({default: () => ({})})
    public configs;

    public close() {
        this.$emit("close");
        this.modalState.close();
    }
}
