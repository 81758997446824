import {Constructor} from "@/lib/types";

export abstract class Metadata {
    public model: Constructor<any>;
    public baseMeta: Metadata = null;

    constructor(model: Constructor<any>, baseMeta: Metadata) {
        this.model = model;
        this.baseMeta = baseMeta;
    }

    public abstract copy(): Metadata;
}
