import {BaseModule, ModuleClass} from "@/lib/store/modules/BaseModule";
import {NotImplementedError} from "@/lib/errors";
import {Constructor} from "@/lib/types";

export class ModuleMember {
    protected readonly moduleClass: ModuleClass;
    protected readonly key: string;
    public readonly name: string;
    public descriptor: PropertyDescriptor;
    constructor(module: ModuleClass, key: string, descriptor: PropertyDescriptor) {
        this.moduleClass = module;
        this.key = key;
        this.name = key;
        this.descriptor = descriptor;
    }

    public static Register() {
        return (target: BaseModule, key?: string, descriptor?: PropertyDescriptor) => {
            const module = target.constructor as ModuleClass;
            const meta = module.getOrInitMeta();
            const member = new this(module, key, descriptor);
            meta.register(member);
        }
    }

    public toVuex(module: BaseModule) {
        throw new NotImplementedError();
    }

    public copy(): this {
        return new (this.constructor as Constructor<this>)(this.moduleClass, this.key, this.descriptor);
    }
}
