import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const AffiliatesSponsorRouter = new Router();

@AffiliatesSponsorRouter.register()
export class AffiliateSponsorCreationRoute extends Route {
    getComponent() {
        return import("@/views/EditCreateAffiliate.vue");
    }

    getPath(): string {
        return "contacts/:contactId/affiliates/create";
    }
}

@AffiliatesSponsorRouter.register()
export class AffiliateSponsorEditRoute extends Route {
    getComponent() {
        return import("@/views/EditCreateAffiliate.vue");
    }

    getPath(): string {
        return "contacts/:contactId/affiliates/:id/edit";
    }
}
