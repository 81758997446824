import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const AffiliatesInvestorRouter = new Router();

@AffiliatesInvestorRouter.register()
export class AffiliateInvestorCreationRoute extends Route {
    getComponent() {
        return import("@/views/EditCreateAffiliate.vue");
    }

    getPath(): string {
        return "affiliates/create";
    }
}

@AffiliatesInvestorRouter.register()
export class AffiliateInvestorEditRoute extends Route {
    getComponent() {
        return import("@/views/EditCreateAffiliate.vue");
    }

    getPath(): string {
        return "affiliates/:id/edit";
    }
}
