import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const OfferingsRouter = new Router();

@OfferingsRouter.register()
export class OfferingVehicleAttachmentRoute extends Route {
    getComponent() {
        return import("@/views/OfferingsVehicleAttachment.vue");
    }

    getPath(): string {
        return "offerings/vehicles/choose";
    }
}

@OfferingsRouter.register()
export class OfferingCreationRoute extends Route {
    getComponent() {
        return import("@/views/OfferingsCreation.vue");
    }

    getPath(): string {
        return "offerings/:offeringId/creation";
    }
}

@OfferingsRouter.register()
export class OfferingsRoute extends Route {
    getComponent() {
        return import("@/views/OfferingsListing.vue");
    }

    getPath(): string {
        return "offerings";
    }
}

@OfferingsRouter.register()
export class ManageOfferingRoute extends Route {
    getComponent() {
        return import("@/views/ManageOfferingSinglePage.vue");
    }

    getPath(): string {
        return "offerings/:offeringId/manage";
    }
}

@OfferingsRouter.register()
export class ViewOfferingRoute extends Route {
    getComponent() {
        return import("@/views/ViewOfferingPage.vue");
    }

    getPath(): string {
        return "offerings/:offeringId/view";
    }
}

@OfferingsRouter.register()
export class EditOfferingRoute extends Route {
    getComponent() {
        return import("@/views/EditOffering.vue");
    }

    getPath(): string {
        return "offerings/:offeringId/edit";
    }
}
