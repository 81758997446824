export type RequestExecutor<T> = () => Promise<T>

class RequestPromise<T> {
    public readonly resolve: (exec: Promise<T>) => void;
    public readonly reject: (err: Error) => void;
    public readonly executor: RequestExecutor<T>;

    constructor(resolve: (exec: Promise<T>) => void, reject: (err: Error) => void, executor: RequestExecutor<T>) {
        this.resolve = resolve;
        this.reject = reject;
        this.executor = executor;
    }

    public execute() {
        this.resolve(this.executor());
    }
}

export class RequestQueue {
    public isBlocked: boolean = false;
    private queue: RequestPromise<any>[] = [];

    public block() {
        this.isBlocked = true;
    }

    public unblock(err?: Error) {
        this.isBlocked = false;

        for (const req of this.queue) {
            if(err) {
                req.reject(err);
            } else {
                req.execute();
            }
        }
    }

    public enqueue<T>(executor: RequestExecutor<T>): Promise<T> {
        return new Promise(((resolve, reject) => {
            this.addRequest(new RequestPromise(resolve, reject, executor));
        }));
    }

    private addRequest(requestPromise: RequestPromise<any>) {
        if (!this.isBlocked) {
            requestPromise.execute();
        } else {
            this.queue.push(requestPromise);
        }
    }
}
