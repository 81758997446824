export class LoaderState {
    private counter = 0;

    public startTask() {
        this.counter++;
    }

    public completeTask() {
        this.counter--;
    }

    get isLoading() {
        return this.counter !== 0;
    }

    public async execute<T>(task: () => Promise<T>): Promise<T> {
        this.startTask();
        try {
            return await task();
        } finally {
            this.completeTask();
        }
    }
}
