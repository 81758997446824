import ResourceRepositoryModule from "@/services/store/lib/ResourceRepositoryModule";
import {Asset} from "@/services/store/modules/assets/Asset";
import {initEntity} from "@/services/entities/sponsor/BaseFloteEntity";

export class AssetRepositoryModule extends ResourceRepositoryModule<Asset> {
    public getEmptyResourceInstance(id: string): Asset {
        return initEntity(Asset, {
            id: id,
        });
    }
}