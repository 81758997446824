import Vue from "vue";
import dropdown from "../../../components/sponsor/ui/baseComponents/BaseDropdownInput.vue";
import textInput from "../../../components/sponsor/ui/baseComponents/BaseTextInput.vue";
import DatePicker from "../../../components/sponsor/ui/baseComponents/Datepicker/Datepicker.vue";
import LocationInput from "../../../components/ui/inputs/LocationInput.vue";
import Image from "./cloudinary/Image.vue";
import DropdownDatePicker from "../../../components/sponsor/ui/baseComponents/DropdownDatePicker.vue";
import LimitedCharsInput from "../../../components/sponsor/ui/baseComponents/LimitedCharsInput.vue";
import SearchInput from "../../../components/sponsor/ui/baseComponents/SearchInput.vue";
import Checkbox from "../../../components/sponsor/ui/baseComponents/Checkbox.vue";
import InvestorImage from '../investor/cloudinary/Image.vue';
import RootInput from '../../../components/investor/UI/BaseComponents/RootInput.vue';
import BaseDropdownInput from '../../../components/investor/UI/BaseComponents/BaseDropdownInput.vue';
import FormattableInput from "@/components/sponsor/ui/baseComponents/FormattableInput.vue";
import FloteInput from "@/components/ui/inputs/FloteInput.vue";
import FormInput from "@/components/ui/inputs/form-inputs/FormInput.vue";
import FormDatePicker from "@/components/ui/inputs/date/FormDatePicker.vue";
import FloteDatePicker from "@/components/ui/inputs/date/FloteDatePicker.vue";
import FloteDropdown from "@/components/ui/inputs/FloteDropdown.vue";
import FormDropdown from "@/components/ui/inputs/form-inputs/FormDropdown.vue";
import DotLoader from "@/components/sponsor/ui/loaders/DotLoader.vue";
import LocalLoader from "@/components/ui/loaders/LocalLoader.vue";
import FloteImage from "@/components/ui/images/FloteImage.vue";
import ImageInput from "@/components/ui/images/ImageInput.vue";
import FormLimitedTextArea from "@/components/ui/inputs/form-inputs/FormTextArea.vue";

Vue.component("flote-input", FloteInput);
Vue.component("form-input", FormInput);
Vue.component("FormLimitedTextArea", FormLimitedTextArea);
Vue.component("flote-date-picker", FloteDatePicker);
Vue.component("form-date-picker", FormDatePicker);
Vue.component("flote-dropdown", FloteDropdown);
Vue.component("form-dropdown", FormDropdown);
Vue.component("location-input", LocationInput);
Vue.component("dot-loader", DotLoader);
Vue.component("local-loader", LocalLoader);
Vue.component("flote-image", FloteImage);
Vue.component("image-input", ImageInput);


// todo: to be removed
Vue.component("drop-down", dropdown);
Vue.component("text-input", textInput);
Vue.component("formattable-text-input", FormattableInput);
Vue.component("vue-datepicker", DatePicker);
Vue.component("cl-image", Image);
Vue.component("dropdown-datepicker", DropdownDatePicker as any);
Vue.component("limited-chars-input", LimitedCharsInput);
Vue.component("search-input", SearchInput);
Vue.component("checkbox", Checkbox);
Vue.component('investor-cl-image', InvestorImage);
Vue.component('investor-root-input', RootInput);
Vue.component('investor-drop-down', BaseDropdownInput);

