import {VuexModule, Mutation, Action, Module} from 'vuex-module-decorators'
import O_o from '../../helpers/sponsor/promiseHandler';
import {SPONSOR_INFO} from '../sponsor/storeConfigs/actionUrls';
import {SET_SPONSOR_INFO, SET_SPONSOR_TERMS, SET_SPONSOR_POLICY} from '../sponsor/storeConfigs/mutationTypes';
import http from '../../helpers/sponsor/managers/requestManager';
import {GET} from '../../helpers/sponsor/actionMethods';
import {initEntity} from "@/services/entities/sponsor/BaseFloteEntity";
import {SPONSOR_POLICY, SPONSOR_TERMS} from "@/services/store/investor/config/actionUrls";
import {Sponsor} from "@/services/entities/investor/Sponsor";

@Module({namespaced: true})
class SponsorStore extends VuexModule {
    public sponsor: Sponsor = initEntity(Sponsor, {});
    public terms: object = null;
    public policy: object = null;

    @Mutation
    public [SET_SPONSOR_INFO](data: Sponsor): void {
        this.sponsor = data
    }

    @Mutation
    public [SET_SPONSOR_TERMS](data) {
        this.terms = data
    }

    @Mutation
    public [SET_SPONSOR_POLICY](data) {
        this.policy = data
    }

    get loginCoverPhotoId() {
        return this.sponsor.investorLoginCoverPhoto || 'a/evauuzpe1jopig3pohac';
    }

    get resetPassPhotoId() {
        return this.sponsor.resetPasswordCoverPhoto || 'a/evauuzpe1jopig3pohac';
    }

    get setPassPhotoId() {
        return this.sponsor.setPasswordCoverPhoto || 'a/evauuzpe1jopig3pohac';
    }

    @Action
    public async getSponsorInfo(): Promise<void> {
        const [error, response] = await O_o(http.send(GET(SPONSOR_INFO)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_SPONSOR_INFO, response);
    }

    @Action
    public async getTerms() {
        const [error, response] = await O_o(http.send(GET(SPONSOR_TERMS)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_SPONSOR_TERMS, response);
    }

    @Action
    public async getPolicy() {
        const [error, response] = await O_o(http.send(GET(SPONSOR_POLICY)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_SPONSOR_POLICY, response);
    }
}

export default SponsorStore;
