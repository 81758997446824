import {User} from "@/services/store/modules/auth/User";
import {RepositoryItemState} from "@/services/store/lib/RepositoryState";
import {ErrorState} from "@/lib/store/state/errors/ErrorState";
import {LoaderState} from "@/lib/store/state/loading/LoaderState";

export type CurrentUserState = RepositoryItemState<User>;

export class AuthModuleState {
    public currentUser: CurrentUserState = {
        loader: new LoaderState(),
        errors: new ErrorState(),
        data: null,
    };

}
