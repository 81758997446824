import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {
    AffiliateSponsorCreationRoute, AffiliateSponsorEditRoute
} from "@/services/router/sponsor/affiliates";
import {AffiliateInvestorCreationRoute, AffiliateInvestorEditRoute} from "@/services/router/investor/affiliates";

export class AffiliatesSponsorNavigator extends BasicNavigator {
    public create(contactId: string) {
        return this.route(AffiliateSponsorCreationRoute, {contactId: contactId});
    }
    public edit(contactId: string, id: string) {
        return this.route(AffiliateSponsorEditRoute, {contactId: contactId, id: id});
    }
}

export class AffiliatesInvestorNavigator extends BasicNavigator {
    public create() {
        return this.route(AffiliateInvestorCreationRoute);
    }
    public edit(id: string) {
        return this.route(AffiliateInvestorEditRoute, {id: id});
    }
}
