import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const EntitiesInvestorRouter = new Router();

@EntitiesInvestorRouter.register()
export class EntityEditInvestorRoute extends Route {
    getComponent() {
        return import("@/components/sections/EntityEditCreate.vue");
    }

    getPath(): string {
        return "entities/:id/edit";
    }
}
