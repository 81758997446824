






















import {Component, Prop, Vue} from "vue-property-decorator";
import {FormField} from "@/lib/forms/fields/FormField";

@Component({})
export default class FormInput extends Vue {
    @Prop({required: true})
    public field!: FormField<any>;

    public get errorMessage() {
        return this.field.errorMessages.join("\n");
    }

    public mounted() {
        this.field.elementRef((this.$refs.inputElement as Vue).$el.getElementsByTagName("input")[0] as HTMLInputElement);
    }
}
