import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {ChangePasswordRoute, SignInRoute} from "@/services/router/investor";
import {UserProfileRoute} from "@/services/router/investor/investment";
import {AffiliatesInvestorNavigator} from "@/core/navigation/navigators/AffiliatesNavigator";
import {EntitiesInvestorNavigator} from "@/core/navigation/navigators/EntitiesNavigator";
import {OfferingsInvestorNavigator} from "@/core/navigation/navigators/OfferingsNavigator";

export class InvestorNavigator extends BasicNavigator {
    public signIn() {
        return this.route(SignInRoute);
    }
    public profile() {
        return this.route(UserProfileRoute, {type: 'profile'});
    }

    public changePassword() {
        return this.route(ChangePasswordRoute);
    }

    public readonly affiliates = new AffiliatesInvestorNavigator();
    public readonly entities =  new EntitiesInvestorNavigator();
    public readonly offerings = new OfferingsInvestorNavigator();
}
