export default {
    suggestedVehiclesByTitle(state) {
        const list = state.allVehicles.map(item => ({
            text: item.legalName,
            value: item.id,
            disabled: false
        }));
        return [{value: null, text: "Choose Vehicle", disabled: true}, ...list]
    },
    singleVehicleAssets(state) {
        return state.singleVehicle.project.assets;
    },
    vehiclesOptionsByProject(state) {
        const list = state.projectVehicles.map(item => ({
            text: item.legalName,
            value: item.id,
            disabled: false
        }));
        return [{value: null, text: "Choose Vehicle", disabled: true}, ...list]
    },
    vehicleOptionsByUser(state) {
        const vehicles = state.vehiclesByUserId.map(item => ({
            text: item.legalName,
            value: item.id,
            disabled: false
        }));
        return [
            {text: "Vehicle", value: null, disabled: true},
            ...vehicles
        ]
    },
}
