import {BaseFloteEntity} from './BaseFloteEntity';

export class Sponsor extends BaseFloteEntity {
    address: string;
    blackLogo: string;
    whiteLogo: string;
    city: string;
    country: string;
    defaultTimezone: string;
    description: string;
    name: string;
    platformName: string;
    postalCode: string;
    state: string;
    streetAddress: string;
    investorLoginCoverPhoto: string;
    resetPasswordCoverPhoto: string;
    setPasswordCoverPhoto: string;

}
