export default function getByQuarters(data) {
  const map = data.reduce((r, i) => {
    const key = `Y${i['year']}Q${i['quarter']}`;
    const val = r.get(key) || [];
    val.push(i);
    r.set(key, val);
    return r;
  }, new Map());
  const result = [];
  map.forEach((v, k) => {
    const max =  v.reduce((acc, val) => {
      if (typeof acc === "undefined") return  val;
      if (val["month"] > acc["month"]) {
        return val;
      }
      return acc;
    }, undefined);
    result.push(max);
  });
  return result;
}
