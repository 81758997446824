import {
    SET_ALL_VEHICLES,
    SET_SUGGESTED_VEHICLES_LIST,
    SET_TRANSACTION_BY_VEHICLE_ID,
    SET_VEHICLE_BY_ID,
    SET_VEHICLES_BY_USER,
    SET_VEHICLES_BY_PROJECT,
} from "../../storeConfigs/mutationTypes";

export default {
    [SET_VEHICLE_BY_ID](state, data) {
        state.singleVehicle = data;
    },
    [SET_VEHICLES_BY_USER](state, data) {
        state.vehiclesByUserId = data;
    },
    [SET_ALL_VEHICLES](state, data) {
        state.allVehicles = data;
    },
    [SET_SUGGESTED_VEHICLES_LIST](state, data) {
        state.suggestedVehiclesList = data;
    },
    [SET_TRANSACTION_BY_VEHICLE_ID](state, data) {
        state.transactionsById = data;
    },
    [SET_VEHICLES_BY_PROJECT](state, data) {
        state.projectVehicles = data;
    },
};
