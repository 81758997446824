import {
  SET_CURRENT_USER_INFO,
  SET_PASSWORD_CHANGE,
  SET_NDA,
} from '../../config/mutationTypes';

export default {
  [SET_CURRENT_USER_INFO](state, data) {
    state.currentUserInfo = data;
  },
  [SET_PASSWORD_CHANGE](state, data) {
    state.passwordHasChanged = data;
  },
};
