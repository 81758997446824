import {FloteResponse} from "@/lib/api/response/FloteResponse";
import {ResponseField} from "@/lib/api/response/decorators";
import {Pojo} from "@/lib/types";

export interface PaginationData {
    hasNext: boolean;
    totalPageCount: number;
    page: number;
    perPage: number;
    totalCount: number;
}

export class ListResponse<M = Pojo> extends FloteResponse implements PaginationData {
    @ResponseField() public items: M[];
    @ResponseField() public hasNext: boolean;
    @ResponseField() public totalPageCount: number;
    @ResponseField() public page: number;
    @ResponseField() public perPage: number;
    @ResponseField() public totalCount: number;
}
