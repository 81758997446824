import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {OfferingReservationRoute, OfferingSingleRoute} from "@/services/router/investor/investment";
import {ManageOfferingRoute, ViewOfferingRoute} from "@/services/router/sponsor/offerings";

export class OfferingsInvestorNavigator extends BasicNavigator {
    public reservation(id: string, inviteId: string) {
        return this.route(OfferingReservationRoute, {id: id, inviteId: inviteId});
    }

    public offering(id: string) {
        return this.route(OfferingSingleRoute, {offeringId: id});
    }
}

export class OfferingsSponsorNavigator extends BasicNavigator {
    public single(id: string) {
        return this.route(ViewOfferingRoute, {offeringId: id});
    }

    public manageOffering(id: string) {
        return  this.route(ManageOfferingRoute, {offeringId: id});
    }
}
