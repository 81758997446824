import store from '../../store';

export function openModal(open) {
  store.commit('setIsModalsShown', open);
}
export function closeModal(close) {
  store.commit('removeIsModalShown', close);
}

export function openCloseModals(open, close) {
  closeModal(close);
  openModal(open);
}
