import {
    SET_LINE_CHART_DATA,
    SET_DOUGHNUT_DATA,
} from '../../config/mutationTypes';

export default {
    [SET_LINE_CHART_DATA](state, data) {
        state.lineChartData = data;
    },
    [SET_DOUGHNUT_DATA](state, data) {
        state.doughnutData = data;
    },
};
