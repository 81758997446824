import {CalendarDateValidator} from "@/components/ui/inputs/date/validator/CalendarDateValidator";
import moment from "moment";

export class EndDateValidator extends CalendarDateValidator {
    public readonly maxDate: Date;
    constructor(maxDate: Date) {
        super();
        this.maxDate = maxDate;
    }
    public isValid(date: Date): boolean {
        return  moment(date).isSameOrBefore(this.maxDate, "day");
    }
}
