import BigNumber from "bignumber.js";
import {isValidNumber} from "./lib";

export const Decimal = BigNumber;
export type Decimal = BigNumber;

Decimal.prototype["isValid"] = function () {
    return isValidNumber(this);
};

export type NumericValue = number | string | Decimal;

export function zero() {
    return new Decimal(0);
}

export function one() {
    return new Decimal(1);
}

export function decimal(n: NumericValue, defaultValue?: NumericValue) {
    let value = new Decimal(n);

    if (!isValidNumber(value)) {
        if (typeof defaultValue !== "undefined") {
            value = decimal(defaultValue);
        } else {
            console.warn(value.toString());
        }
    }

    return value;
}

export function decimalOrNull(n: NumericValue) {
    if (!n) {
        return null;
    }
    return decimal(n);
}

export function decimalOrZero(n: NumericValue) {
    return decimal(n, zero());
}
