import {Formatters} from "@/lib/forms/formatters/Formatters";
import {TaxIdType} from "@/core/enums/TaxIdType";

export function formatTaxId(value: string, taxType: TaxIdType) {
    return Formatters.TaxId(taxType).format(value);
}

export function formatTaxIdUnmasked(value: string, taxType: TaxIdType) {
    return Formatters.TaxId(taxType).mask(value);
}
