import {VuexModule, Mutation, Action, Module} from 'vuex-module-decorators'
import O_o from '../../../helpers/sponsor/promiseHandler';
import {SET_COUNTRIES_LIST, SET_STATES_LIST} from '../storeConfigs/mutationTypes';
import http from '../../../helpers/sponsor/managers/requestManager';
import {GET} from '../../../helpers/sponsor/actionMethods';
import {DropdownOptions} from "@/mixins/sponsor/TypedGetType";

export interface States {
    name: string,
    abbreviation: string
}

@Module({namespaced: true})
class Regions extends VuexModule {
    public countries: [] = [];
    public states: States[] = [];

    @Mutation
    public [SET_COUNTRIES_LIST](data) {
        this.countries = data;
    }

    @Mutation
    public [SET_STATES_LIST](data) {
        this.states = data;
    }

    @Action
    public async getCountriesList() {
        const [error, response] = await O_o(http.sendRequest(GET(`https://restcountries.eu/rest/v1/all`)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_COUNTRIES_LIST, response);
    };

    @Action
    public async getStatesList() {
        const [error, response] = await O_o(http.send(GET(`/etc/config`)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_STATES_LIST, response.enums.states.US);
    };

    get countriesList() {
        return this.countries;
    };

    get statesList(): DropdownOptions[] {
        const list = this.states.map((i) => ({
            value: i.abbreviation,
            text: i.abbreviation,
            disabled: false
        }));
        return [{value: null, text: "State", disabled: true}, ...list]
    };
}

export default Regions;
