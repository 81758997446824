import router from "../../../services/router/router";

export abstract class BaseTokenManager {
    protected abstract keyNamePrefix: string;

    protected get accessTokenKey() {
        return this.keyNamePrefix + "Token";
    }

    protected get refreshTokenKey() {
        return this.keyNamePrefix + "RefreshToken";
    }

    protected get resetTokenKey() {
        return this.keyNamePrefix + "ResetToken";
    }

    protected get nextRouteKey() {
        return this.keyNamePrefix + "NextRoute";
    }

    protected storage: Storage;

    constructor() {
        this.resolveStorage();
    }

    protected resolveStorage() {
        if (sessionStorage.getItem(this.accessTokenKey)) {
            this.storage = sessionStorage;
        }
        this.storage = localStorage;
    }

    protected toSessionStorage() {
        this.storage = sessionStorage;
    }

    public toLoginAsSession(accessToken: string, refreshToken: string) {
        this.toSessionStorage();
        this.setAuthTokens(accessToken, refreshToken);
    }

    public setAuthTokens(accessToken: string, refreshToken: string) {
        this.setAccessToken(accessToken);
        this.setRefreshToken(refreshToken);
    }

    public removeAuthTokens() {
        this.removeAccessToken();
        this.removeRefreshToken();
    }

    public isUserAuthenticated(): boolean {
        return typeof this.getAccessToken() === "string";
    }

    public getAccessToken() {
        return this.storage.getItem(this.accessTokenKey);
    };

    public setAccessToken(token) {
        this.storage.setItem(this.accessTokenKey, token);
    }

    public removeAccessToken() {
        this.storage.removeItem(this.accessTokenKey);
    }

    public getRefreshToken(): string {
        return this.storage.getItem(this.refreshTokenKey);
    }

    public setRefreshToken(refreshToken) {
        this.storage.setItem(this.refreshTokenKey, refreshToken);
    };

    public removeRefreshToken() {
        this.storage.removeItem(this.refreshTokenKey);
    }

    public setResetToken(resetToken) {
        this.storage.setItem(this.resetTokenKey, resetToken);
    };

    public getResetToken() {
        return this.storage.getItem(this.resetTokenKey);
    }

    public removeResetToken() {
        this.storage.removeItem(this.resetTokenKey);
    }

    public logout() {
        this.removeAuthTokens();
        this.navigateToLoginPage();
    };

    public setNextRoute() {
        this.storage.setItem(this.nextRouteKey, JSON.stringify({
            name: router.currentRoute.name,
            params: {...router.currentRoute.params},
        }));
    };

    public getNextRoute() {
        return JSON.parse(localStorage.getItem(this.nextRouteKey));
    };

    public removeNextRoute() {
        return this.storage.removeItem(this.nextRouteKey);
    };

    protected abstract navigateToLoginPage(): void;
}
