import {Constructor} from "@/lib/types";
import {RequestFilter} from "@/lib/store/state/filters/RequestFilter";
import {LoadableFilter} from "@/lib/store/state/filters/LoadableFilter";
import {FloteApi} from "@/services/api/FloteApi";
import {FlotePortalApi} from "@/services/api/PortalApi";

export class FiltersState {
    public toParams() {
        const params = {};
        for (const key of Object.keys(this)) {
            const filter = this[key];
            if (filter instanceof RequestFilter && filter.isSet) {
                params[filter.paramName] = filter.serialize();
            }
        }

        return params;
    }

    public async loadAll(api: FloteApi, portalApi: FlotePortalApi) {
        for (const key of Object.keys(this)) {
            const filter = this[key];
            if (filter instanceof LoadableFilter) {
                await filter.loadOptions();
            }
        }
    }

    public reset() {
        for (const key of Object.keys(this)) {
            const filter = this[key];
            if (filter instanceof RequestFilter && !filter.isPredefined) {
                filter.reset();
            }
        }
    }

    public get hasSetItem() {
        for (const key of Object.keys(this)) {
            const filter = this[key];
            if (filter instanceof RequestFilter) {
                if (filter.isSet && !filter.isPredefined) {
                    return true;
                }
            }
        }
        return false;
    }
}

export type FilterStateClass = typeof FiltersState & Constructor<FiltersState>;
