import {
  SET_ALL_POSITIONS,
  SET_POSITION_BY_ID,
  SET_POSITIONS_TRANSACTION,
} from '../../config/mutationTypes';

export default {
  [SET_ALL_POSITIONS](state, data) {
    state.allPositions = data;
  },
  [SET_POSITION_BY_ID](state, data) {
    state.positionById = data;
  },
  [SET_POSITIONS_TRANSACTION](state, data) {
    state.positionsTransactions = data;
  },
};
