import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const NotificationsRouter = new Router();

@NotificationsRouter.register()
export class SingleNotificationRoute extends Route {
    getComponent() {
        return import("@/components/sponsor/sections/NotificationsSinglePage/NotificationsSinglePage.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/notifications/transactions/:transactionType/:transactionId";
    }
}

@NotificationsRouter.register()
export class DocumentSingleNotificationRoute extends Route {
    getComponent() {
        return import("@/components/sponsor/sections/NotificationsSinglePage/DocumentNotificationSinglePage.vue");

    }

    getPath(): string {
        return "vehicles/:vehicleId/notifications/documents/:documentGroupId";
    }

}
