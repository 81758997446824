<template>
    <div :class="{'absolute-loader-wrapper': isMainLoader, 'loader-mask': hasBackground}">
        <div class="loader"
             :class="{'fixed-loader': isMainLoader, 'absolute-loader': !isMainLoader, 'inverted': isInverted}">
            <div class="dot dot1"></div>
            <div class="dot dot2"></div>
            <div class="dot dot3"></div>
            <div class="dot dot4"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DotLoader',
    props: {
        isMainLoader: {
            type: Boolean,
            default: true,
        },
        isInverted: {
            type: Boolean,
            default: false,
        },
        hasBackground: {
            type: Boolean,
            default: false,
        },
    }
};
</script>

<style scoped lang="scss">
@import "../../../../assets/sponsor/styles/components/Loader";
</style>
