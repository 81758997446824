import {ValueFormatter} from "./ValueFormatter";
import {formatDate} from "@/services/helpers/sponsor/date/formatDate";

export class DateFormatter extends ValueFormatter<Date> {
    public mask(value: Date): string {
        return value.toISOString();
    }

    public unmask(value: string): Date {
        if (value === "") {
            return null;
        }
        return new Date(value);
    }

    public format(value: Date): string {
        return formatDate(value);
    }
}
