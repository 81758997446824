export default {
  getEntitiesForOptions(state) {
    const entities = state.entities.map(item => ({
      text: item.entityName,
      value: item.id,
      disabled: false
    }));
    return [
      { text: 'Choose Entity', value: null , disabled: true},
      ...entities,
      { text: 'Add New Entity', value: 'addNew' },
    ];
  },
};
