import {ContactsApi} from "@/services/api/ContactsApi";
import {DocumentsApi, DraftDocumentsApi} from "@/services/api/DocumentsApi";
import sponsorTokenManager from "@/lib/api/auth/SponsorTokenManager";
import investorTokenManager from "@/lib/api/auth/InvestorTokenManager";
import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {VehiclesApi} from "@/services/api/VehiclesApi";
import {AssetsApi} from "@/services/api/AssetsApi";
import {UserApi} from "@/services/api/UserApi";
import {EntitiesApi} from "@/services/api/EntitiesApi";
import {BaseTokenManager} from "@/lib/api/auth/TokenManager";
import {AuthApi} from "@/services/api/AuthApi";
import {PositionTransactionsApi} from "@/services/api/PositionTransactionsApi";
import {VehicleTransactionsApi} from "@/services/api/VehicleTransactionsApi";
import {NotificationsApi} from "@/services/api/NotificationsApi";
import {DocumentGroupsApi} from "@/services/api/DocumentGroupsApi";
import {ConfigApi} from "@/services/api/ConfigApi";
import {AffiliatesApi} from "@/services/api/AffiliatesApi";
import {OfferingsApi} from "@/services/api/OfferingsApi";
import {SponsorDataApi} from "@/services/api/SponsorDataApi";
import {ReservationApi} from "@/services/api/ReservationApi";
import {OfferingInvestorsListApi} from "@/services/api/OfferingInvestorsListApi";

export abstract class FlotePortalApi extends FloteEndpointGroup {
    public readonly documents = new DocumentsApi(this);
    public readonly documentGroups = new DocumentGroupsApi(this);
    public readonly vehicles = new VehiclesApi(this);
    public readonly assets = new AssetsApi(this);
    public readonly user = new UserApi(this);
    public readonly entities = new EntitiesApi(this);
    public readonly auth = new AuthApi(this);
    public readonly positionTransactions = new PositionTransactionsApi(this);
    public readonly vehicleTransactions = new VehicleTransactionsApi(this);
    public readonly notifications = new NotificationsApi(this);
    public readonly affiliates = new AffiliatesApi(this);
    public readonly config = new ConfigApi(this);
    public readonly offerings = new OfferingsApi(this);
    public readonly offeringInvestorsList = (offeringId: string) =>  new OfferingInvestorsListApi(this, offeringId);
    public readonly reservations = new ReservationApi(this);

    public readonly isSponsor: boolean = false;
    public readonly isInvestor: boolean = false;
}

export class SponsorApi extends FlotePortalApi {
    public readonly relativeUri: string = "sponsor";
    public readonly isSponsor: boolean = true;
    public tokenManager: BaseTokenManager = sponsorTokenManager;
    public readonly draftDocuments = new DraftDocumentsApi(this);
    public readonly users = new ContactsApi(this);
    public readonly transactions = new VehicleTransactionsApi(this);
    public readonly sponsorData = new SponsorDataApi(this);
}

export class InvestorApi extends FlotePortalApi {
    public readonly relativeUri: string = "";
    public readonly isInvestor: boolean = true;
    public tokenManager: BaseTokenManager = investorTokenManager;
    public readonly transactions = new PositionTransactionsApi(this);
}
