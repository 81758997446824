




























import {Prop, Vue, Component, Watch} from "vue-property-decorator";
import {guidGenerator} from "@/services/helpers/sponsor/randomId";
import {Formatters} from "@/lib/forms/formatters/Formatters";
import {ValueFormatter} from "@/lib/formatters/ValueFormatter";

@Component({})
export default class FloteTextArea extends Vue {
    public activeErrorMessage: string = null;

    @Watch("errorMessage", {immediate: true})
    public onErrorMessageChange(newValue, oldValue) {
        this.activeErrorMessage = newValue;
    }

    @Prop({default: null})
    public inputClass!: string;

    @Prop({default: "text"})
    public inputType!: string;

    @Prop({default: null})
    public value!: string;

    @Prop({default: null})
    public placeholder!: string;

    @Prop({default: false})
    public isRequired!: boolean;

    @Prop({default: false})
    public isDisabled!: boolean;

    @Prop({default: null})
    public errorMessage!: string;

    @Prop({default: () => Formatters.Default()})
    public formatter!: ValueFormatter;

    @Prop({default: () => guidGenerator()})
    public id!: string;

    @Prop({default: false})
    public highlightedAsError!: boolean;

    public isFocused: boolean = false;

    public displayValue: string = null;

    @Watch("value", {immediate: true})
    public updateValue(value: string) {
        this.displayValue = value;
    }

    public unmask(value: string) {
        return this.formatter.unmask(value);
    }

    public onBlur(event: Event) {
        this.isFocused = false;
        this.$emit("blur", event);
    }

    public onFocus(event: Event) {
        this.isFocused = true;
        this.$emit("focus", event);
    }

    public handleInput(event: any) {
        this.activeErrorMessage = null;
        const inputValue = event.target.value;
        const targetValue = this.unmask(inputValue)
        this.$emit("input", targetValue);
    }
}
