<template>
    <div v-html="Image" :class="wrapperClass"></div>
</template>

<script>
import Cloud from "../cloudinaryHelper";

export default {
    props: {
        publicId: String,
        height: Number,
        gravity: String,
        crop: String,
        width: Number,
        quality: String,
        radius: Number,
        dpr: String,
        wrapperClass: {
            type: String,
            default: "image",
        },
    },
    data() {
        return {
            Image: "",
        };
    },
    created() {
        this.Image = Cloud.Image(
            this.publicId,
            this.height,
            this.width,
            this.gravity,
            this.crop,
            this.quality,
            this.dpr,
            this.radius
        );
    },
    watch: {
        publicId(value) {
            this.Image = Cloud.Image(
                value,
                this.height,
                this.width,
                this.gravity,
                this.crop,
                this.quality,
                this.dpr,
                this.radius
            );
        },
    },
};
</script>
