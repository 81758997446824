import {Constructor} from "@/services/helpers/sponsor/types";

export class BaseFloteEntity {
    public set(data: {}) {
        Object.assign(this, data);
    }
}

export function initEntity<T extends BaseFloteEntity>(entityClass: Constructor<T>, data: {}): T {
    const entity = new entityClass();
    entity.set(data);
    return entity;
}
