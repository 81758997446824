import Vue from "vue";
import money from "v-money";
import VueMultiselect from "vue-multiselect";
import {Datetime} from "vue-datetime";
import Tooltip from "vue-directive-tooltip";
import * as VueGoogleMaps from "vue2-google-maps";
import 'chart.js';
import 'hchs-vue-charts';
import "vue-datetime/dist/vue-datetime.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";
import './assets/sponsor/styles/vendors/slick.scss';
import {NavigationPlugin} from "@/core/navigation/NavigationPlugin";
import "./services/helpers/sponsor/globalComponents";
import VueClipboard from 'vue-clipboard2'
import clickOutside from "./directives/clickOutside";
import {ClickOutside} from "./directives/ClickOutsideNew";
import Vuelidate from "vuelidate";
import {config} from "@/lib/config";
import {ApiPlugin} from "@/services/api/ApiPlugin";
import {ErrorHandler} from "@/core/errors/ErrorHandler";
import {ModalPlugin} from "@/core/modals/ModalPlugin";
import VueQuillEditor from 'vue-quill-editor';

Vue.directive('click-outside', clickOutside);
Vue.directive('click-outside-new', ClickOutside);

Vue.directive('title', {
    inserted: (el, binding) => document.title = binding.value,
    update: (el, binding) => document.title = binding.value
});

import 'quill/dist/quill.snow.css'
Vue.use(VueQuillEditor, {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{'header': 1}, {'header': 2}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'direction': 'rtl'}],
            [{'size': ['small', false, 'large', 'huge']}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'color': []}, {'background': []}],
            [{'align': []}],
            ['clean'],
        ]
    },
})

Vue.use(new NavigationPlugin());
Vue.use(new ApiPlugin());
Vue.use(new ModalPlugin());
Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(Tooltip);
// @ts-ignore
Vue.use(Datetime);
Vue.use(VueGoogleMaps, {
    load: {
        key: config.GOOGLE_MAPS_KEY,
        libraries: "places", // necessary for places input
    },
});
// @ts-ignore
Vue.use(window.VueCharts);
Vue.use(Vuelidate);
Vue.use(money);
Vue.component("multiselect", VueMultiselect);

Vue.config.errorHandler = ErrorHandler;
