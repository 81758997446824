<template>
    <div class="modal-mask" transition="modal">
        <div class="modal-wrapper" @click.self="handleModalClose">
            <VuePerfectScrollbar class="modal-container" :settings="settings">
                <img v-if="showCloseButton"
                     src="../../../../assets/images/close_cross.svg"
                     alt="[X]"
                     class="close-modal-button"
                     @click="handleModalClose"/>
                <slot></slot>
            </VuePerfectScrollbar>
        </div>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
    name: "modal",
    props: {
        handleModalClose: {
            type: Function,
            default: () => {
            },
        },
        show: {
            type: Boolean,
            required: false,
            twoWay: true,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        VuePerfectScrollbar,
    },
    data() {
        return {
            settings: {
                maxScrollbarLength: 60
            },
        }
    },
    created() {
        const onEscape = (e) => {
            if (e.keyCode === 27) {
                this.handleModalClose();
            }
        }
        document.addEventListener('keydown', onEscape)
        this.$once('hook:destroyed', () => {
            document.removeEventListener('keydown', onEscape)
        })
    },

};
</script>

<style lang="scss" scoped>
.close-modal-button {
    cursor: pointer;
    position: absolute;
    top: 34px;
    right: 30px;
}
</style>
