import "./registerComponentHooks";
import Vue from "vue";
import App from "./App.vue";
import router from "./services/router/router";
import store from "./services/store/";

import "./assets/investor/styles/style.scss";
import "./assets/sponsor/styles/style.scss";

import "./projectBootstrap";
import "./filters";

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== "production";
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app");
