import {Module, VuexModule, Action, Mutation} from "vuex-module-decorators";
import O_o from "@/services/helpers/investor/promiseHandler";
import RequestManager from "@/services/helpers/investor/managers/requestManager";
import {POST} from "@/services/store/investor/config/actionMethods";
import {CLOUDINARY_URL} from "@/services/store/sponsor/storeConfigs/actionUrls";
import {
    REMOVE_IMAGE_IS_PROCESSES,
    SET_IMAGE_IS_PROCESSES
} from "@/services/store/sponsor/storeConfigs/mutationTypes";

@Module({namespaced: true})
class ImageUploadModule extends VuexModule {
    public imageBeingProcessed = [];
    public imageUploadFailed: boolean = false;

    @Action({rawError: true})
    public async uploadImageToCloudinary(data) {
        this.context.commit(SET_IMAGE_IS_PROCESSES, data.imageProcesses);
        const [error, response] = await O_o(RequestManager.sendMultipartFormDataWithoutCredentials(
            POST(CLOUDINARY_URL), data.data,));

        if (error) {
            this.context.commit(REMOVE_IMAGE_IS_PROCESSES, data.imageProcesses);
            this.context.commit('setImageUploadFailed', true);
            throw error;
        }

        if (data.type === "MAIN") {
            await this.context.dispatch(`AssetStore/addMainPhoto`, response.public_id, {root: true})
            this.context.commit(REMOVE_IMAGE_IS_PROCESSES, data.imageProcesses);
        } else if (data.type === "GALLERY") {
            await this.context.dispatch(`AssetStore/addAssetGalleryPhoto`, response.public_id, {root: true});
            this.context.commit(REMOVE_IMAGE_IS_PROCESSES, data.imageProcesses);
        } else {
            const uploadedImage = {
                profilePhoto: response.public_id,
            };
            await this.context.dispatch('changeCurrentUserInfo', {
                userChangedData: uploadedImage,
                imageProcesses: data.imageProcesses,
            }, {root: true})
            this.context.commit(REMOVE_IMAGE_IS_PROCESSES, data.imageProcesses);
        }
    };

    @Mutation
    public [SET_IMAGE_IS_PROCESSES](data) {
        this.imageBeingProcessed.push(data);
    };

    @Mutation
    public [REMOVE_IMAGE_IS_PROCESSES](data) {
        this.imageBeingProcessed = this.imageBeingProcessed.map(item => item !== data);
    };

    @Mutation
    public setImageUploadFailed(state, data) {
        this.imageUploadFailed = data;
    };

    get isProcessing() {
        return this.imageBeingProcessed;
    };
}

export default ImageUploadModule;
