import {MetadataManager} from "@/lib/metadata/MetadataManager";
import {BaseModule, ModuleClass} from "../BaseModule";
import {ModuleMetadata} from "./ModuleMetadata";

const METADATA_KEY = "FLOTE_MODULE_METADATA";

export class ModuleMetadataManager extends MetadataManager<ModuleMetadata> {
    public baseModel = BaseModule;
    public metadataKey: string = METADATA_KEY;

    public getNewMetadata(model: ModuleClass, baseMeta: ModuleMetadata): ModuleMetadata {
        return new ModuleMetadata(model, baseMeta);
    }
}

export const Metadata = new ModuleMetadataManager();
