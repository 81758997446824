export function GET(url, loading) {
    return {
        url,
        method: 'GET',
        ...(loading ? {loading} : {}),
    };
}

export function POST(url, loading) {
    return {
        url,
        method: 'POST',
        ...(loading ? {loading} : {}),
    };
}

export function PATCH(url, loading) {
    return {
        url,
        method: 'PATCH',
        ...(loading ? {loading} : {}),
    };
}

export function DELETE(url, loading) {
    return {
        url,
        method: 'DELETE',
        ...(loading ? {loading} : {}),
    };
}

export function PUT(url, loading) {
    return {
        url,
        method: 'PUT',
        ...(loading ? {loading} : {}),
    };
}
