import O_o from "@/services/helpers/investor/promiseHandler";
import RequestManager from "@/services/helpers/investor/managers/requestManager";
import {GET} from "@/services/store/investor/config/actionMethods";
import {VEHICLES} from "@/services/store/investor/config/actionUrls";
import {SET_SUGGESTED_VEHICLES_LIST} from "@/services/store/investor/config/mutationTypes";
import {
    REMOVE_IS_REQUEST_PROCESSING,
    SET_IS_REQUEST_PROCESSING,
} from "./sponsor/storeConfigs/mutationTypes";

export default {
    setLoaderForRequests({commit}) {
        commit(SET_IS_REQUEST_PROCESSING);
    },
    removeLoaderForRequests({commit}) {
        commit(REMOVE_IS_REQUEST_PROCESSING);
    },

    async getSuggestedVehicles({commit}, data) {
        const [error, response] = await O_o(RequestManager.send(GET(VEHICLES)));

        if (error) {
            throw error;
        }

        commit(SET_SUGGESTED_VEHICLES_LIST, response.items);
    },
};
