import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {AssetCreationRoute, AssetEditRoute, AssetSingleRoute, AssetsRoute} from "@/services/router/sponsor/assets";
import router from "@/services/router/router";
import {InvestmentAssetRoute, ManageInvestmentRoute} from "@/services/router/investor/investment";

export class AssetsNavigator extends BasicNavigator {
    public list() {
        return this.route(AssetsRoute);
    }

    public single(id: string) {
        const route = router.currentRoute;
        if (route.name === ManageInvestmentRoute.Name) {
            return this.route(InvestmentAssetRoute, {positionId: route.params.positionId, id: id});
        }
        return this.route(AssetSingleRoute, {id: id});
    }

    public edit(id: string) {
        return this.route(AssetEditRoute, {id: id});
    }

    public create() {
        return this.route(AssetCreationRoute);
    }
}
