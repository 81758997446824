import {DropdownOptions} from "@/mixins/sponsor/TypedGetType";
import {PositionTransactionType} from "@/core/enums/TransactionType";

export const assetDocumentTypes: DropdownOptions[] = [
    {value: 'PropertyConditionReport', text: 'Property Condition Report', disabled: false},
    {value: 'RentRoll', text: 'Rent Roll', disabled: false},
    {value: 'TitlePolicy', text: 'Title Policy', disabled: false},
    {value: 'SeismicReport', text: 'Seismic Report', disabled: false},
    {value: 'FinancialStatement', text: 'Financial Statement', disabled: false},
    {value: 'InsurancePolicy', text: 'Insurance Policy', disabled: false},
    {value: 'Appraisal', text: 'Appraisal', disabled: false},
    {value: 'EnvironmentalReport', text: 'Environmental Report', disabled: false},
    {value: 'Deed', text: 'Deed', disabled: false},
];

export const investmentDocumentTypes: DropdownOptions[] = [
    {value: 'BrokerOM', text: 'Broker OM', disabled: false},
    {value: 'Proforma', text: 'Proforma', disabled: false},
    {value: 'InvestmentMemo', text: 'Investment Memo', disabled: false},
    {value: 'PrivatePlacementMemo', text: 'Private Placement Memo', disabled: false},
    {value: 'TrackRecord', text: 'Track Record', disabled: false},
    {value: 'WireInstructions', text: 'Wire Instructions', disabled: false},
    {value: 'ClosingBook', text: 'Closing Book', disabled: false},
];

export const investorDocumentTypes: DropdownOptions[] = [
    {value: 'AccreditationLetter', text: 'Accreditation Letter', disabled: false},
    {value: 'W9', text: 'W9', disabled: false},
];

export const commitmentDocumentTypes: DropdownOptions[] = [
    {value: 'AmendmentToSA', text: 'Amendment to SA', disabled: false},
    {value: 'AmendmentToOA', text: 'Amendment to OA', disabled: false},
    {value: 'SubscriptionAgreement', text: 'Subscription Agreement', disabled: false},
    {value: 'AssignmentSaleAgreement', text: 'Assignment & Sale Agreement', disabled: false},
    {value: 'OperatingAgreement', text: 'Operating Agreement', disabled: false},
];

export const capitalCallDocumentTypes: DropdownOptions[] = [
    {value: 'CapitalCallNotice', text: 'Capital Call Notice', disabled: false},
];

export const contributionDocumentTypes: DropdownOptions[] = [
    {value: 'ContributionReceipt', text: 'Contribution Receipt', disabled: false},
];

export const distributionDocumentTypes: DropdownOptions[] = [
    {value: 'DistributionSummary', text: 'Distribution Summary', disabled: false},
];

export const legalDocumentTypes: DropdownOptions[] = [
    ...commitmentDocumentTypes,
    {value: 'OrganizationalChart', text: 'Organizational Chart', disabled: false},
    {value: 'SideLetter', text:'Side Letter', disabled: false},
];

export const reportsDocumentTypes: DropdownOptions[] = [
    {value: 'AnnualPerformanceReport', text: 'Annual Performance Report', disabled: false},
    {value: 'QuarterlyPerformanceReport', text: 'Quarterly Performance Report', disabled: false},
    {value: 'MonthlyPerformanceReport', text: 'Monthly Performance Report', disabled: false},
    {value: 'CapitalStatement', text: 'Capital Statement', disabled:false},
    {value: 'ProjectUpdate', text: 'Project Update', disabled: false},
];

export const reportsAndNoticesDocumentTypes: DropdownOptions[] = [
    ...reportsDocumentTypes,
    ...distributionDocumentTypes,
    ...capitalCallDocumentTypes,
];

export const transactionalDocumentTypes: DropdownOptions[] = [
    ...capitalCallDocumentTypes,
    ...contributionDocumentTypes,
    ...distributionDocumentTypes,
];

export const taxDocumentTypes: DropdownOptions[] = [
    {value: 'K1', text: 'K-1', disabled: false},
    {value: '_1099', text: '1099', disabled: false},
];

export const nonTransactionalDocumentTypesOptions: DropdownOptions[] = [
    ...assetDocumentTypes,
    ...investmentDocumentTypes,
    ...investorDocumentTypes,
    ...legalDocumentTypes,
    ...reportsDocumentTypes,
    ...taxDocumentTypes,
];

export const nonTransactionalDocumentTypes: DropdownOptions[] = [
    ...assetDocumentTypes,
    ...investmentDocumentTypes,
    ...investorDocumentTypes,
    ...legalDocumentTypes,
    ...reportsDocumentTypes,
    ...taxDocumentTypes,
];

export const allDocumentOptions: DropdownOptions[] = [
    ...nonTransactionalDocumentTypesOptions,
    ...transactionalDocumentTypes,
]
export const documentTypesPlaceholder = [{value: null, text: "Document Type", disabled: true}];
// todo: refactor
export const allDocumentTypes: DropdownOptions[] = [
    ...documentTypesPlaceholder,
    ...nonTransactionalDocumentTypes,
    ...transactionalDocumentTypes,
]

export const getDocumentTypeOptions = (transactionType) => {
    if (transactionType === PositionTransactionType.CapitalCall.name) {
        return [...documentTypesPlaceholder, ...capitalCallDocumentTypes];
    }
    if (transactionType === PositionTransactionType.Contribution.name) {
        return [...documentTypesPlaceholder, ...contributionDocumentTypes];
    }
    if (transactionType === PositionTransactionType.Distribution.name) {
        return [...documentTypesPlaceholder, ...distributionDocumentTypes];
    }
    if (transactionType === PositionTransactionType.Commitment.name) {
        return [...documentTypesPlaceholder, ...commitmentDocumentTypes];
    }
    return [...documentTypesPlaceholder, ...nonTransactionalDocumentTypes];
};
