import {FiltersState} from "@/lib/store/state/filters/FiltersState";
import {allDocumentOptions} from "@/config/sponsor/documentTypes";
import {DateFilter} from "@/lib/store/state/filters/DateFilter";
import {DocumentTypeFilter} from "@/services/store/lib/filters/DocumentTypeFilter";
import {VehicleFilter} from "@/services/store/lib/filters/VehicleFilter";
import {FlotePortalApi} from "@/services/api/PortalApi";
import {SearchFilter} from "@/lib/store/state/filters/SearchFilter";

export class DocumentsFilters extends FiltersState {
    constructor(api: FlotePortalApi) {
        super();
        this.vehicle = new VehicleFilter("f_vehicle_id", api.vehicles);
    }

    public vehicle: VehicleFilter;
    public documentType = new DocumentTypeFilter("f_type", allDocumentOptions);
    public fromDate = new DateFilter("f_range_from");
    public toDate = new DateFilter("f_range_to");
    public search = new SearchFilter();
}
