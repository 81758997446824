import {Router} from "@/lib/router/Router";
import {Route} from "@/lib/router/Route";
import {InvestmentsRoute, InvestmentRouter, InvestorOfferingsRoute} from "./investment";
import {AffiliatesInvestorRouter} from "@/services/router/investor/affiliates";
import {EntitiesInvestorRouter} from "@/services/router/investor/entities";

export const InvestorRouter = new Router();

@InvestorRouter.register()
export class InvestorHomeRoute extends Route {
    getComponent() {
        return import("@/components/investor/Home.vue");
    }

    getPath(): string {
        return "/";
    }

    getConfig(): {} {
        return {
            redirect: {name: InvestmentsRoute.Name}
        };
    }
}

InvestorHomeRoute.addRouter(InvestmentRouter);

@InvestorRouter.register()
export class SignInRoute extends Route {
    getComponent() {
        return import("@/views/investor/userViews/Auth/SignIn.vue");
    }

    getPath(): string {
        return "/signin";
    }
}

@InvestorRouter.register()
export class SignInAfterVerificationRoute extends Route {
    getComponent() {
        return import("@/views/investor/userViews/Auth/SignIn.vue");
    }

    getPath(): string {
        return "/signin/:verified";
    }
}

@InvestorRouter.register()
export class SignUpRoute extends Route {
    getComponent() {
        return import("@/views/investor/userViews/Auth/SignUp.vue");
    }

    getPath(): string {
        return "/signup";
    }
}

@InvestorRouter.register()
export class EmailVerificationRoute extends Route {
    getComponent() {
        return import("@/components/investor/Common/EmailVerification.vue");
    }

    getPath(): string {
        return "/signup/confirm";
    }
}

@InvestorRouter.register()
export class ResetPasswordRoute extends Route {
    getComponent() {
        return import("@/views/investor/userViews/Auth/ResetPassword.vue");
    }

    getPath(): string {
        return "/reset-password";
    }
}

@InvestorRouter.register()
export class ChangePasswordRoute extends Route {
    getComponent() {
        return import("@/views/investor/userViews/Auth/ChangePassword.vue");
    }

    getPath(): string {
        return "/change-password";
    }
}

@InvestorRouter.register()
export class ActivateAccountRoute extends Route {
    getComponent() {
        return import("@/views/investor/userViews/Auth/ActivateAccount.vue");
    }

    getPath(): string {
        return "/activate-account";
    }
}

@InvestorRouter.register()
export class ConfirmSetUpRoute extends Route {
    getComponent() {
        return import("@/components/investor/Common/AuthResetPassword.vue");
    }

    getPath(): string {
        return "/auth/setup";
    }
}

@InvestorRouter.register()
export class ConfirmResetPasswordRoute extends Route {
    getComponent() {
        return import("@/components/investor/Common/AuthResetPassword.vue");
    }

    getPath(): string {
        return "/auth/reset-password";
    }
}

@InvestorRouter.register()
export class LoginAsRoute extends Route {
    getComponent() {
        return import("@/components/investor/Common/LoginAs.vue");
    }

    getPath(): string {
        return "/login-as";
    }
}

@InvestorRouter.register()
export class TermsRoute extends Route {
    getComponent() {
        return import("@/views/investor/Terms-Privacy/Terms.vue");
    }

    getPath(): string {
        return "/terms";
    }
}

@InvestorRouter.register()
export class PrivacyRoute extends Route {
    getComponent() {
        return import("@/views/investor/Terms-Privacy/Privacy.vue");
    }

    getPath(): string {
        return "/privacy";
    }
}

export const DefaultRoute = InvestmentsRoute;

@InvestorRouter.register()
export class Investor404Route extends Route {
    getComponent() {
        return import("@/views/investor/404Page.vue");
    }

    getPath(): string {
        return "/*";
    }
}

InvestorHomeRoute.addRouter(AffiliatesInvestorRouter);
InvestorHomeRoute.addRouter(EntitiesInvestorRouter);
