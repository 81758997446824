import {CountryCode} from "libphonenumber-js";

class Config {
    public CLOUDINARY_UPLOAD_PRESET: string = "flote-front-general";
    public CLOUDINARY_CLOUD_NAME: string = "greenscreen";
    public CLOUDINARY_API_BASE_URL: string = `https://api.cloudinary.com/v1_1/${this.CLOUDINARY_CLOUD_NAME}`;

    public API_BASE_URL: string = process.env.VUE_APP_API_URL;
    public OFFERING_ENABLED: boolean = process.env.VUE_APP_ENABLE_OFFERING === "true";
    public CREATE_ASSET_ENABLED: boolean = process.env.VUE_APP_ENABLE_CREATE_ASSET === "true";
    public GOOGLE_MAPS_KEY: string = process.env.VUE_APP_GOOGLE_MAPS_KEY;

    public NUMBER_FORMATTING_DECIMAL_PLACES: number = 2;
    public LONG_NUMBER_FORMATTING_DECIMAL_PLACES: number = 20;
    public NUMBER_COMPARING_ACCURACY_DECIMAL_PLACES: number = 2;
    public DEFAULT_COUNTRY: CountryCode = "US";
    public DEFAULT_TIMEZONE: string = "America/Los_Angeles";

    public MODAL_ERROR_MESSAGES = {
        "activate_account_token_expired": {
            title: "Activation Link Expired",
            body: "This activation link has expired. Please contact us {{ contact_link }} to receive a new one."
        },
        "password_reset_token_expired": {
            title: "Password Reset Link Expired",
            body: "This password reset link has expired. Please contact us {{ contact_link }} to receive a new one."
        }
    }
}

export const config = new Config();
