import {BaseModule} from "@/lib/store/modules/BaseModule";
import {SubModule} from "@/lib/store/modules/SubModule";
import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {AssetRepositoryModule} from "@/services/store/modules/assets/AssetRepositoryModule";

export class AssetModule extends BaseModule {
    @SubModule.Register()
    public repository: AssetRepositoryModule;

    public get api(): FloteEndpointGroup {
        return this.portalApi.assets;
    }
}
