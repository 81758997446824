import actions from './entityActions';
import getters from './entityGetters';
import state from './entityState';
import mutations from './entityMutations';

const entity = {
  actions,
  getters,
  mutations,
  state,
};

export default entity;
