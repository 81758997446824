import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {LoginRoute, SponsorChangePasswordRoute, SponsorResetPasswordRoute} from "@/services/router/sponsor";
import {AffiliatesSponsorNavigator} from "@/core/navigation/navigators/AffiliatesNavigator";
import {EntitiesSponsorNavigator} from "@/core/navigation/navigators/EntitiesNavigator";
import {ContactsNavigator} from "@/core/navigation/navigators/ContactsNavigator";
import {VehiclesNavigator} from "@/core/navigation/navigators/VehiclesNavigator";
import {NotificationsNavigator} from "@/core/navigation/navigators/NotificationsNavigator";
import {AssetsNavigator} from "@/core/navigation/navigators/AssetsNavigator";
import {TransactionsNavigator} from "@/core/navigation/navigators/TransactionsNavigator";
import {ProjectNavigator} from "@/core/navigation/navigators/ProjectNavigator";
import {OfferingsSponsorNavigator} from "@/core/navigation/navigators/OfferingsNavigator";

export class SponsorNavigator extends BasicNavigator {
    public signIn() {
        return this.route(LoginRoute);
    }

    public resetPassword() {
        return this.route(SponsorResetPasswordRoute);
    }

    public changePassword() {
        return this.route(SponsorChangePasswordRoute);
    }

    public readonly affiliates = new AffiliatesSponsorNavigator();
    public readonly entities =  new EntitiesSponsorNavigator();
    public readonly contacts = new ContactsNavigator();
    public readonly vehicles = new VehiclesNavigator();
    public readonly notifications = new NotificationsNavigator();
    public readonly assets = new AssetsNavigator();
    public readonly transactions = new TransactionsNavigator();
    public readonly projects = new ProjectNavigator();
    public readonly offerings = new OfferingsSponsorNavigator();
}
