import O_o from "../../../../helpers/sponsor/promiseHandler";
import http from "../../../../helpers/sponsor/managers/requestManager";
import {
  INVESTOR_INVITES,
  OFFERINGS,
  OFFERING_INVITES,
} from "../../storeConfigs/actionUrls";
import {
  HIDE_MODALS,
  SET_ERROR_HANDLER,
  SET_OFFERINGS,
  SET_SINGLE_OFFER,
  SET_INVESTORS_LIST,
  SET_PENDING_POSITIONS_LIST,
  SET_MODALS,
} from "../../storeConfigs/mutationTypes";
import router from "../../../../router/router";
import constants from "../../../../../config/sponsor/constants";
import {GET, PATCH, POST} from '../../../../helpers/sponsor/actionMethods';
import { ViewOfferingRoute } from '../../../../router/sponsor/offerings';

export default {
  async getOffers({ commit }, data) {
    const [error, response] = await O_o(
      http.send( GET(OFFERINGS), data));
    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    commit(SET_OFFERINGS, response.items);
  },
  async createOffer({ commit }, data) {
    const [error, response] = await O_o(
      http.send(POST(OFFERINGS),
        data
      )
    );
    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    router.push({
      name: "OfferingCreation",
      params: {
        offeringId: response.id,
      },
    });
  },
  async editOffering({ commit, dispatch }, data) {
    const [error, response] = await O_o(
      http.send(PATCH(`${OFFERINGS}/${data.securityId}`),
        data.updatedInfo
      )
    );
    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    if (!data.changeState) {
      router.push({
        name: ViewOfferingRoute.Name,
        params: {
          offeringId: response.id,
        },
      });
    } else {
      dispatch("getSingleOffer", data.securityId);
    }
  },
  async getSingleOffer({ commit }, data) {
    const [error, response] = await O_o(http.send(GET(`${OFFERINGS}/${data}/aggregated`, constants.isLoadingStates.getSingleOffer)));
    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    commit(SET_SINGLE_OFFER, response);
    return response;
  },

  async getInvestorsList({ commit }, data) {
    const [error, response] = await O_o(
      http.send(GET(`${INVESTOR_INVITES}/${data}`)));
    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    commit(SET_INVESTORS_LIST, response);
  },
  async getPendingPositions({ commit }, data) {
    const securityId =
      data && data.securityId && data.securityId.length
        ? `?f_security_id=${data.securityId}`
        : "";
    const [error, response] = await O_o(
      http.send(GET(`${OFFERING_INVITES}${securityId}&f_is_pending=true`)));
    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    commit(SET_PENDING_POSITIONS_LIST, response.items);
  },
  async sendInvites({ commit, dispatch }, data) {
    const [error, response] = await O_o(
      http.send(POST(`${OFFERINGS}/${data.securityID}/send-invites`),
        data.invites));

    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    commit(SET_MODALS, constants.modals.sentInvitationModal);
    dispatch("getInvestorsList", data.securityID);
  },
  async getInviteLink({commit}, data) {
  const [error, response] = await O_o(http.send(POST(`${OFFERINGS}/${data.securityID}/user-invite/${data.userId}/link`)));
  if (error) {
  commit(SET_ERROR_HANDLER, error);
  throw error;
  }
  return response;
  },
  async changeInvestorsInfo({ commit, dispatch }, data) {
    const [error, response] = await O_o(http.send(PATCH(`${OFFERINGS}/${data.securityID}/user-invite/${data.userId}`), data.updatedData));

    if (error) {
      throw error;
    }

    commit(HIDE_MODALS, constants.modals.editMinMaxAmount);
    dispatch("getInvestorsList", data.securityID);
  },
  async editPendingPositions({ commit, dispatch }, data) {
    const [error, response] = await O_o(http.send(POST(`${OFFERING_INVITES}/batch-update`), data.data));

    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    commit(HIDE_MODALS, constants.modals.commitmentAmountApproval);
    dispatch("getPendingPositions", { securityId: data.securityId });
  },
};
