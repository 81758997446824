import {decimal, Decimal, NumericValue, zero} from "./Decimal";
import {config} from "@/lib/config";

export function isValidNumber(a: Decimal) {
    return Decimal.isBigNumber(a) && a.isFinite();
}

export function canBeDenominator(value: Decimal): boolean {
    return (isValidNumber(value) && !value.isZero());
}

export function percent(portion: Decimal): Decimal {
    return portion.multipliedBy(100);
}

export function ratio(a: NumericValue, b: NumericValue): Decimal {
    return decimal(a).dividedBy(b);
}

export function safeRatio(a: NumericValue, b: NumericValue, defaultValue?: Decimal): Decimal {
    a = decimal(a);
    b = decimal(b);
    if (canBeDenominator(b)) {
        return a.dividedBy(b);
    }

    return defaultValue || zero();
}

export function sum(...decimals: NumericValue[]): Decimal {
    return Decimal.sum(...decimals);
}

export function accumulate<T>(values: T[], mapper: (v: T) => NumericValue): Decimal {
    return sum(...values.map(mapper), zero());
}

export function max(...decimals: NumericValue[]): Decimal {
    return Decimal.maximum(...decimals);
}

export function min(...decimals: NumericValue[]): Decimal {
    return Decimal.minimum(...decimals);
}

export function areEqualWithinAccuracy(a: NumericValue, b: NumericValue) {
    return decimal(a).decimalPlaces(config.NUMBER_COMPARING_ACCURACY_DECIMAL_PLACES)
        .isEqualTo(decimal(b).decimalPlaces(config.NUMBER_COMPARING_ACCURACY_DECIMAL_PLACES));
}

export function formatDecimal(a: Decimal) {
    return a.toString();
}

export function isValidMoney(a: Decimal) {
    return isValidNumber(a);
}

export function ratioAsPercent(a: NumericValue, b: NumericValue) {
    return percent(ratio(a, b));
}

export function normalizeNumericValue(a: NumericValue) {
    return decimal(a).toString();
}

export const minus = (a, b) => decimal(a).minus(decimal(b));

export const multiply = (a, b) => decimal(a).multipliedBy(decimal(b));

/**
 * @deprecated use ratio or safeRatio. Use safeRatio only where it is needed.
 */
export const divide = (a, b) => {
    let result = decimal(a).dividedBy(decimal(b)).toNumber();
    return isNaN(result) ? decimal(0) : result;
};

export const getNumberByPortion = (total, portion) => multiply(total, portion);

export const getPercentByNumbers = (mainAmount, x) => {
    let result = safeRatio(multiply(x, 100), mainAmount).toNumber();
    return isNaN(result) ? decimal(0) : result;
};
