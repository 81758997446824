import {Component, Vue} from 'vue-property-decorator';
import {gmapApi} from "vue2-google-maps";

@Component
class GmapApiCaller extends Vue {
    public callGmapApi() {
        return gmapApi();
    };

    get google() {
        return this.callGmapApi();
    };
}

export default GmapApiCaller;
