import {BaseModule} from "@/lib/store/modules/BaseModule";
import {SubModule} from "@/lib/store/modules/SubModule";
import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {VehicleTransactionsListModule} from "@/services/store/modules/vehicles/VehicleTransactionsListModule";

export class VehiclesModule extends BaseModule {
    @SubModule.Register()
    public transactions: VehicleTransactionsListModule;

    public get api(): FloteEndpointGroup {
        return this.portalApi.vehicles;
    }
}
