import {FormField} from "@/lib/forms/fields/FormField";
import {EmptyValidator} from "@/lib/forms/validators/EmptyValidator";
import {DateFormatter} from "@/lib/formatters/DateFormatter";

export class DateField extends FormField<Date> {
    public validator = new EmptyValidator<Date>();
    public formatter = new DateFormatter();

    constructor(name: string, value: Date = null) {
        super(name, value);
    }

    public serialize() {
        return this.value?.toISOString();
    }
}
