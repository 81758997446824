export const errorMessageOptions = {
    password: 'Password',
    confirmPassword: 'Confirm Password',
    email: 'Email',
    passwords: 'Passwords',
    required: 'required',
    match: 'match',
    notValid: 'notValid',
    minLength: 'minLength',
    maxLength: 'maxLength',
};

export const validationErrorMessages = {
    required: 'Required field.',
    requiredNdaText: "Please provide NDA content.",
    invalidEmail: 'Please provide a valid email address.',
    duplicateEmail: 'Email has been entered twice.',
    invalidPhone: 'Phone # not recognized.',
    invalidBankAccountNumber: 'Maximum 17 characters.',
    invalidBankRoutingNumber: 'Please enter 9 digits.',
    invalidZipCode: 'Please enter 5 digits.',
};
