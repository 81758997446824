





































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DatePickerLocale, defaultDatePickerLocale} from "@/config/locale/DatePickerLocale";
import {now} from "@/services/helpers/utils";
import {CalendarDateValidator} from "@/components/ui/inputs/date/validator/CalendarDateValidator";
import {DefaultCalendarDateValidator} from "@/components/ui/inputs/date/validator/DefaultCalendarDateValidator";

@Component({
    filters: {
        dd: val => `0${val}`.slice(-2),
    },
})
export default class DatepickerCalendar extends Vue {
    @Prop({default: () => defaultDatePickerLocale})
    public local: DatePickerLocale;

    @Prop({default: "YYYY-MM-DD"})
    public format: string;

    @Prop({default: () => new DefaultCalendarDateValidator()})
    public dateValidator: CalendarDateValidator;

    @Prop({default: null})
    public value: Date;

    public pre: string = "calendar";
    public m: string = "D";
    public showYears: boolean = false;
    public showMonths: boolean = false;
    public showHours: boolean = false;
    public showMinutes: boolean = false;
    public showSeconds: boolean = false;

    public year: number = 0;
    public month: number = 0;
    public day: number = 0;
    public hour: number = 0;
    public minute: number = 0;
    public second: number = 0;

    public getValueAsObject() {
        let time = this.value || now();
        return {
            year: time.getFullYear(),
            month: time.getMonth(),
            day: time.getDate(),
            hour: time.getHours(),
            minute: time.getMinutes(),
            second: time.getSeconds(),
        };
    }

    @Watch("value", {immediate: true})
    public valueUpdated(val: Date) {
        const time = this.getValueAsObject();
        this.year = time.year;
        this.month = time.month;
        this.day = time.day;
        this.hour = time.hour;
        this.minute = time.minute;
        this.second = time.second;
    }

    public toFormat(time: Date, format?: string) {
        time = time || now();
        const year = time.getFullYear();
        const month = time.getMonth();
        const day = time.getDate();
        const hours24 = time.getHours();
        const hours = hours24 % 12 === 0 ? 12 : hours24 % 12;
        const minutes = time.getMinutes();
        const seconds = time.getSeconds();
        const milliseconds = time.getMilliseconds();
        const dd = t => `0${t}`.slice(-2);
        const map = {
            YYYY: year,
            MM: dd(month + 1),
            MMM: this.local.months[month],
            MMMM: this.local.monthsHead[month],
            M: month + 1,
            DD: dd(day),
            D: day,
            HH: dd(hours24),
            H: hours24,
            hh: dd(hours),
            h: hours,
            mm: dd(minutes),
            m: minutes,
            ss: dd(seconds),
            s: seconds,
            S: milliseconds,
        };
        return (format || this.format).replace(
            /Y+|M+|D+|H+|h+|m+|s+|S+/g,
            str => map[str]
        );
    }

    public get yearStart() {
        return this.year;
    }

    public get yearEnd() {
        return this.yearStart + 10;
    }

    public get years() {
        const arr = [];
        let start = this.yearStart - 1;
        while (arr.length < 12) {
            arr.push(start++);
        }
        return arr;
    }

    public get days() {
        const days = [];
        const year = this.year;
        const month = this.month;
        const time = new Date(year, month, 1);
        const dow = this.local.dow || 7;
        time.setDate(0); // switch to the last day of last month
        let lastDay = time.getDate();
        const week = time.getDay() || 7;
        let count = dow <= week ? week - dow + 1 : week + (7 - dow + 1);
        while (count > 0) {
            days.push({
                i: lastDay - count + 1,
                y: month > 0 ? year : year - 1,
                m: month > 0 ? month - 1 : 11,
                p: true,
            });
            count--;
        }
        time.setMonth(time.getMonth() + 2, 0); // switch to the last day of the current month
        lastDay = time.getDate();
        let i = 1;
        for (i = 1; i <= lastDay; i++) {
            days.push({
                i,
                y: year,
                m: month,
            });
        }
        for (i = 1; days.length < 42; i++) {
            days.push({
                i,
                y: month < 11 ? year : year + 1,
                m: month < 11 ? month + 1 : 0,
                n: true,
            });
        }
        return days;
    }

    public mounted() {
        const is = c => this.format.indexOf(c) !== -1;
        if (is("s") && is("m") && (is("h") || is("H"))) {
            this.m = "H";
        } else if (is("D")) {
            this.m = "D";
        } else if (is("M")) {
            this.m = "M";
            this.showMonths = true;
        } else if (is("Y")) {
            this.m = "Y";
            this.showYears = true;
        }
    }

    public status(year, month, day, hour, minute, second, format) {
        const maxDay = new Date(year, month + 1, 0).getDate();
        const time = new Date(
            year,
            month,
            day > maxDay ? maxDay : day,
            hour,
            minute,
            second
        );
        const classObj = {};
        let flag = false;
        if (format === "YYYY") {
            flag = year === this.year;
        } else if (format === "YYYYMM") {
            flag = month === this.month;
        } else {
            flag = this.toFormat(this.value, format) === this.toFormat(time, format);
        }
        classObj[`${this.pre}-date`] = true;
        classObj[`${this.pre}-date-disabled`] = !this.dateValidator.isValid(time);
        classObj[`${this.pre}-date-on`] = false;
        classObj[`${this.pre}-date-selected`] = flag;
        return classObj;
    }

    public nextMonth() {
        if (this.month < 11) {
            this.month++;
        } else {
            this.month = 0;
            this.year++;
        }
    }

    public previousMonth() {
        if (this.month > 0) {
            this.month--;
        } else {
            this.month = 11;
            this.year--;
        }
    }

    public is(e) {
        return e.target.className.indexOf(`${this.pre}-date-disabled`) === -1;
    }

    public ok(info) {
        let year = null;
        let month = null;
        let day = null;
        info && info.n && this.nextMonth();
        info && info.p && this.previousMonth();
        if (info === "h") {
            const time = this.getValueAsObject();
            year = time.year;
            month = time.month;
        } else if (info === "m" || info === "y") {
            day = 1;
        }
        const _time = new Date(
            year || this.year,
            month || this.month,
            day || this.day,
            this.hour,
            this.minute,
            this.second
        );
        this.$emit("input", _time);
        this.$emit("change", {finished: info !== "h"});
    }
}
