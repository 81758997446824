import {BaseModule} from "@/lib/store/modules/BaseModule";
import {SubModule} from "@/lib/store/modules/SubModule";
import {DocumentsStore} from "@/services/store/modules/documents/DocumentsStore";
import {Portal} from "@/lib/Portal";
import {AssetModule} from "@/services/store/modules/assets/AssetModule";
import {AuthModule} from "@/services/store/modules/auth/AuthModule";
import {EntitiesModule} from "@/services/store/modules/entities/EntitiesModule";
import {VehiclesModule} from "@/services/store/modules/vehicles/VehiclesModule";

export class PortalModule extends BaseModule {
    public portal: Portal;

    @SubModule.Namespaced()
    public auth: AuthModule;

    @SubModule.Namespaced()
    public documents: DocumentsStore;

    @SubModule.Namespaced()
    public assets: AssetModule;

    @SubModule.Namespaced()
    public entities: EntitiesModule;

    @SubModule.Namespaced()
    public vehicles: VehiclesModule;

    public get portalApi() {
        return this.portal.api;
    }
}
