import {Metadata} from "@/lib/store/modules/metadata/ModuleMetadataManager";
import {ModuleMetadata} from "@/lib/store/modules/metadata/ModuleMetadata";
import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {NotImplementedError} from "@/lib/errors";
import {FlotePortalApi} from "@/services/api/PortalApi";

export class BaseModule<S = {}> {
    APIType!: FloteEndpointGroup;

    parent: BaseModule = null;
    constructor(parent?: BaseModule) {
        this.parent = parent;
    }
    state: S;
    public get portalApi(): FlotePortalApi {
        if(this.parent) {
            return this.parent.portalApi;
        }
        return null;
    }

    public get api(): this["APIType"] {
        if(this.parent) {
            return this.parent.api;
        }
        throw new NotImplementedError();
    }

    public static getMetaData(): ModuleMetadata {
        return Metadata.getForModel(this);
    }
    public static initMeta() {
        return Metadata.initForModel(this);
    }
    public static getOrInitMeta() {
        return this.getMetaData() || this.initMeta();
    }

    public static toVuex(...args: any[]) {
        const instance = new this(...args);
        return this.getMetaData().toVuexModule(instance);
    }

    public toVuex(namespaced: boolean) {
        return this.getMetaData().toVuexModule(this, namespaced);
    }

    public getMetaData() {
        return (this.constructor as ModuleClass).getMetaData();
    }
}

export type ModuleClass =  typeof BaseModule & (new (...args: any[]) => BaseModule);
