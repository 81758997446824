export default {
    errorHandler(state) {
        return state.errorHandler;
    },
    checkModals(state) {
        return state.modals;
    },
    isLoading(state) {
        return state.isLoading;
    },
    securitiesList() {
        return data => {
            return data;
        };
    },
    isLoaded(state) {
        return state.isLoaded;
    },
    isModalShown(state) {
        return state.checkModal;
    },
    suggestedVehiclesByTitleForInvestor(state) {
        return state.suggestedVehiclesList.map(item => ({
            name: item.legalName,
            value: item.id,
            isSelected: false,
        }));
    },
    getSuccessModalText(state) {
        return state.successModalText;
    },
};
