





















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ModalState} from "@/core/modals/ModalState";

@Component
export default class BaseModal extends Vue {
    @Prop({default: 'space-between'})
    public buttonPosition: string;

    @Prop({default: false})
    public showCloseButton: boolean;

    @Prop({required: true})
    public modalState!: ModalState;

    @Watch("modalState.isOpen")
    public onOpenStateChange(newValue: boolean) {
        if (newValue) {
            this.$emit("open")
        } else {
            this.$emit("close");
        }
    }

    public close() {
        this.modalState.close();
    }
}
