export default (params) => {
    const {
        startDate,
        endDate,
        startYear,
        endYear,
        onlyRelatedToVehicle,
        onlyRelatedToPosition,
        onlyRelatedToProject,
    } = params;

    if (onlyRelatedToVehicle && typeof onlyRelatedToVehicle !== "boolean") {
        throw new Error('Parameter onlyRelatedToVehicle should have a boolean type.')
    }

    if (onlyRelatedToPosition && typeof onlyRelatedToPosition !== "boolean") {
        throw new Error('Parameter onlyRelatedToPosition should have a boolean type.')
    }

    if (onlyRelatedToProject && typeof onlyRelatedToProject !== "boolean") {
        throw new Error('Parameter onlyRelatedToProject should have a boolean type.')
    }

    if (startDate && startYear) {
        throw new Error("Cannot specify startDate and startYear at the same time.");
    }

    if (endDate && endYear) {
        throw new Error("Cannot specify endDate and endYear at the same time.");
    }

    let queryKeysMap = {
        url: 'url',
        search: 'q',
        startDate: 'f_range_from',
        endDate: 'f_range_to',
        types: 'f_type',
        onlyRelatedToVehicle: 'f_is_vehicle_related',
        onlyRelatedToPosition: 'f_is_position_related',
        onlyRelatedToProject: 'f_is_project_related',
        tagsContain: 'f_tags_contain',
        tagsOverlap: 'f_tags_overlap',
        tags: 'f_tags_overlap', // TODO review this
        vehicleId: 'f_vehicle_id',
        projectId: 'f_project_id',
        perPage: 'per_page',
        page: 'page',
        startYear: 'f_range_from',
        endYear: 'f_range_to',
    };
    const components = [];
    let isValidProp;

    for (const key of Object.keys(params)) {
        if (typeof queryKeysMap[key] === "undefined") {
            throw new Error(`Wrong query parameter: ${key}`);
        }

        isValidProp = typeof params[key] !== 'undefined' && params[key] !== "";

        if (Array.isArray(params[key])) {
            isValidProp = isValidProp && params[key].join(',') !== "";
        }

        if (isValidProp) {
            components.push(`${queryKeysMap[key]}=${params[key]}`);
        }
    }
    return (components.length ? "?" : "") + components.join("&");
}
