



































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {guidGenerator} from "@/services/helpers/sponsor/randomId";
import {FloteEnum} from "@/lib/enums/FloteEnum";

@Component
export default class FloteDropdown extends Vue {
    @Prop({default: () => guidGenerator()})
    public id!: string;

    @Prop({default: null})
    public value!: string;

    @Prop({default: null})
    public placeholder: string;

    @Prop({default: false})
    public isDisabled!: boolean;

    @Prop({default: false})
    public isRequired!: boolean;

    @Prop({required: true})
    public enumClass: typeof FloteEnum;

    @Prop({default: null})
    public errorMessage!: string;

    @Prop({default: false})
    public highlightedAsError!: boolean;

    public get valueProxy() {
        return this.value;
    }

    public set valueProxy(value: string) {
        this.$emit("input", value);
    }

    public changed() {
        this.$emit("change");
    }

    public get isValueSet() {
        return Boolean(this.value);
    }

    public get options() {
        return this.enumClass.getAll();
    }
}
