import {MetadataManager} from "@/lib/metadata/MetadataManager";
import {FloteResponse, ResponseModel} from "../FloteResponse";
import {ResponseMetadata} from "./ResponseMetadata";

const METADATA_KEY = "FLOTE_MODULE_METADATA";

export class ResponseMetadataManager extends MetadataManager<ResponseMetadata> {
    public baseModel = FloteResponse;
    public metadataKey: string = METADATA_KEY;

    public getNewMetadata(model: ResponseModel, baseMeta: ResponseMetadata): ResponseMetadata {
        return new ResponseMetadata(model, baseMeta);
    }
}

export const Metadata = new ResponseMetadataManager();
