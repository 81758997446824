import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

export class ReservationApi extends FloteEndpointGroup {
    public relativeUri: string = "offerings-reservations";

    public downloadAsCsv(id: string) {
        return this.download(this.getFullUrl("download")).filter({
            f_offering_id: id
        });
    }
}
