import {ValueFormatter} from "./ValueFormatter";

export class DefaultFormatter extends ValueFormatter {
    public mask(value: string): string {
        return value || "";
    }

    public unmask(value: string): string {
        if (value === "") {
            return null;
        }
        return value;
    }

    public format(value: string): string {
        return value ? value.toString() : "";
    }

    public safeValue(value: string): string {
        return value || "";
    }
}
