export const SET_ALL_ASSETS = "setAllAssets";
export const SET_ASSET_BY_ID = "setAssetById";
export const SET_PROJECT_ASSETS = "setProjectAssets";
export const SET_ASSET_MAIN_PHOTO = "setAssetMainPhoto";
export const SET_ASSET_GALLERY_PHOTO = "setAssetGalleryPhoto";

export const SET_OFFERING_DOCUMENTS = "setOfferingDocuments";
export const SET_DOCUMENTS_REQUEST = 'setDocumentsRequest';
export const SET_VEHICLE_DOCUMENTS = 'setVehicleDocuments';
export const SET_DOCUMENT_UPLOAD_TYPE = 'setDocumentUploadType';

export const SET_ENTITIES = "setEntities";
export const SET_USERS = "setUsers";
export const SET_USER = "setUser";

export const SET_ERROR_HANDLER = "setErrorHandler";
export const SET_ERROR_IN_ERROR_HANDLER = 'setErrorInErrorHandler';
export const REMOVE_ERROR_HANDLER = "removeErrorHandler";
export const SET_MODALS = "setModals";
export const HIDE_MODALS = "hideModals";
export const HIDE_ALL_MODALS = "hideAllModals";
export const SET_IS_LOADING = "setIsLoading";
export const SET_IS_REQUEST_PROCESSING = 'setIsRequestProcessing';
export const REMOVE_IS_REQUEST_PROCESSING = 'removeIsRequestProcessing';
export const REMOVE_IS_LOADING = "removeIsLoading";
export const EMPTY_ERRORS = 'emptyErrors';

export const SET_IMAGE_IS_PROCESSES = "setImageIsProcesses";
export const REMOVE_IMAGE_IS_PROCESSES = "removeImageIsProcesses";

export const SET_VEHICLE_BY_ID = "setVehicleById";
export const SET_VEHICLES_BY_USER = "setVehicleByUserId";
export const SET_ALL_VEHICLES = "setAllVehicles";
export const SET_SUGGESTED_VEHICLES_LIST = "setSuggestedVehiclesList";
export const SET_VEHICLES_BY_PROJECT = "setVehiclesByProject";

export const SET_TRANSACTION_BY_VEHICLE_ID = "setTransactionsByVehicleId";
export const SET_CAPITAL_CALLS = 'setCapitalCalls';
export const SET_TRANSACTION = 'setTransaction';
export const SET_TRANSACTION_LIST = 'setTransactionList';
export const SET_CONTRIBUTIONS = 'setContribution';

export const SET_DOCUMENT_GROUP_DATA = 'setDocumentGroupData';

export const SET_POSITIONS = "setPositions";
export const SET_COMMON_EQUITY = 'setCommonEquity';

export const SET_SINGLE_PROJECT = "setSingleProject";
export const SET_PROJECTS_LIST = "setProjectsList";

export const SET_OFFERINGS = "setOfferings";
export const SET_SINGLE_OFFER = "setSingleOffer";
export const SET_PENDING_POSITIONS_LIST = "setPendingPositionsList";

export const SET_INVESTORS_LIST = "setInvestorsList";

export const SET_SPONSOR_INFO = 'setSponsorInfo';
export const SET_SPONSOR_TERMS = 'setSponsorTerms';
export const SET_SPONSOR_POLICY = 'setSponsorPolicy';

export const SET_IS_EDITABLE = "setIsEditable";
export const SET_EDITING_CONTRIBUTION_ID = "setEditingContributionId";
export const SET_DELETING_CONTRIBUTION_ID = "setDeletingContributionId";

export const SET_NEW_POSITION = 'setNewPosition';
export const ADD_INVESTOR_GROUP = 'AddInvestorGroup';
export const DELETE_INVESTOR_GROUP = 'DeleteInvestorGroup';

export const SET_COUNTRIES_LIST = 'setCountriesList';
export const SET_STATES_LIST = 'setStatesList';

export const SET_SCROLLABLE_MAIN_CONTAINER = 'scrollableMainContainer';

export const SET_TRANSACTION_NOTIFICATIONS = 'setTransactionNotifications';
export const SET_NOTIFICATION_PREVIEW = 'setNotificationPreview';
export const SET_CONTACT_NOTIFICATION_PREVIEW = 'setContactNotificationPreview';
export const SET_DOCUMENT_NOTIFICATION_PREVIEW = 'setDocumentNotificationPreview';
export const SET_DOCUMENT_NOTIFICATIONS = 'setDocumentNotifications';
