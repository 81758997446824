// TODO find a better naming for 'isLoading' & 'isRequestProcessing' states.

export default {
    isLoading: [],
    isRequestProcessing: false,
    modals: [],
    scrollableMainContainer: false,
    errorHandler: {
        pdfFileName: "",
        data: {
            message: "",
            errors: {},
        },
    },
    documentId: '',
    positionVehicleAssets: [],
    isLoaded: [],
    checkModal: [],
    error: {
        data: {
            message: '',
        },
    },
    suggestedVehiclesList: [],
    successModalText: '',
};
