export function getAccountStatus(user) {
    if (!user.isActive) {
        return AccountStatus.DEACTIVATED;
    }

    if (!user.isRegistered) {
        return AccountStatus.NOT_REGISTERED;
    }

    return AccountStatus.ACTIVE;
}

export class AccountStatus {
    public readonly name: string;
    public readonly className: string;

    constructor(name: string, className: string) {
        this.name = name;
        this.className = className;
    }

    static ACTIVE = new AccountStatus("Active", "green-text");
    static DEACTIVATED = new AccountStatus("Deactivated", "red-text");
    static NOT_REGISTERED = new AccountStatus("Not Registered", "grey-text");
    static NONE = new AccountStatus("", "");
}
