import {ModuleMember} from "@/lib/store/modules/metadata/ModuleMember";
import {BaseModule} from "@/lib/store/modules/BaseModule";

export class Mutation extends ModuleMember {
    public toVuex(module: BaseModule) {
        const key = this.key;
        return function (state, payload) {
            return module[key](payload);
        }
    }
}
