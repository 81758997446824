import http from '../../../../helpers/sponsor/managers/requestManager';
import O_o from '../../../../helpers/sponsor/promiseHandler';
import {GET} from '../../../../helpers/sponsor/actionMethods';

export default {
  async getCountriesList({commit}, data) {
  const [error, response] = await O_o(http.sendRequest(GET(`https://restcountries.eu/rest/v1/all`)));
  if (error)
	{
	throw error;
	}
  commit('setCountriesList', response);
  },
  async getStatesList({commit}, data) {
  const [error, response] = await O_o(http.send(GET(`/etc/config`)));
  if (error)
	{
	throw error;
	}
  commit('setStatesList', response.enums.states.US);
  },
};
