import {BasicFilter} from "@/lib/store/state/filters/BasicFilter";
import {FloteEnum} from "@/lib/enums/FloteEnum";
import {DisplayOptionFilterItem} from "@/lib/store/state/filters/OptionFilter";

export class EnumFilter extends BasicFilter {
    public defaultValue: string = null;
    public defaultText: string = "";
    public enumClass: typeof FloteEnum;

    constructor(propName: string, enumClass: typeof FloteEnum) {
        super(propName);
        this.enumClass = enumClass;
    }

    public get displayOptions(): DisplayOptionFilterItem[] {
        return [
            {value: this.defaultValue, text: this.defaultText, disabled: true},
            ...this.enumClass.getAll().map(i => ({
                value: i.name,
                text: i.displayValue,
                disabled: false
            }))
        ];
    }
}
