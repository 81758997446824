import axios from "axios";
import {SponsorTokenManager} from "../../../../lib/api/auth/SponsorTokenManager";
import store from "../../../store";
import interceptor from './interceptor';
import {REMOVE_IS_LOADING, SET_IS_LOADING} from '../../../store/sponsor/storeConfigs/mutationTypes';
import {config} from '@/lib/config';

const tokenManager = new SponsorTokenManager();

class Request {

    constructor() {
        this.axios = axios.create({
            baseURL: `${config.API_BASE_URL}sponsor/`,
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${tokenManager.getAccessToken()}`,
                "Content-Type": "application/json",
            },
        });

        this.AxiosNoCreds = axios.create({
            withCredentials: false,
        });

        this.multipartAxios = axios.create({
            baseURL: config.API_BASE_URL,
            withCredentials: true,
        });

        this.multipartAxiosWithOutCreds = axios.create({
            baseURL: config.API_BASE_URL,
            withCredentials: false,
        });

        this.axios.interceptors.response.use(null, interceptor);
    }

    send() {
        let op = arguments[0];
        let data = arguments[1];
        op.loading ? store.commit(SET_IS_LOADING, op.loading) : {};
        op.headers = {
            ...op.headers,
            Authorization: `Bearer ${tokenManager.getAccessToken()}`,
        } || {};

        return this.axios({
                method: op.method,
                url: op.url,
                responseType: op.responseType ? op.responseType : "",
                headers: {
                    ...op.headers,
                    "Content-Type": "application/json",
                },
                params: op.query,
                data: data,
            })
            .then((res) => {
                if (res.headers['x-renew-access-token']) {
                    tokenManager.setAccessToken(res.headers['x-renew-access-token']);
                }
                if (res.headers['x-renew-refresh-token']) {
                    tokenManager.setRefreshToken(res.headers['x-renew-refresh-token']);
                }
                op.loading ? store.commit(REMOVE_IS_LOADING, op.loading) : {};

                return op.responseType ? res : res.data;
            })
            .catch((err) => {
                if (
                    err.response.status === 401 &&
                    err.response.statusText === "Unauthorized"
                ) {
                    tokenManager.logout();
                }
                if (err.response.status === 500) {
                    console.log(
                        "%c Dear Ben ",
                        "color: white; background-color: #95B46A",
                        "This is a backend problem"
                    );
                }
                throw err.response;
            });
    }

    sendRequest() {
        let op = arguments[0];
        let data = arguments[1];
        return this.AxiosNoCreds({
                method: op.method,
                url: op.url,
                params: op.query,
                data: data,
            })
            .then(res => res.data)
            .catch(err => {
                throw err.response;
            });
    }

    clearAxios() {
        this.axios = undefined;
    }
}

export default new Request();
