import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

export class SponsorDataApi extends FloteEndpointGroup {
    public relativeUri: string = "sponsor-data";

    public export() {
        return this.request(this.getFullUrl("export"));
    }

    public downloadExport(id: string) {
        return this.download(this.getFullUrl("export", id, "download"));
    }

    public import(file: File) {
        return this.post(this.getFullUrl("import")).attachFile("importJson", file);
    }

    public remove() {
        return this.post(this.getFullUrl("delete"));
    }
}
