import actions from "./positionActions";
import getters from "./positionGetters";
import state from "./positionState";
import mutations from "./positionMutations";

const position = {
  actions,
  getters,
  state,
  mutations,
};

export default position;
