import {Module} from "vuex";
import {BaseModule} from "@/lib/store/modules/BaseModule";
import {Action} from "@/lib/store/modules/Action";
import {ModuleMember} from "@/lib/store/modules/metadata/ModuleMember";
import {Getter} from "@/lib/store/modules/Getter";
import {Mutation} from "@/lib/store/modules/Mutation";
import {ModuleMemberTypeNotResolvedError} from "@/lib/store/modules/metadata/errors";
import {Metadata} from "@/lib/metadata/Metadata";
import {SubModule} from "@/lib/store/modules/SubModule";
import {Pojo} from "@/lib/types";

export class ModuleMetadata extends Metadata {
    public model = BaseModule;
    public namespaced: boolean = true;
    public getters: Getter[] = [];
    public mutations: Mutation[] = [];
    public actions: Action[] = [];
    public modules: SubModule[] = [];

    public register(member: ModuleMember) {
        if (member instanceof  Action) {
            this.actions.push(member);
            return;
        }
        if (member instanceof Mutation) {
            this.mutations.push(member);
            return;
        }
        if(member instanceof Getter) {
            this.getters.push(member);
            return;
        }
        throw new ModuleMemberTypeNotResolvedError();
    }

    public addModule(moduleClass: SubModule) {
        this.modules.push(moduleClass);
    }

    private mapMembers(instance: BaseModule, members: ModuleMember[]) {
        const mapped = {};
        for (const member of members) {
            mapped[member.name] = member.toVuex(instance);
        }
        return mapped;
    }

    private getMappedModules(instance: BaseModule, namespaced: boolean) {
        const mapped = {};
        for (const module of this.modules) {
            const subModule = module.getInstance(instance);
            instance[module.name] = subModule;
            const vuex = subModule.toVuex(module.isNamespaced);
            if (namespaced) {
                vuex.getters = prefixObjectKeys(module.key, vuex.getters);
                vuex.mutations = prefixObjectKeys(module.key, vuex.mutations);
                vuex.actions = prefixObjectKeys(module.key, vuex.actions);
            }
            mapped[module.name] = vuex;
        }
        return mapped;
    }

    public toVuexModule(instance: BaseModule, namespaced: boolean = undefined): Module<any, any> {
        if (typeof namespaced === "undefined") {
            namespaced = this.namespaced;
        }
        return {
            namespaced: namespaced,
            state: () => instance.state,
            getters: this.mapMembers(instance, this.getters),
            mutations: this.mapMembers(instance, this.mutations),
            actions: this.mapMembers(instance, this.actions),
            modules: this.getMappedModules(instance, namespaced),
        }
    }

    public copy(): ModuleMetadata {
        const meta = new ModuleMetadata(this.model, this.baseMeta);
        meta.namespaced = this.namespaced;
        meta.actions = this.actions.map(a => a.copy());
        meta.getters = this.getters.map(a => a.copy());
        meta.mutations = this.mutations.map(a => a.copy());
        meta.modules = this.modules.map(a => a.copy());
        return meta;
    }
}

function prefixObjectKeys(prefix: string, object: Pojo) {
    const newObject = {};
    for (const key of Object.keys(object)) {
        newObject[`${prefix}.${key}`] = object[key];
        // todo: remove this
        newObject[`${key}`] = object[key];
    }
    return newObject;
}
