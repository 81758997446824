export default {
    positions: {
        items: [],
    },
    transferPositionFromInfo: "",
    commonEquity: {
        items: [],
    },
    positionCreation: {},
    newPositionData: {
        description: {},
        capitalType: {},
        totalProjectCommonEquity: {},
        effectiveDate: {},
        vehicle: {},
        groups: [],
    },
};
