export default {
    lineChartData: [],
    doughnutData: [],
    newStateData: [
        'rgb(254, 191, 0)',
        'rgb(204, 46, 67)',
        'rgb(70, 90, 97)',
        'rgb(255, 159, 0)',
        'rgb(119, 119, 119)',
    ],
    hoveredColorData: [
        'rgb(274, 211, 20)',
        'rgb(224, 66, 87)',
        'rgb(90, 110, 117)',
        'rgb(275, 179, 20)',
        'rgb(139, 139, 139)',
    ],
};
