<template>
    <div :class="{'datepicker-range':range,'datepicker__clearable':clearable&&text&&!disabled}">
        <button :value="text" :class="isLeft ? 'calendar-button-left' : 'calendar-button-right'" :disabled="disabled"
                :placeholder="placeholder" :name="name" v-if="type!=='inline'"></button>
        <a class="datepicker-close" @click.stop="cls"></a>
        <transition name="modal">
            <div :class="popupClass" tabindex="-1" v-if="show||type==='inline'">
                <div class="datepicker-popup" :class="[{'datepicker-inline':type==='inline'}]">
                    <template v-if="range">
                        <vue-datepicker-local-calendar v-model="dates[0]" :left="true"></vue-datepicker-local-calendar>
                        <vue-datepicker-local-calendar v-model="dates[1]" :right="true"></vue-datepicker-local-calendar>
                    </template>
                    <template v-else>
                        <vue-datepicker-local-calendar v-model="dates[0]"></vue-datepicker-local-calendar>
                    </template>

                    <div class="checkbox-wrapper" style="display: inline-block" v-if="hasApplyToAll">
                        <label class="checkbox-container">
                            <input type="checkbox"
                                   v-model="applyToAll"
                                   hidden/>
                            <span class="tick-container big f_checkbox"></span>
                            <div class="message-container">
                                <h6>Apply To All Records</h6>
                                <h5 class="f_affectedCountMessageContainer checked"
                                    :class="applyToAll ? 'checked' : 'is_hidden'">
                                    <span class="f_recordsCount">{{ recordsAffected }}</span> Records Will Be Affected
                                </h5>
                            </div>
                        </label>
                    </div>
                    <div v-if="showButtons" class="datepicker__buttons">
                        <button @click.prevent.stop="cancel" class="datepicker__button-cancel">
                            {{ this.local.cancelTip }}
                        </button>
                        <button @click.prevent.stop="submit" class="datepicker__button-select">
                            {{ this.local.submitTip }}
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import VueDatepickerLocalCalendar from "./DatepickerCalendar.vue";

export default {
    name: "VueDatepickerLocal",
    components: {VueDatepickerLocalCalendar},
    props: {
        name: [String],
        inputClass: [String],
        popupClass: [String],
        value: [Date, Array, String],
        disabled: [Boolean],
        type: {
            type: String,
            default: "normal",
        },
        rangeSeparator: {
            type: String,
            default: "~",
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        isLeft: {
            type: Boolean,
            default: false
        },
        recordsAffected: Number,
        hasApplyToAll: {
            type: Boolean,
            default: false
        },
        placeholder: [String],
        disabledDate: {
            type: Function,
            default: () => false,
        },
        format: {
            type: String,
            default: "YYYY-MM-DD",
        },
        local: {
            type: Object,
            default() {
                return {
                    dow: 0, // Sunday is the first day of the week
                    hourTip: "Select Hour", // tip of select hour
                    minuteTip: "Select Minute", // tip of select minute
                    secondTip: "Select Second", // tip of select second
                    monthsHead: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split(
                        "_"
                    ), // months of head
                    months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
                    weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks,
                    cancelTip: "Cancel",
                    submitTip: "Confirm",
                };
            },
        },
        showButtons: {
            type: Boolean,
            default: false,
        },
        dateRangeSelect: [Function],
    },
    data() {
        return {
            show: false,
            dates: this.vi(this.value),
            applyToAllValue: false
        };
    },
    computed: {
        applyToAll: {
            get() {
                return this.applyToAllValue;
            },
            set(value) {
                this.applyToAllValue = value;
                this.$emit("applyToAll", value);
            }
        },

        range() {
            return this.dates.length === 2;
        },
        text() {
            const val = this.value;
            const txt = this.dates
                .map(date => this.tf(date))
                .join(` ${this.rangeSeparator} `);
            if (Array.isArray(val)) {
                return val.length > 1 ? txt : "";
            }
            return val ? txt : "";
        },
    },
    watch: {
        value(val) {
            this.dates = this.vi(this.value);
        },
    },
    mounted() {
        document.addEventListener("click", this.dc, true);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.dc, true);
    },
    created() {
        this.initialValue = this.value;
    },
    methods: {
        showPicker() {
            this.show = true;
        },
        get() {
            return Array.isArray(this.value) ? this.dates : this.dates[0];
        },
        cls() {
            this.$emit("clear");
            this.$emit("input", this.range ? [] : "");
        },
        vi(val) {
            if (Array.isArray(val)) {
                return val.length > 1
                    ? val.map(item => new Date(item))
                    : [new Date(), new Date()];
            }
            return val ? new Array(new Date(val)) : [new Date()];
        },
        ok(leaveOpened) {
            const $this = this;
            $this.$emit("input", $this.get());
            !leaveOpened &&
            !$this.showButtons &&
            setTimeout(() => {
                $this.show = $this.range;
            });
        },
        tf(time, format) {
            const year = time.getFullYear();
            const month = time.getMonth();
            const day = time.getDate();
            const hours24 = time.getHours();
            const hours = hours24 % 12 === 0 ? 12 : hours24 % 12;
            const minutes = time.getMinutes();
            const seconds = time.getSeconds();
            const milliseconds = time.getMilliseconds();
            const dd = t => `0${t}`.slice(-2);
            const map = {
                YYYY: year,
                MM: dd(month + 1),
                MMM: this.local.months[month],
                MMMM: this.local.monthsHead[month],
                M: month + 1,
                DD: dd(day),
                D: day,
                HH: dd(hours24),
                H: hours24,
                hh: dd(hours),
                h: hours,
                mm: dd(minutes),
                m: minutes,
                ss: dd(seconds),
                s: seconds,
                S: milliseconds,
            };
            return (format || this.format).replace(
                /Y+|M+|D+|H+|h+|m+|s+|S+/g,
                str => map[str]
            );
        },
        dc(e) {
            this.show = this.$el.contains(e.target) && !this.disabled;
        },
        submit() {
            this.$emit("confirm", {val: this.get(), applyToAll: this.applyToAllValue});
            this.show = false;
            this.applyToAllValue = false;
        },
        cancel() {
            this.$emit("cancel", {val: this.initialValue});
            this.show = false;
            this.applyToAllValue = false;
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../assets/sponsor/styles/base/datepicker/datepicker.scss";
</style>
