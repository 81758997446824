import {LoaderState} from "@/lib/store/state/loading/LoaderState";
import {ErrorState} from "@/lib/store/state/errors/ErrorState";

export interface RepositoryItemState<Resource> {
    data: Resource,
    loader: LoaderState,
    errors: ErrorState
}

export class RepositoryState<Resource> {
    [id: string]: RepositoryItemState<Resource>;
}
