import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const TransactionsRouter = new Router();

@TransactionsRouter.register()
export class CreateCapitalCallTransactionRoute extends Route {
    getComponent() {
        return import("@/views/Transactions/CreateCapitalCallTransaction.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/:equityType/capitalCall/create";
    }
}

@TransactionsRouter.register()
export class CapitalCallTransactionRoute extends Route {
    getComponent() {
        return import("@/views/Transactions/CapitalCallTransactions.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/capitalCall/:transactionId/:transactionType";
    }
}

@TransactionsRouter.register()
export class ContributionTransactionRoute extends Route {
    getComponent() {
        return import("@/views/Transactions/CapitalCallTransactions.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/capitalCall/:transactionId/:transactionType/contributions";
    }
}

@TransactionsRouter.register()
export class CreateDistributionTransactionRoute extends Route {
    getComponent() {
        return import("@/views/Transactions/CreateDistributionTransaction.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/:equityType/distributions/create";
    }
}

@TransactionsRouter.register()
export class DistributionTransactionRoute extends Route {
    getComponent() {
        return import("@/views/Transactions/DistributionTransactions.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/distributions/:transactionId/:transactionType";
    }
}

@TransactionsRouter.register()
export class CreateCommitmentTransactionRoute extends Route {
    getComponent() {
        return import("@/views/Transactions/CreateCommitmentTransaction.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/:equityType/commitments/create";
    }
}

@TransactionsRouter.register()
export class CommitmentTransactionRoute extends Route {
    getComponent() {
        return import("@/views/Transactions/CommitmentTransactions.vue");
    }

    getPath(): string {
        return "vehicles/:vehicleId/commitments/:transactionId/:transactionType";
    }
}
