import http from '../../../../helpers/sponsor/managers/requestManager';
import {
    OFFERING_INVITES,
    POSITIONS,
    VEHICLE_TRANSACTIONS,
} from '../../storeConfigs/actionUrls';
import {SET_ERROR_HANDLER,
} from '../../storeConfigs/mutationTypes';

import O_o from '../../../../helpers/sponsor/promiseHandler';
import {GET,} from '../../../../helpers/sponsor/actionMethods';

export default {
  async downloadCsv({ commit }, data) {
    const pendingPositionsQuery =
        data && data.securityId && data.securityId.length
            ? `?f_security_id=${data.securityId}&f_is_pending=true`
            : "";
    const vehicleId = data && data.vehicleId && data.vehicleId.length
            ? `?f_vehicle_id=${data.vehicleId}` : "";
      const searchQuery =
          data && data.searchQuery && data.searchQuery.length
              ? `&q=${data.searchQuery}` : "";
      const transactionType =
          data && data.transactionType && data.transactionType.length
              ? `&f_transaction_type=${data.transactionType}` : "";
      const status =
          data && data.status && data.status.length
              ? `&f_is_published=${data.status}` : "";
    const commonEquityQuery =
        data && data.vehicleId && data.vehicleId.length
            ? `${vehicleId}&f_capital_type=CommonEquity`
            : "";
    const transactionHistory =
        data && data.vehicleId && data.vehicleId.length
            ? `${vehicleId}${searchQuery}${transactionType}${status}`
            : "";
    let query = "";
    if (data && data.rootField && data.rootField === OFFERING_INVITES) {
      query += pendingPositionsQuery;
    }
    if (data && data.rootField && data.rootField === POSITIONS) {
      query += commonEquityQuery;
    }
    if (data && data.rootField && data.rootField === VEHICLE_TRANSACTIONS) {
        query += transactionHistory;
    }
    const [error, response] = await O_o(
        http.send({
          url: `${data.rootField}/download${query}`,
          method: "GET",
          responseType: "arraybuffer",
        })
    );
    if (error) {
      commit(SET_ERROR_HANDLER, error);
      throw error;
    }
    const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/csv" })
    );
    const link = document.createElement("a");
    link.href = url;
      link.setAttribute("download", `${data.fileName}.csv`);
     //or any other extension
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  },
};
