import {Router} from "@/lib/router/Router";
import {Route} from "@/lib/router/Route";

export function compose(...routes: Route[]) {
    const composed = new Router();
    for (const route of routes) {
        composed.addRoute(route);
    }

    return composed;
}
