import {FloteApiError} from "@/lib/api/errors/FloteApiError";
import {AxiosError} from "axios";
import {map} from "@/lib/api/errors/errors";

export class ErrorResolver {
    public readonly nameMap: {
        [k: string]: typeof FloteApiError,
    } = map;

    public resolve(error: AxiosError) {
        const name = error?.response?.data?.name;
        if (name && this.nameMap[name]) {
            return new this.nameMap[name](error);
        } else {
            return new FloteApiError(error);
        }
    }
}

export const errorResolver = new ErrorResolver();
