import { replacerSSN, replacerEIN } from '../taxIdReplacer';

const masks = {
  currency: {
    mask(value) {
      if (value === null) {
        return "";
      }
      if (value !== 0 || value) {
        return "$ " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    unmask(value) {
      value = parseFloat(value.toString().replace(/[^\d\.]/g, ""));
      return isNaN(value) ? 0 : value;
    },
  },
  percentage: {
    mask(value) {
      if (value === null) {
        return "";
      }
      if (value === 0 || value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " %";
      }
    },
    unmask(value) {
      value = parseFloat(value.toString().replace(/[^\d\.]/g, ""));
      return isNaN(value) ? 0 : value;
    },
  },
  taxIdSSN: {
    mask(value) {
      if (value === null) {
        return "";
      }
      if (value === 0 || value) {
        return value.toString().replace(/(^\d{3})(\d{2})(\d+$)/, replacerSSN)
      }
    },
    unmask(value) {
      return value.toString().replace('-', "");
    },
  },
  taxIdEIN: {
    mask(value) {
      if (value === null) {
        return "";
      }
      if (value === 0 || value) {
        return value.toString().replace(/(^\d{2})(\d+$)/, replacerEIN)
      }
    },
    unmask(value) {
      return value.toString().replace('-', "");
    },
  }
};

export default masks;
