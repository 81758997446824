import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const ContactsRouter = new Router();

@ContactsRouter.register()
export class ContactsRoute extends Route {
    getComponent() {
        return import("@/views/Contacts/Contacts.vue");
    }

    getPath(): string {
        return "contacts";
    }
}

@ContactsRouter.register()
export class CreateContactRoute extends Route {
    getComponent() {
        return import("@/views/Contacts/CreateContact.vue");
    }

    getPath(): string {
        return "contacts/create";
    }
}

@ContactsRouter.register()
export class ContactsSingeRoute extends Route {
    getComponent() {
        return import("@/views/Contacts/ContactSinglePage.vue");
    }

    getPath(): string {
        return "contacts/:id";
    }
}

@ContactsRouter.register()
export class ContactEditingRoute extends Route {
    getComponent() {
        return import("@/views/Contacts/ContactEditingPage.vue");
    }

    getPath() {
        return "contacts/:id/edit";
    }
}
