import {
    SET_IS_EDITABLE,
    SET_EDITING_CONTRIBUTION_ID,
    SET_CAPITAL_CALLS,
    SET_TRANSACTION,
    SET_TRANSACTION_LIST,
    SET_CONTRIBUTIONS,
    SET_DELETING_CONTRIBUTION_ID,
} from '../../storeConfigs/mutationTypes';
import {guidGenerator} from '../../../../helpers/sponsor/randomId';

export default {
    [SET_IS_EDITABLE](state, data) {
        state.isEditingTransaction = data;
    },
    [SET_EDITING_CONTRIBUTION_ID](state, data) {
        state.editingTransactionId = data;
    },
    [SET_DELETING_CONTRIBUTION_ID](state, data) {
        state.deletingContributionId = data;
    },
    [SET_CAPITAL_CALLS](state, data) {
        state.capitalCall = data;
    },
    [SET_TRANSACTION](state, data) {
        state.transaction = data;
    },
    [SET_TRANSACTION_LIST](state, data) {
        let dataField = 'positionTransactions';
        if (data.isCapitalCall) {
            dataField = 'capitalCalls';
        }
        if (data.isContribution) {
            dataField = 'contributions';
        }
        state.transaction[dataField] = data.items.map(item => {
            let transaction = state.transaction[dataField].find((t) => (t.positionState.id === item.id));
            if (!transaction) {
                transaction = {
                    id: guidGenerator(),
                    effectiveDate: '',
                    positionState: {
                        entity: {},
                    },
                    commitmentChange: '0.00',
                    recordedAmount: '0.00',
                    contributedAmount: '0.00',
                }
            }
            return {
                ...transaction,
                positionState: item
            };
        });
    },

    [SET_CONTRIBUTIONS](state, data) {
        state.createContribution = data;
    },
};
