





























import {Component, Prop, Vue} from "vue-property-decorator";
import {LoaderState} from "@/lib/store/state/loading/LoaderState";
import {cloudinaryApi} from "@/core/integrations/cloudinary/CloudinaryApi";

@Component
export default class ImageInput extends Vue {
    @Prop({required: true})
    public alt: string;

    @Prop({required: true})
    public value: string;

    @Prop()
    public height: string|number;

    @Prop()
    public width: string|number;

    @Prop({required: true})
    public folder: string;

    @Prop({default: false})
    public multiple: boolean;

    public loader = new LoaderState();

    public change(evt: InputEvent) {
        for (const image of (<HTMLInputElement>evt.target).files) {
            this.loader.execute(async () => {
                const res = await cloudinaryApi.upload(image, this.folder);
                this.$emit("input", res.getRawData()["public_id"]);
            });
        }
    };

    public remove() {
        this.$emit("input", null);
    }
}
