export class BaseFloteEntity {
    set(data) {
        Object.assign(this, data);
    }
}

export function initEntity(entityClass, data) {
    const entity = new entityClass();
    entity.set(data);
    return entity;
}
