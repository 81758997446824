import "reflect-metadata";
import {Constructor} from "@/lib/types";
import {AccessingMetaFromNotWrongModel} from "@/lib/errors";
import {isSubClass} from "@/lib/utils/subclass";
import {Metadata} from "@/lib/metadata/Metadata";

export abstract class MetadataManager<MT extends Metadata> {
    public abstract metadataKey: string;
    public abstract baseModel: Constructor<any>;

    public abstract getNewMetadata(model: Constructor<any>, baseMeta: MT): MT;

    public getForModel(model: Constructor<any>): MT {
        if (this.baseModel && !isSubClass(model, this.baseModel)) {
            throw new AccessingMetaFromNotWrongModel();
        }
        return Reflect.getMetadata(this.metadataKey, model);
    }

    public getOrInitMetaForModel(model: Constructor<any>): MT {
        return this.getForModel(model) || this.initForModel(model);
    }

    public initForModel(model: Constructor<any>) {
        let meta = this.getForModel(model);
        if (!meta) {
            meta = this.getNewMetadata(model, meta);
        }
        if (meta.model !== model) {
            const baseMeta = meta;
            meta = baseMeta.copy() as MT;
            meta.model = model;
            meta.baseMeta = baseMeta;
        }

        Reflect.defineMetadata(
            this.metadataKey,
            meta,
            model,
        );

        return meta;
    }
}
