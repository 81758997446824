<template>
    <div @click="showCalendar" class="input-container dropdown-datepicker"
         :class="isValueSet ? 'active-text ' + containerClass : containerClass">
        <label class="placeholder" :class="{'transformed-label': isValueSet}" :for="id">
            {{ placeholder }} <span class="required" v-if="isRequired">*</span>
        </label>
        <input
            class="input-box"
            v-model="formattedValue"
            @blur="handleInputState"
            @focus="handleInputState"
            disabled
            :id="id"
            :class="error ? `error ${inputClass}` : inputClass"
        />
        <div class="error-message" v-if="error">
            {{ errorMessage }}
        </div>
        <vue-datepicker ref="datepicker"
                        :disabledDate="isDisabledDate"
                        :input-class="options.inputClass"
                        :show-buttons="options.showButtons"
                        :placeholder="options.placeholder"
                        :popup-class="options.popupClass"
                        v-model="normalizedValue">
        </vue-datepicker>
    </div>
</template>

<script>
import {guidGenerator} from '../../../../services/helpers/sponsor/randomId';
import {formatDate} from "../../../../services/helpers/sponsor/date/formatDate";
import moment from "moment";
import {DateRounder} from "@/services/helpers/sponsor/date/DateRounder";
import {fromLocalDate, toLocalDate} from "@/services/helpers/sponsor/date/DateConverter";
import {serializeDate, toDate} from "@/services/helpers/sponsor/date/common";
import {validationErrorMessages} from "@/config/sponsor/validationErrorConfigs";

export default {
    name: "DropdownDatePicker",
    data() {
        return {
            inputFocused: false,
        };
    },
    props: {
        rounder: {
            type: DateRounder,
            default: () => DateRounder.Down,
        },
        value: String,
        placeholder: String,
        type: String,
        options: {
            type: Object,
            default() {
                return {
                    inputClass: '',
                    showButtons: false,
                    popupClass: '',
                    placeholder: '',
                }
            }
        },
        dateFrom: {
            type: String,
            default: null,
        },
        dateTo: {
            type: String,
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        error: String,
        id: {
            type: String,
            default: () => guidGenerator(),
        },
        inputClass: String,
        containerClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        normalizedValue: {
            get: function () {
                return toLocalDate(toDate(this.value), this.rounder);
            },
            //TODO: remove submit and change all used cases
            set: function (value) {
                this.$emit("input", serializeDate(fromLocalDate(value, this.rounder)));
                this.$emit("submit", serializeDate(fromLocalDate(value, this.rounder)));
            },
        },
        formattedValue() {
            return formatDate(this.value);
        },
        isValueSet() {
            return !!this.value;
        },
        errorMessage() {
            if (this.normalizedValue === null && this.isRequired && this.error) {
                return validationErrorMessages.required;
            }
            if (this.normalizedValue === null && this.error) {
                return this.error;
            }
            return null;
        },
    },
    methods: {
        handleInputState(event) {
            this.inputFocused = event.type === "focus";
        },
        showCalendar() {
            let elem = this.$refs.datepicker;
            elem.showPicker();
        },
        isDisabledDate(time) {
            let localDateFrom = toLocalDate(toDate(this.dateFrom), DateRounder.Down);
            let localDateTo = toLocalDate(toDate(this.dateTo), DateRounder.Down);
            return moment(time).isBefore(localDateFrom)
                || moment(time).isAfter(localDateTo);
        },
    },
};
</script>

<style scoped lang="scss">
.dropdown-datepicker {
    ::v-deep .calendar-button {
        top: 3px;
    }
   ::v-deep .input-box:disabled {
        opacity: 1;
    }
}
</style>
