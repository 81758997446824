import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {
    CapitalCallTransactionRoute, CommitmentTransactionRoute,
    ContributionTransactionRoute,
    DistributionTransactionRoute,
} from "@/services/router/sponsor/transactions";
import {PositionTransactionType} from "@/core/enums/TransactionType";

export class TransactionsNavigator extends BasicNavigator {
    public getViewTransactionRoute(transactionType: string) {
        if (transactionType === PositionTransactionType.Distribution.name) {
            return DistributionTransactionRoute;
        }
        if (transactionType === PositionTransactionType.CapitalCall.name) {
            return CapitalCallTransactionRoute;
        }
        if (transactionType === PositionTransactionType.Contribution.name) {
            return ContributionTransactionRoute;
        }
        if (transactionType === PositionTransactionType.Commitment.name) {
            return CommitmentTransactionRoute;
        }
    }

    public single(vehicleId: string, transactionType: string, transactionId: string) {
        return this.route(this.getViewTransactionRoute(transactionType), {
            vehicleId, transactionId, transactionType
        });
    }
}
