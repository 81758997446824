import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import etc from "./sponsor/modules/etc";
import vehicle from "./sponsor/modules/vehicle";
import security from "./sponsor/modules/security";
import position from "./sponsor/modules/position";
import offer from "./sponsor/modules/offer";
import csv from "./sponsor/modules/csv";
import Documents from "@/services/store/sponsor/modules/Documents";
import transactions from "./sponsor/modules/transactions";
import SponsorStore from "@/services/store/modules/SponsorStore";
import ProjectModule from "@/services/store/sponsor/modules/ProjectModule";
import UserStore from "@/services/store/sponsor/modules/UserStore";
import AssetStore from "@/services/store/sponsor/modules/AssetStore";
import Regions from "@/services/store/sponsor/modules/Regions";

Vue.use(Vuex, {force: true});

import user from './investor/modules/user';
import charts from './investor/modules/charts';
import investorEtc from './investor/modules/etc';
import investorPosition from './investor/modules/position';
import documents from './investor/modules/documents';
import entity from './investor/modules/entity';
import offerings from './investor/modules/offerings';

import ImageUploadModule from "@/services/store/Cloudinary";
import NotificationsModule from "@/services/store/sponsor/modules/NotificationsModule";
import ContactNotificationModule from "@/services/store/sponsor/modules/notifications/ContactNotificationModule";
import DocumentNotificationModule from "@/services/store/sponsor/modules/notifications/DocumentNotificationModule";
import {SponsorModule} from "@/services/store/modules/portals/SponsorModule";
import {InvestorModule} from "@/services/store/modules/portals/InvestorModule";

export default new Vuex.Store({
    modules: {
        sponsor: SponsorModule.toVuex(),
        investor: InvestorModule.toVuex(),
        UserStore,
        AssetStore,
        Regions,
        etc,
        offer,
        csv,
        Documents,
        vehicle,
        position,
        security,
        Project: ProjectModule,
        transactions,
        Sponsor: SponsorStore,
        user,
        charts,
        investorEtc,
        ImageUploadModule,
        investorPosition,
        NotificationsModule,
        ContactNotificationModule,
        DocumentNotificationModule,
        documents,
        offerings,
        entity,
    },
    state,
    getters,
    mutations,
    actions,
});
