import Vue from "vue";
import {formatDate} from "./services/helpers/sponsor/date/formatDate";
import {
    formatNumericValue,
    formatAsInteger,
    formatNumericValueLong
} from './services/helpers/sponsor/filters/numberFormatter';
import {formatPossibleEmptyValue} from "./services/helpers/sponsor/filters/formatPossibleEmptyValue";
import {formatPhoneNumber} from "@/services/helpers/sponsor/filters/phoneNumberFormatter";
import {
    formatPercent,
    formatPercentLong,
    formatPortion,
    formatPortionLong
} from '@/services/helpers/sponsor/filters/percentFormatter';
import {addAsterisks} from "@/services/helpers/sponsor/addAsterisks";
import TrimText from '@/services/helpers/sponsor/trimText';
import {formatAddress} from "@/services/helpers/filters/formatAddress";
import {formatTaxId, formatTaxIdUnmasked} from "@/services/helpers/filters/formatTaxId";

Vue.filter("formatNumbersLong", formatNumericValueLong);
Vue.filter("formatPossibleEmptyValues", formatPossibleEmptyValue);
Vue.filter("trimmed", TrimText);
Vue.filter("formatTaxId", formatTaxId);
Vue.filter("formatNumbers", formatNumericValue);
Vue.filter("formatPercent", formatPercent);
Vue.filter("formatTaxIdUnmasked", formatTaxIdUnmasked);
Vue.filter("formatPortion", formatPortion);
Vue.filter("formatPortionPercent", (v) => formatPortion(v) + "%");
Vue.filter("formatPercentLong", formatPercentLong);
Vue.filter("formatPortionLong", formatPortionLong);
Vue.filter("formatAsInteger", formatAsInteger);
Vue.filter("formatPhoneNumber", formatPhoneNumber);
Vue.filter("formatAddress", formatAddress);

Vue.filter("formatDate", value => {
    return formatPossibleEmptyValue(formatDate(value));
});

Vue.filter("showValueWithAsterisks", value => {
    return addAsterisks(value);
});
