import RequestManager from '../../../../helpers/investor/managers/requestManager';
import constants from '../../../../../config/investor/constants';
import {
  REMOVE_IS_LOADING,
  SET_ALL_POSITIONS, SET_IS_LOADING,
  SET_POSITION_BY_ID,
  SET_POSITIONS_TRANSACTION,
} from '../../config/mutationTypes';

import { POSITION_TRANSACTIONS, POSITIONS } from '../../config/actionUrls';
import O_o from '../../../../helpers/investor/promiseHandler';
import { GET, POST } from '../../config/actionMethods';
import { api } from '@/services/api/FloteApi';
export default {
  async sellShares({ commit }, data) {
    const [error, response] = await O_o(RequestManager.send(POST(`${POSITIONS}/${data.path}/sell`),
      data.data,
    ));
    if(error) {
      throw error;
    }
    commit('removeIsModalShown', constants.modals.TRANSFER);
    commit('setIsModalsShown', constants.modals.SUCCESS);
    commit(
      'setSuccessModalText',
      `We have received your request and will respond to ${data.user} shortly`,
    );
  },
  async transfer({ commit }, data) {
    const [error, response] = await O_o(RequestManager.send(
      POST(`${POSITIONS}/${data.path}/transfer`),
      data.data,
    ));
    if(error){
      throw  error;
    }
    commit('removeIsModalShown', constants.modals.TRANSFER);
    commit('setIsModalsShown', constants.modals.SUCCESS);
    commit(
      'setSuccessModalText',
      `We have received your request and will respond to ${data.user} shortly`,
    );
  },
  async buy({ commit }, data) {
    const [error, response] = await O_o(RequestManager.send(
      POST(`${POSITIONS}/${data.path}/buy`),
      data.data,
    ));
    if(error) {
      throw error;
    }
    commit('removeIsModalShown', constants.modals.TRANSFER);
    commit('setIsModalsShown', constants.modals.SUCCESS);
    commit(
      'setSuccessModalText',
      `We have received your request and will respond to ${data.user} shortly`,
    );
  },
  async getInvestorPositions({ commit }, data) {
    const [error, response] = await  O_o(RequestManager.send(
      GET(POSITIONS,constants.isLoadingStates.getPositionsData
      ))
    );
    if(error) {
      commit('removeIsLoading', constants.isLoadingStates.getPositionsData);
      throw error;
    }
    commit(SET_ALL_POSITIONS, response.items);
    commit('removeIsLoading', constants.isLoadingStates.getPositionsData);
  },
  async getPositionByID({ commit }, data) {
    const [error, response] = await O_o(RequestManager.send(
      GET(`${POSITIONS}/${data.id}`, constants.isLoadingStates.getPositionByID)
    ));
    if(error) {
      throw error;
    }
    commit(SET_POSITION_BY_ID, response);
    commit('removeIsLoading', constants.isLoadingStates.getPositionByID);
    return response;
  },
  async downloadPositionTransactions({ commit }, { positionId,  transactionType, dateFrom, dateTo}) {
    const req = api.investor.positionTransactions.downloadCsv().filter({
      f_position_id: positionId,
    });
    if (transactionType) {
      req.filter({
        f_transaction_type: transactionType
      });
    }
    if (dateFrom) {
      req.filter({
        f_effective_date_from: dateFrom
      });
    }
    if (dateTo) {
      req.filter({
        f_effective_date_to: dateTo
      });
    }

    commit(SET_IS_LOADING, constants.isLoadingStates.transactionsTableLoads);
    const res = await req;
    commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionsTableLoads);
    res.save("transactions.csv");
  },

  async getPositionsTransactions({ commit }, data) {
    const positionId =
      data && data.positionId && data.positionId.length
        ? `f_position_id=${data.positionId}`
        : '';
    const startDate =
      data && data.buttonDateOne && data.buttonDateOne.length
        ? `${data.buttonDateOne}`
        : '';
    const endDate =
      data && data.buttonDateTwo && data.buttonDateTwo.length
        ? `${data.buttonDateTwo}`
        : '';
    const download = data && data.download ? '/download' : '';
    const resType = download ? 'arraybuffer' : '';
    commit(SET_IS_LOADING, constants.isLoadingStates.transactionsTableLoads);
    const [error, response] = await  O_o(RequestManager.send({
      url: `${POSITION_TRANSACTIONS}${download}?${positionId}${
        startDate ? `&f_effective_date_from=${startDate}` : ''
      }${startDate !== endDate ? `&f_effective_date_to=${endDate}` : ''}`,
      method: 'GET',
      responseType: resType,
    }));
    if(error) {
      throw error;
    }
    if (resType) {
      let filename = 'transactions.csv';
      const disposition = response.headers['Content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      const url = window.URL.createObjectURL(
        new Blob([response.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      return response;
    }
    commit(SET_POSITIONS_TRANSACTION, response.items);
    commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionsTableLoads);
  },
};
