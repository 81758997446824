import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {OfferingsNDAApi} from "@/services/api/OfferingsNDAApi";
import {NotificationTemplateApi} from "@/services/api/NotificationTemplateApi";

export class OfferingDocumentsApi extends FloteEndpointGroup {
    public relativeUri: string = "documents";
    constructor(parent: FloteEndpointGroup, offeringId: string) {
        super(parent);
        this.relativeUri = `${offeringId}/documents`;
    }
}

export class OfferingsApi extends FloteEndpointGroup {
    public relativeUri: string = "offerings";

    public nda = new OfferingsNDAApi(this);

    public notificationTemplate = new NotificationTemplateApi(this);

    public activateAccount(token: string) {
        return this.post(this.getFullUrl("activate", token));
    }

    public getAggregated(id: string) {
        return this.request(this.getFullUrl(id, "aggregated"));
    }

    public documents(offeringId: string){
        return new OfferingDocumentsApi(this, offeringId);
    }
}
