import {
  SET_COUNTRIES_LIST,
  SET_ENTITY_STATES_LIST,
  SET_BANK_STATES_LIST,
  SET_SIGNATORY_STATES_LIST,
} from '../../config/mutationTypes';

export default {
  [SET_COUNTRIES_LIST](state, data) {
    state.countriesList = data;
  },
  [SET_ENTITY_STATES_LIST](state, data) {
    state.entityStatesList = data;
  },
  [SET_BANK_STATES_LIST](state, data) {
    state.bankStatesList = data;
  },
  [SET_SIGNATORY_STATES_LIST](state, data) {
    state.signatoryStatesList = data;
  },
};
