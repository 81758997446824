import actions from "./transactionsActions";
import getters from "./transactionsGetters";
import state from "./transactionsState";
import mutations from "./transactionsMutations";

const transactions = {
  actions,
  getters,
  state,
  mutations,
};

export default transactions;
