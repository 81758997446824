import {formatPossibleEmptyValue} from "@/services/helpers/sponsor/filters/formatPossibleEmptyValue";

export default function TrimText(data: string, maxChars = 30) {
    data = formatPossibleEmptyValue(data);

    if (data && data.length > maxChars) {
        return `${data.slice(0, Math.floor(maxChars / 3))}...${data.substr(data.length - Math.floor(maxChars / 3 * 2))}`;
    }
    return data;
}
