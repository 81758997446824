import {multiply} from '../math/lib';
import {formatNumericValue, formatNumericValueLong} from "@/services/helpers/sponsor/filters/numberFormatter";
import {NumericValue} from "@/services/helpers/sponsor/math/Decimal";

//TODO write tests for these functions.
export function formatPortion(value: NumericValue, decimalPoints?: number) {
    return formatPercent(multiply(value, 100), decimalPoints);
}

export function formatPortionLong(value: NumericValue, decimalPoints?: number) {
    return formatPercentLong(multiply(value, 100), decimalPoints);
}

export function formatPercent(value: NumericValue, decimalPoints?: number) {
    return formatNumericValue(value, decimalPoints);
}

export function formatPercentLong(value: NumericValue, decimalPoints?: number) {
    return formatNumericValueLong(value, decimalPoints) + "%";
}
