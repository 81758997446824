import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const EntitiesSponsorRouter = new Router();

@EntitiesSponsorRouter.register()
export class EntityEditSponsorRoute extends Route {
    getComponent() {
        return import("@/components/sections/EntityEditCreate.vue");
    }

    getPath(): string {
        return "contacts/:contactId/entities/:id/edit";
    }

}
