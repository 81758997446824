import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

export class ContactsApi extends FloteEndpointGroup {
    public relativeUri: string = "users";

    public getLoginAsUrl(userId: string) {
        return this.withAccessToken(this.getFullUrl("login-as", userId));
    }

    public createMultiple() {
        return this.post(this.getFullUrl("/multiple"));
    }

    public changeAccreditationStatus(id: string, isAccredited: boolean) {
        return this.post(this.getFullUrl(id, "accreditation-status")).data({
            isAccredited: isAccredited
        })
    }
}
