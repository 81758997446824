import actions from "./offerActions";
import getters from "./offerGetters";
import state from "./offerState";
import mutations from "./offerMutations";

const offer = {
  actions,
  getters,
  state,
  mutations,
};

export default offer;
