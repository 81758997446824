import {Pojo} from "@/lib/types";

export function formatAddress(value: Pojo, prefix: string = null) {
    const addressComponents = [];

    let streetAddress = prefix ? value[prefix + "StreetAddress"] : value["streetAddress"];
    let city = prefix ? value[prefix + "City"] : value["city"];
    let state = prefix ? value[prefix + "State"] : value["state"];
    let postalCode = prefix ? value[prefix + "PostalCode"] : value["postalCode"];

    if (streetAddress) {
        addressComponents.push(streetAddress);
    }
    if (city) {
        addressComponents.push(city);
    }
    if (state) {
        addressComponents.push(state);
    }
    if (postalCode) {
        addressComponents.push(postalCode);
    }

    if (!addressComponents.length) {
        return null;
    }

    return addressComponents.join(', ');
}
