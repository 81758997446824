import {Moment} from "moment";

export abstract class DateRounder {
    abstract round(date: Moment): Moment;

    public static Up: DateRounder;
    public static Down: DateRounder;
}

export class DownDateRounder extends DateRounder {
    public round(date: Moment): Moment {
        return date.startOf('day');
    }
}

export class UpDateRounder extends DateRounder {
    public round(date: Moment): Moment {
        return date.endOf('day');
    }
}

DateRounder.Up = new UpDateRounder();
DateRounder.Down = new DownDateRounder();
