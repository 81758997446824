import {FloteError} from "@/lib/errors";
import {AxiosError} from "axios";

export class FloteApiError extends FloteError {
    protected rawError: AxiosError;
    public readonly name: string;
    public readonly messaged: string;
    public readonly statusCode: number;

    constructor(error: AxiosError) {
        super();
        this.rawError = error;
        this.name = error?.response?.data?.name;
        this.messaged = error?.response?.data?.messaged;
        this.statusCode = error.response.status;
    }
}
