import {config} from "@/lib/config";

export const ASSETS = "/assets";

export const DOCUMENTS = "/documents";
export const DRAFT_DOCUMENT_GROUPS = "/draft-document-groups";
export const DOCUMENT_GROUPS = "/document-groups";
export const DRAFT_DOCUMENTS = "/draft-documents";

export const VEHICLES = "/vehicles";
export const VEHICLE_TRANSACTIONS = "/vehicle-transactions";
export const COMMITMENTS = "/commitments";
export const DISTRIBUTIONS = "/distributions";
export const CAPITAL_CALLS = '/capital-calls';
export const CONTRIBUTIONS = '/contributions';

export const INVESTMENTS = "/investments";


export const POSITIONS = "/positions";
export const POSITIONS_AVERAGED = "/positions/averaged";

export const REFRESH_TOKEN = '/auth/refresh-token';
export const LOGIN = "auth/login";
export const USERS = "/users";
// TODO: change this
export const ENTITIES = "/legal-entities";

export const PROJECTS = "/projects";

export const OFFERINGS = "/offerings";

export const INVESTOR_INVITES = "/investor-invites";
export const OFFERING_INVITES = "/offering-invites";

export const SPONSOR_INFO = '/sponsor/info';

export const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${
    config.CLOUDINARY_CLOUD_NAME
}/upload`;

export const NOTIFICATIONS = '/notifications';
export const TRANSACTIONS = '/transactions';
export const CONTACTS = '/contacts';
export const PREVIEW = '/preview';
export const WELCOME = '/welcome';
