import {CalendarDateValidator} from "@/components/ui/inputs/date/validator/CalendarDateValidator";
import moment from "moment";

export class DateRangeValidator extends CalendarDateValidator {
    public readonly minDate: Date;
    public readonly maxDate: Date;
    constructor(minDate: Date, maxDate: Date) {
        super();
        this.minDate = minDate;
        this.maxDate = maxDate;
    }
    public isValid(date: Date): boolean {
        return moment(date).isBetween(this.minDate, this.maxDate, "day", "[]");
    }
}
