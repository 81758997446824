import {Pagination} from "@/lib/store/state/list/Pagination";
import {LoaderState} from "@/lib/store/state/loading/LoaderState";
import {ErrorState} from "@/lib/store/state/errors/ErrorState";
import {FiltersState} from "@/lib/store/state/filters/FiltersState";

export type SelectableItem<T> = T & {
    isSelected: boolean;
    isSelectable: boolean;
};

export class ListState<Resource, AdditionalListState = {}, F = FiltersState> {
    public allItems: SelectableItem<Resource>[];
    public pagination: Pagination;
    public filters: F;
    public loader: LoaderState;
    public exportDownloadLoader: LoaderState;
    public errors: ErrorState;
    public additional: Partial<AdditionalListState>;

    constructor(additionalListState?: AdditionalListState, filter?: F) {
        this.allItems = [];
        this.pagination = new Pagination();
        this.filters = filter || new FiltersState() as any;
        this.loader = new LoaderState();
        this.exportDownloadLoader = new LoaderState();
        this.errors = new ErrorState();
        this.additional = additionalListState || {};
    }
}
