<template>
    <div :class="wrapperClass">
        <div class="limited-chars" :class="limitReached ? 'limit-reached' : ''">
            <div v-if="characterCount"
                 class="character-counter">
                {{ displayLength }} /{{ maxLength }}
            </div>
            <div class="input-container"
                 :class="{'active-text': displayLength, 'text-area': isTextArea}">
                <label class="placeholder" :for="id" :class="isTextArea ? 'textarea-placeholder' : '' ">
                    {{ placeholder }} <span class="required" v-if="isRequired">*</span>
                </label>
                <input
                    v-if="!isTextArea"
                    v-model="displayValue"
                    :title="displayValue"
                    @blur="handleInputBlurState"
                    @focus="handleInputState"
                    :type="type ? type : 'text'"
                    :id="id"
                    class="input-box"
                    :class="isInvalidInput ? 'error' : ''"
                />
                <textarea
                    v-if="isTextArea"
                    v-model="displayValue"
                    @blur="handleInputBlurState"
                    @focus="handleInputState"
                    :type="type ? type : 'text'"
                    :id="id"
                    class="input-box"
                    :class="isInvalidInput ? 'error' : ''"
                />
                <div class="error-message" v-if="error">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {guidGenerator} from '../../../../services/helpers/sponsor/randomId';
import masks from '../../../../services/helpers/sponsor/BaseInputHelper';
import {validationErrorMessages} from "@/config/sponsor/validationErrorConfigs";

export default {
    props: {
        characterCount: {
            type: Boolean,
            default: true,
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: String,
        type: String,
        maskType: String,
        blurEffect: Function,
        focusEffect: Function,
        maxLength: Number,
        wrapperClass: String,
        isRequired: {
            type: Boolean,
            default: false,
        },
        error: String,
        id: {
            type: String,
            default: () => guidGenerator(),
        },
        isTextArea: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            inputFocused: false,
            inputLength: null,
        };
    },
    computed: {
        errorMessage() {
            if (this.error && this.isRequired && this.value === "") {
                return validationErrorMessages.required;
            }
            if (this.error && (this.value === "" || this.value === null)) {
                return this.error;
            }
            return "";
        },
        isInvalidInput() {
            return (this.error && !this.value) || this.isOverLimit;
        },
        displayLength() {
            return this.value ? this.value.length : 0;
        },
        displayValue: {
            get() {
                if (this.value) {
                    if (this.maskType) {
                        return this.mask(this.value);
                    }
                    return this.value;
                }
                return '';
            },
            set(value) {
                if (this.maskType) {
                    this.$emit('input', this.unmask(value));
                } else {
                    this.$emit('input', value);
                }
            },
        },
        limitReached() {
            return this.displayLength >= this.maxLength;
        },
        isOverLimit() {
            return this.displayLength > this.maxLength;
        },
    },
    watch: {
        displayLength() {
            if (this.limitReached) {
                this.displayValue = this.value.slice(0, this.maxLength);
            }
        }
    },
    methods: {
        handleInputState(event) {
            if (this.focusEffect) {
                this.focusEffect();
            }
            this.inputFocused = event.type === 'focus';
        },
        handleInputBlurState(event) {
            if (this.blurEffect) {
                this.blurEffect();
            }
            this.inputFocused = event.type === 'focus';
        },
        unmask(value) {
            return masks[this.maskType].unmask(value);
        },
        mask(value) {
            return masks[this.maskType].mask(value);
        },
    },
};
</script>

<style scoped lang="scss">
.textarea-placeholder {
    background-color: $color-white-normal;
    height: 27px;
    width: calc(100% - 16px);
    padding-top: 7px;
}

.limited-chars {
    max-width: 330px;

    .input-container {
        width: 100%;
    }

    .character-counter {
        @include font(14px, 300, 'SF_Light');
        color: $color-grey-dark;
        text-align: right;
    }

    textarea {
        resize: none;
        padding-top: 27px;
        @include font(16px, 300, SF_Light);
        color: $color-black-semi-strong;
    }

    .input-container.text-area .placeholder {
        top: 10%;
    }

    .text-area.active-text .placeholder {
        top: 1px;
    }

    .input-box {
        &:focus {
            border-color: $color-green-bright;
        }
    }

    &.limit-reached {
        .character-counter {
            color: $color-red-strong;
        }
    }
}
</style>
