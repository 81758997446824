import RequestManager from '../../../../helpers/investor/managers/requestManager';
import tokenManager from '../../../../../lib/api/auth/InvestorTokenManager';
import constants from '../../../../../config/investor/constants';

import {
  SET_CURRENT_USER_INFO,
} from '../../config/mutationTypes';

import {
  USER,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD,
  LOGIN,
  LOGOUT,
  ACTIVATE_USER,
  SIGNUP,
  REFRESH_TOKEN,
  NDA,
  NDA_SIGN,
  ACCREDITATION, OFFERING_INVITES,
} from '../../config/actionUrls';
import {closeModal, openCloseModals} from '../../../../helpers/investor/openCloseModals';
import O_o from '../../../../helpers/investor/promiseHandler';
import { GET, PATCH, POST } from '../../config/actionMethods';
import manager from "../../../../../lib/api/auth/InvestorTokenManager";
import { DefaultRoute, SignInAfterVerificationRoute, SignInRoute } from '../../../../router/investor';
import { OfferingSingleRoute } from '../../../../router/investor/investment';
import { routerNavigateTo } from '../../../../../lib/router/Navigator';
export default {
  async refreshToken({ commit }, data) {
      const [error, response] = await O_o(RequestManager.send(
        POST(REFRESH_TOKEN),
        { refreshToken: tokenManager.getRefreshToken() },
      ));
      if(error) {
        tokenManager.setNextRoute();
        tokenManager.logout();
        throw error;
      }
      tokenManager.setAuthTokens(response.accessToken, response.refreshToken);
      return response;
  },
  async signUp({ commit }, data) {
     const [error, response] = await O_o(RequestManager.send(
        POST(SIGNUP),
        data,
      ));
   if(error) {
     commit('setErrorHandler', error);
     throw error;
   }
    setTimeout(() => {routerNavigateTo(SignInRoute.Name);}, 2000);
  },
  // : TODO change response logic
  async verifyUser(commit, data) {
    const [error, response] = await O_o(RequestManager.send(POST(ACTIVATE_USER)));
    if(error) {
      throw error;
    }
    tokenManager.removeAuthTokens();
    const params = { verified: 'verified'};

    routerNavigateTo(SignInAfterVerificationRoute.Name, params);
  },
  async login({ commit }, data) {
    manager.removeAuthTokens();
    commit('setIsLoading', constants.isLoadingStates.signInProcess);
    const [error, response] = await O_o(RequestManager.send(POST(LOGIN), data));
    if(error){
      commit('removeIsLoading', constants.isLoadingStates.signInProcess);
      commit('setErrorHandler', error);
      throw error;
    }
    tokenManager.setAuthTokens(response.accessToken, response.refreshToken);
    let nextRoute = tokenManager.getNextRoute();
    if (nextRoute) {
      let { name, params } = nextRoute;
      routerNavigateTo(name, params);
      tokenManager.removeNextRoute();
    } else {
      routerNavigateTo(DefaultRoute.Name);
    }
    commit('removeIsLoading', constants.isLoadingStates.signInProcess);
  },
  async logout({ commit }, data) {
    const [error, response] = await O_o(RequestManager.send(POST(LOGOUT)));
  if(error) {
    commit('setErrorHandler', error);
    throw error;
  }
    tokenManager.logout();
  },
  async changeCurrentUserInfo({ commit }, data) {
      commit('ImageUploadModule/setImageIsProcesses', data.imageProcesses, {root: true});
      const [error, response] = await O_o(RequestManager.send(PATCH(USER, !data.imageProcesses
        ? constants.isLoadingStates.getProfileInfo
        : ''),
        data.userChangedData,
      ));
      if(error) {
        throw error;
      }
      commit(SET_CURRENT_USER_INFO, response);
      if (!data.imageProcesses) {
        commit('removeIsLoading', constants.isLoadingStates.getProfileInfo);
      }
      commit('ImageUploadModule/removeImageIsProcesses', data.imageProcesses, {root: true});
  },
  async sendEmail({ commit }, data) {
     commit('setIsLoading', constants.isLoadingStates.resetPasswordProcess);
     const [error, response] = await O_o(RequestManager.send(
       POST(RESET_PASSWORD_REQUEST, data.load ? data.editProfile : ''),
        data.userMail,
      ));

      if(error) {
        commit('removeIsLoading', constants.isLoadingStates.resetPasswordProcess);
        throw error;
      }

      commit('removeIsLoading', constants.isLoadingStates.resetPasswordProcess);

      if (data.editProfile) {
        commit('setIsModalsShown', data.editProfile);
      }
      if (data.load) {
        commit('removeIsLoading', data.editProfile);
      }
  },
  async sendEmailForPasswordReset({ commit }, data) {
      commit('setIsLoading', constants.isLoadingStates.changePasswordProcess);
     const [error, response] = await O_o(RequestManager.send(POST(`${RESET_PASSWORD}?token=${tokenManager.getResetToken()}`),data));

      if(error) {
        commit('setErrorHandler', error);
        commit('removeIsLoading', constants.isLoadingStates.changePasswordProcess);
        throw error;
      }

      tokenManager.removeResetToken();
      commit('removeIsLoading', constants.isLoadingStates.changePasswordProcess);

      if (response && response["destinationUrl"]) {
          window.location.href = response["destinationUrl"];
      } else {
          // todo: use mediator pattern
          routerNavigateTo(SignInRoute.Name);
      }
  },
  async currentUser({ commit }, data) {
      const [error, response] = await O_o(RequestManager.send(GET(USER, constants.isLoadingStates.getProfileInfo)));
    if (error) {
          throw error;
       }
      commit(SET_CURRENT_USER_INFO, response);
      commit('removeIsLoading', constants.isLoadingStates.getProfileInfo);
  },
  async signNda({ commit }, data) {
      const [error, response] = await O_o(RequestManager.send(POST(`${OFFERING_INVITES}/${data.inviteId}${NDA_SIGN}`)));
       if(error) {
         throw error;
       }
       const params = {
         offeringId: data.offeringId
       };
       routerNavigateTo(OfferingSingleRoute.Name, params);
  },
  async signAccreditation({ commit, dispatch }, data) {
      const [error, response] = await O_o(RequestManager.send(POST(ACCREDITATION),data));

      if (error) {
        throw error;
      }

      return response;
  },
};
