export default {
  offeringsList(state) {
    return state.offerings.map(item => {
      return {
        capitalType: item.capitalType,
        commitmentDueDate: item.commitmentDueDate,
        fundsDueDate: item.fundsDueDate,
        id: item.id,
        investmentType: item.investmentType,
        isOfferingActive: item.isOfferingActive,
        isPublished: item.isPublished,
        isTradable: item.isTradable,
        maxInvestmentAmount: item.maxInvestmentAmount,
        minInvestmentAmount: item.minInvestmentAmount,
        totalOfferingAmount: item.totalOfferingAmount,
        vehicle: item.vehicle,
        targetOfferingCloseDate: item.targetOfferingCloseDate,
        taxDocType: item.taxDocType,
      };
    });
  },
  getOfferingVehicleAssets(state) {
    return state.singleOffer.vehicle.project.assets;
  },
  getInvestorsList(state) {
    return state.investorsInviteList.map(item => {
      return {
        email: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        invite: item.invite,
        isAccredited: item.isAccredited,
        isMarked: false,
        id: item.id,
      };
    });
  },
  getPendingPositions(state) {
    return state.pendingPositions.map(item => {
      return {
        id: item.id,
        approvedAmount: item.approvedAmount,
        committedAmount: item.committedAmount,
        docStatus: item.docStatus,
        user: item.user,
        requestedEntity: item.requestedEntity,
        requestedAmount: item.requestedAmount,
        isSelected: false,
      };
    });
  },
};
