export class ValueFormatter<T = string> {
    public mask(value: T): string {
        return this.format(value);
    }

    public unmask(value: string): T {
        return value as any;
    }

    public format(value: T): string {
        return value.toString();
    }

    public areEqual(vale1: T, value2: T) {
        return vale1 === value2;
    }

    public readonly prefix: string = "";
}

export type FormatterClass<T= string> = typeof ValueFormatter & (new () => ValueFormatter<T>);
