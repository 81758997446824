


























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {guidGenerator} from "@/services/helpers/sponsor/randomId";
import {formatDate} from "@/services/helpers/sponsor/date/formatDate";
import BaseDatepicker from './BaseDatepicker.vue';
import {Formatters} from "@/lib/forms/formatters/Formatters";
import {ValueFormatter} from "@/lib/formatters/ValueFormatter";

@Component({
    components: {
        BaseDatepicker
    }
})
export default class FloteDatePicker extends Vue {
    @Prop({default: () => guidGenerator()})
    public id: string;

    @Prop({required: true})
    public value: Date;

    @Prop({default: null})
    public placeholder: string;

    @Prop({default: false})
    public isRequired: boolean;

    @Prop({default: () => Formatters.Date()})
    public formatter!: ValueFormatter;

    @Prop({default: null})
    public errorMessage!: string;

    @Prop({default: false})
    public highlightedAsError!: boolean;

    @Prop({default: null})
    public inputClass: string;

    public isOpen: boolean = false;

    public open() {
        this.isOpen = true;
        this.$emit("open");
    }

    public close() {
        if (this.isOpen) {
            this.isOpen = false;
            this.$emit("close");
        }
    }

    public activeErrorMessage: string = null;

    @Watch("errorMessage", {immediate: true})
    public onErrorMessageChange(newValue, oldValue) {
        this.activeErrorMessage = newValue;
    }

    public get valueProxy() {
        return this.value;
    }

    public set valueProxy(value) {
        if (value !== this.value) {
            this.changed();
        }
        this.$emit("input", value);
    }

    public changed() {
        this.activeErrorMessage = null;
    }

    public get formattedValue() {
        return formatDate(this.value);
    }

    public get isValueSet() {
        return Boolean(this.value);
    }
};
