import ResourceListModule from "@/services/store/lib/ResourceListModule";
import {Document} from "@/services/store/modules/documents/Document";
import {DocumentsApi} from "@/services/api/DocumentsApi";
import {FiltersState} from "@/lib/store/state/filters/FiltersState";
import {DocumentsFilters} from "@/services/store/modules/documents/Filters";
import {ListState, SelectableItem} from "@/services/store/lib/ListState";
import {Action} from "@/lib/store/modules/Action";

export type DocumentsListState = ListState<Document, {}, DocumentsFilters>;

export class DocumentsListModule extends ResourceListModule<Document> {
    APIType!: DocumentsApi;

    public getFilters(): FiltersState {
        return new DocumentsFilters(this.portalApi);
    }

    public getListItem(item: Document): SelectableItem<Document> {
        item = super.getListItem(item);
        item.url = this.api.getDocumentViewUrl(item);
        return item as SelectableItem<Document>;
    }

    @Action.Register()
    public async downloadItems(ids: string[], ctx) {
        this.showGlobalLoader(null, ctx);
        const res = await this.api.downloadMultiple(ids);
        res.save("documents.zip");
        this.hideGlobalLoader(null, ctx);
    }
}
