export default {
  singleOffer: {
    vehicle: {
      project: {
        assets: []
      },
    },
  },
  offerings: [],
  isAmountEntered: false,
  riskFactors: {},
  ndaInfo: {},
};
