import {BaseModule} from "@/lib/store/modules/BaseModule";
import {SubModule} from "@/lib/store/modules/SubModule";
import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {EntitiesListModule} from "./EntitiesListModule";

export class EntitiesModule extends BaseModule {
    @SubModule.Register()
    public list: EntitiesListModule;

    public get api(): FloteEndpointGroup {
        return this.portalApi.entities;
    }
}
