import ResourceListModule from "@/services/store/lib/ResourceListModule";
import {ListState, SelectableItem} from "@/services/store/lib/ListState";
import {Entity} from "./Entity";
import {EntitiesApi} from "@/services/api/EntitiesApi";

export type EntitiesListState = ListState<Entity>;

export class EntitiesListModule extends ResourceListModule<Entity> {
    APIType!: EntitiesApi;
}
