import {api} from "@/services/api/FloteApi";
import {Portal} from "@/lib/Portal";

export class ApiPlugin {
    public install(Vue) {
        Vue.prototype.$api = api;
        Object.defineProperty(Vue.prototype, "$portalApi", {
            get() {
                return Portal.Current.api;
            }
        });
    }
}
