<template>
    <div class="input-container search-container">
        <input
            class="input-box"
            :class="className"
            v-model="inputVal"
            @keydown.enter="executeRequest"
            :placeholder="placeholder"
            type="text"
            :id="id"/>
        <label :for="id">{{ placeholder }}</label>

        <button class="search-button" type="submit" @click="executeRequest">
            <img src="~@/assets/images/search-icon.svg" alt="🔎" class="search-icon"/>
        </button>
    </div>
</template>

<script>
import {guidGenerator} from "@/services/helpers/sponsor/randomId";

export default {
    name: "SearchInput",
    data() {
        return {};
    },
    props: {
        id: {
            type: String,
            default: () => guidGenerator(),
        },
        value: String,
        className: String,
        placeholder: String,
    },
    watch: {
        value: {
            handler: function () {
                this.inputVal = this.value ? this.value : '';
            },
            immediate: true,
        },
        inputVal(newVal, oldVal) {
            if (oldVal !== "" && newVal === "") {
                this.executeRequest();
            }
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            }
        }
    },
    methods: {
        executeRequest() {
            this.$emit("executeRequest");
        }
    }
}
</script>

<style lang="scss" scoped>
.search-container {
    label {
        position: absolute;
        top: calc(50% - 7px);
        left: 0;
        opacity: 0;
        transition: all 0.3s ease;
        padding-left: 15px;
        @include font(10px, 400, SF_Regular)
    }

    input {
        text-indent: 13px;
        height: 45px;
        width: 100%;
        display: flex;
        border: 1px solid $color-grey-semi-dark;
        border-radius: 3px;
        @include font(16px, 400, SF_Light);
        color: $color-black-semi-strong;
        outline: none;
    }

    ::placeholder {
        @include font(16px, 400, SF_Light);
        color: $color-grey-dark;
    }

    input:not(:placeholder-shown) {
        padding: 25px 0 5px 0 !important;
        @include font(16px, 400, SF_Light);
    }

    input:not(:placeholder-shown) + label {
        transform: translateY(-10px);
        opacity: 0.7;
    }


    .input-box {
        padding-right: 40px;
        padding-left: 0;
    }

    .search-button {
        position: absolute;
        bottom: 1px;
        right: 0;
        width: 40px;
        height: 100%;
        border: none;
        background: transparent;
        padding: 7px;
        font-size: 15px;
        color: $color-white-normal;
        cursor: pointer;
    }
}
</style>
