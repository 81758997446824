import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {Affiliate} from "@/core/affiliates/Affiliate";

export class AffiliatesApi extends FloteEndpointGroup {
    public relativeUri: string = "affiliates";

    public toggleNotifications(affiliate: Affiliate) {
        return this.edit(affiliate.id).data({
            notificationsEnabled: !affiliate.notificationsEnabled,
        }).then((res) => {
            affiliate.notificationsEnabled = res.getRawData()["notificationsEnabled"];
            return res;
        });
    }
}
