import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {ProjectEditRoute, ProjectsSingleRoute} from "@/services/router/sponsor/projects";

export class ProjectNavigator extends BasicNavigator {
    public single(id: string) {
        return this.route(ProjectsSingleRoute, {id: id})
    }

    public edit(id: string) {
        return this.route(ProjectEditRoute, {id: id})
    }

}
