import {BaseTokenManager} from "@/lib/api/auth/TokenManager";
import {navigation} from "@/core/navigation/Navigation";

export class InvestorTokenManager extends BaseTokenManager {
    protected keyNamePrefix: string = "investor";

    protected navigateToLoginPage(): void {
        navigation.investor.signIn().navigate();
    }
}

const manager = new InvestorTokenManager()

export default manager;

