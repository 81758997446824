import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {SingleNonTransactionalDocumentRoute} from "@/services/router/sponsor/documents";
import {DocumentSingleNotificationRoute, SingleNotificationRoute} from "@/services/router/sponsor/notifications";
import {TransactionsNavigator} from "@/core/navigation/navigators/TransactionsNavigator";

export class NotificationsNavigator extends BasicNavigator {
    public transactions = new TransactionsNavigator();

    public transactionsNotification(vehicleId: string, transactionType: string, transactionId: string,) {
        return this.route(SingleNotificationRoute, {
            vehicleId: vehicleId,
            transactionType: transactionType,
            transactionId: transactionId,
        });
    }

    public documentsNotification(vehicleId: string, documentGroupId: string) {
        return this.route(DocumentSingleNotificationRoute, {
            vehicleId: vehicleId,
            documentGroupId: documentGroupId,
        })
    }

    public toDocuments(vehicleId: string, documentGroupId: string) {
        return this.route(SingleNonTransactionalDocumentRoute, {
            vehicleId: vehicleId,
            documentGroupId: documentGroupId,
        });
    }

    public back(vehicleId, transactionType?, transactionId?, documentGroupId?) {
        if (documentGroupId) {
            return this.toDocuments(vehicleId, documentGroupId);
        }
        return this.transactions.single(vehicleId, transactionType, transactionId);
    };
}
