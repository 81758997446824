import actions from "./securityActions";
import getters from "./securityGetters";
import state from "./securityState";
import mutations from "./securityMutations";

const security = {
  actions,
  getters,
  state,
  mutations,
};

export default security;
