export default {
  countriesList(state) {
    return state.countries;
  },
  statesList(state) {
    return state.states.map((i) => ({
      value: i.abbreviation,
      text: i.abbreviation,
      disabled: false
    }));
  },
};
