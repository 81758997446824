import {BaseFloteEntity} from "@/services/entities/sponsor/BaseFloteEntity";
import {RepositoryItemState} from "@/services/store/lib/RepositoryState";

export class Asset extends BaseFloteEntity {
    id: string = null;
    title: string = null;
    purchasePrice: string = null;
    purchaseDate: string = null;
    soldPrice: string = null;
    soldDate: string = null;
    status: string = null;
    address: string = null;
    streetAddress: string = null;
    city: string = null;
    state: string = null;
    country: string = null;
    postalCode: string = null;
    assetType: string = null;
    yearBuilt: string = null;
    totalAreaFt: string = null;
    units: number = null;
    images: string[];
    mainPhoto: string = null;
    location: {
        coordinates: number[],
    } = null;
}

export type AssetRepositoryItem = RepositoryItemState<Asset>;
