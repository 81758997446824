export class DatePickerLocale {
    public readonly dow: number = 0; // Sunday is the first day of the week
    public readonly hourTip: string = 'Select Hour'; // tip of select hour
    public readonly minuteTip: string = 'Select Minute'; // tip of select minute
    public readonly secondTip: string = 'Select Second'; // tip of select second
    public readonly monthsHead: string[] = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'); // months of head
    public readonly months: string[] = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'); // months of panel
    public readonly weeks: string[] = 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'); // weeks;
    public readonly cancelTip: string = 'Cancel';
    public readonly submitTip: string = 'Confirm';

    public readonly yearSuffix: string = null;
}

export const defaultDatePickerLocale = new DatePickerLocale();
