export default {
    transactionInitialState: {
        capitalType: null,
        type: null,
        description: '',
        documentGroup: {},
        isPublished: null,
        isNotified: null,
        periodStart: '',
        periodEnd: '',
        effectiveDate: null,
        dueDate: null,
        distributionType: '',
        totalRecordedAmount: '0.00',
        totalCommittedBefore: '0.00',
        isInitialCommitment: false,
        existingBalanceToggleEnabled: false,
        totalProjectCommonEquity: null,
        positionTransactions: [],
        vehicle: {
            project: {},
        },
        capitalCalls: [],
        contributions: [],
        items: [],
    }
}
