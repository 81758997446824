import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {FloteResponse} from "@/lib/api/response/FloteResponse";
import {ResponseField} from "@/lib/api/response/decorators";

class NdaResponse extends FloteResponse {
    @ResponseField()
    public ndaText: string;
}

export class OfferingsNDAApi extends FloteEndpointGroup {

    public getNdaText(id: string) {
        return this.request(this.getFullUrl(id, "nda")).asResponse(NdaResponse);
    }

    public signNda(id: string) {
        return this.post(this.getFullUrl(id, "sign-nda"))
    }
}
