import { render, staticRenderFns } from "./FloteTextArea.vue?vue&type=template&id=6b36af50&scoped=true&"
import script from "./FloteTextArea.vue?vue&type=script&lang=ts&"
export * from "./FloteTextArea.vue?vue&type=script&lang=ts&"
import style0 from "./FloteTextArea.vue?vue&type=style&index=0&id=6b36af50&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b36af50",
  null
  
)

export default component.exports