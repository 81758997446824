import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {NotificationTemplateApi} from "@/services/api/NotificationTemplateApi";

export class DocumentGroupsApi extends FloteEndpointGroup {
    public relativeUri: string = "document-groups";

    public notificationTemplate = new NotificationTemplateApi(this);
}

export class DraftDocumentGroupsApi extends DocumentGroupsApi {
    public relativeUri: string = "draft-document-groups";
}
