import {Router} from "@/lib/router/Router";
import {Route} from "@/lib/router/Route";
import {AdminPanelRoute, PanelRouter} from "@/services/router/flote/panel";

export const FloteRouter = new Router();

@FloteRouter.register()
export class FloteHome extends Route {
    getComponent() {
        return import("@/views/FloteAdmin.vue");
    }

    getPath(): string {
        return "/flote";
    }

    getConfig(): {} {
        return {
            redirect: {name: AdminPanelRoute.Name}
        };
    }
}

FloteHome.addRouter(PanelRouter);

export const DefaultRoute = FloteHome;
