












import {Component, Prop, Vue} from "vue-property-decorator";
import FloteDatePicker from './FloteDatePicker.vue';
import {DateField} from "@/lib/forms/fields/DateField";

@Component({
    components: {
        FloteDatePicker
    }
})
export default class FormDatePicker extends Vue {
    @Prop({required: true})
    public field!: DateField;

    public get errorMessage() {
        return this.field.errorMessages.join("\n");
    }
};
