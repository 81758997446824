import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {NotificationPreview} from "@/services/store/sponsor/modules/notifications/interfaces";
import {
    SET_DOCUMENT_NOTIFICATION_PREVIEW, SET_DOCUMENT_NOTIFICATIONS,
    SET_ERROR_HANDLER,
} from "@/services/store/sponsor/storeConfigs/mutationTypes";
import O_o from "@/services/helpers/sponsor/promiseHandler";
import http from "@/services/helpers/sponsor/managers/requestManager";
import {GET, PATCH, POST} from "@/services/helpers/sponsor/actionMethods";
import {
    DOCUMENT_GROUPS,
    DOCUMENTS,
    NOTIFICATIONS,
    PREVIEW,
} from "@/services/store/sponsor/storeConfigs/actionUrls";
import {NotificationChannel} from "@/services/store/sponsor/modules/notifications/enums";
import constants from "@/config/sponsor/constants";
import {getNotificationStatus} from "@/lib/notifications/NotificationStatus";
import {Document} from "@/services/store/modules/documents/Document";
import {Vehicle} from "@/services/store/modules/vehicles/Vehicle";

export interface DocumentNotification {
    description: string,
    documents: Document[],
    id: string,
    isPublished: boolean,
    isVisible: boolean,
    type: string,
    vehicle: Vehicle
}

@Module({namespaced: true})
class DocumentNotificationModule extends VuexModule {
    public documentNotificationPreview: NotificationPreview = {
        notSendingReasons: [],
        payloads: [],
    }

    public documentNotifications = {
        documents: [],
        capitalType: null,
        isPublished: null,
    };

    @Mutation
    public [SET_DOCUMENT_NOTIFICATION_PREVIEW](data) {
        this.documentNotificationPreview = data;
    }

    @Mutation
    public [SET_DOCUMENT_NOTIFICATIONS](data) {
        this.documentNotifications = data;
    };

    @Action
    public async getDocumentNotifications(data) {
        const [error, response] = await O_o(http.send(GET(`${DOCUMENT_GROUPS}/${data.id}${NOTIFICATIONS}`, constants.isLoadingStates.notificationTable)));
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_DOCUMENT_NOTIFICATIONS, response);
        return response;
    };

    @Action
    public async getDocumentsNotificationPreview(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});
        const [error, response] = await O_o(http.send(GET(`${NOTIFICATIONS}${DOCUMENTS}${PREVIEW}/${data.id}`)));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_DOCUMENT_NOTIFICATION_PREVIEW, response);
        return response;
    }

    @Action
    public async notify(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});

        const [error, response] = await O_o(http.send(POST(`${NOTIFICATIONS}${DOCUMENTS}/send`), {
            triggerIds: data.ids,
            attachDocs: data.attachDocs
        }));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

    }

    @Action
    public async sendTestNotification(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});

        const [error, response] = await O_o(http.send(POST(`${NOTIFICATIONS}${DOCUMENTS}/test/${data.documentId}`), {
            email: data.email,
            attachDocs: data.attachDocs
        }));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

        return response;

    }

    get preview() {
        return this.documentNotificationPreview.payloads.find(i => i.channel === NotificationChannel.Email)
    }

    get documentNotificationList() {
        return this.documentNotifications.documents.map((item) => {
            return {
                id: item.id,
                firstName: item.entity.owner.firstName,
                lastName: item.entity.owner.lastName,
                entityName: item.entity.entityName,
                email: item.entity.owner.email,
                isActive: item.entity.owner.isActive,
                isRegistered: item.entity.owner.isRegistered,
                notificationStatus: getNotificationStatus(item),
                isChecked: false,
                document: item,
            }
        })
    }

}

export default DocumentNotificationModule;
