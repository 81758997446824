import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const AssetsRouter = new Router();

@AssetsRouter.register()
export class AssetCreationRoute extends Route {
    getComponent() {
        return import("@/views/Asset/EditCreateAsset.vue");
    }

    getPath(): string {
        return "assets/create";
    }
}

@AssetsRouter.register()
export class AssetSingleRoute extends Route {
    getComponent() {
        return import("@/views/Asset/AssetSinglePage.vue");
    }

    getPath(): string {
        return "assets/:id";
    }
}

@AssetsRouter.register()
export class AssetEditRoute extends Route {
    getComponent() {
        return import("@/views/Asset/EditCreateAsset.vue");
    }

    getPath(): string {
        return "assets/:id/edit";
    }
}

@AssetsRouter.register()
export class AssetsRoute extends Route {
    getComponent() {
        return import("@/views/Asset/Assets.vue");
    }

    getPath(): string {
        return "assets";
    }
}
