import {BaseTokenManager} from "@/lib/api/auth/TokenManager";
import {BaseEndpointGroup} from "@/lib/api/endpoints/BaseEndpointGroup";
import {UnauthorizedError} from "@/lib/api/errors/errors";
import {FloteRequest} from "@/lib/api/request/FloteRequest";
import {AbstractAuthApi} from "@/lib/api/auth/AbstractAuthApi";
import {TokenExpiredError} from "@/lib/errors";
import {RequestQueue} from "@/lib/api/endpoints/RequestQueue";


export class FloteEndpointGroup extends BaseEndpointGroup {
    protected parent?: FloteEndpointGroup;
    public tokenManager?: BaseTokenManager;
    public auth?: AbstractAuthApi;
    private _queue = new RequestQueue();

    constructor(parent?: FloteEndpointGroup) {
        super(parent);
    }

    public get rootApi(): FloteEndpointGroup {
        if (this.parent) {
            return this.parent.rootApi;
        }
        return this;
    }

    public get queue(): RequestQueue {
        return this.rootApi._queue;
    }

    public get rootTokenManager() {
        if (this.tokenManager) {
            return this.tokenManager;
        }
        if (this.parent) {
            return this.parent.rootTokenManager;
        }
        return null;
    }

    public resolveAuthApi(): AbstractAuthApi {
        if (this.auth) {
            return this.auth;
        }
        if (this.parent) {
            return this.parent.resolveAuthApi();
        }
        return null;
    }

    public getInvalidTokenHandler() {
        return async (request: FloteRequest, error: UnauthorizedError) => {
            if (this.queue.isBlocked) {
                return request.copy().withQueue(this.queue);
            }
            this.queue.block();
            const tokenManager = this.rootTokenManager;
            try {
                const tokens = await this
                    .resolveAuthApi()
                    .refreshTokens(tokenManager.getRefreshToken())
                    .catch((error) => {
                        tokenManager.setNextRoute();
                        tokenManager.logout();
                        return Promise.reject(new TokenExpiredError());
                    });
                tokenManager.setAuthTokens(tokens.accessToken, tokens.refreshToken);
                this.queue.unblock();
                return request.copy();
            } catch (e) {
                this.queue.unblock(e);
                return Promise.reject(e);
            }
        };
    }

    public withAccessToken(url: string) {
        return this.withQuery(url, {
            at: this.rootTokenManager.getAccessToken()
        });
    }

    public request(url: string) {
        return super.request(url)
            .withQueue(this.queue)
            .authenticate(this.rootTokenManager)
            .on(UnauthorizedError, this.getInvalidTokenHandler());
    }

    public unauthorizedRequest(url: string) {
        return super.request(url);
    }
}
