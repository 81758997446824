


















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import FloteTextArea from "../FloteTextArea.vue";
import {FormField} from "@/lib/forms/fields/FormField";

@Component({
    components: {FloteTextArea},
})
export default class FormTextArea extends Vue {
    @Prop({required: true})
    public field!: FormField<any>;

    @Prop({default: null})
    public maxLength!: number;

    public inputValue: string = null;

    @Watch("field.value", {immediate: true})
    public updateValue(value: string) {
        this.inputValue = this.getLimitedValue(value);
    }

    @Watch("inputValue", {immediate: true})
    public updateInputValue(newValue: string) {
        if (this.getLimitedValue(newValue) === this.field.value) {
            this.$nextTick(() => {
                this.inputValue = this.getLimitedValue(newValue);
            });
            return;
        }
        this.field.value = this.getLimitedValue(newValue);
    }

    public get hasLimit() {
        return this.maxLength !== null;
    }

    public get hasReachedTheLimit() {
        return this.valueLength >= this.maxLength;
    }

    public get valueLength() {
        return this.field.value ? this.field.value.length : 0;
    }

    public get errorMessage() {
        return this.field.errorMessages.join("\n");
    }

    public getLimitedValue(value: string) {
        return this.hasLimit && value ? value.slice(0, this.maxLength) : value;
    }
}
