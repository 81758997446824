import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

export class NotificationTemplateApi extends FloteEndpointGroup {
    public uriPostfix: string[] = ["notifications", "template"];
    public get(id: string) {
        return this.request(this.getFullUrl(id, ...this.uriPostfix));
    }

    public edit(id: string) {
        return this.patch(this.getFullUrl(id, ...this.uriPostfix));
    }
}

export class ContributionNotificationTemplateApi extends NotificationTemplateApi {
    public uriPostfix: string[] = ["notifications", "contribution", "template"];
}
