import {TaxIdFormatter} from "@/lib/formatters/TaxIdFormatter.ts";
import {EINFormatter} from "@/lib/formatters/TaxIdFormatter.ts";
import {MoneyFormatter} from "@/lib/formatters/MoneyFormatter.ts";
import {DefaultFormatter} from "@/lib/formatters/DefaultFormatter";
import {SensitiveDataFormatter} from "@/lib/formatters/SensitiveDataFormatter";
import {DateFormatter} from "@/lib/formatters/DateFormatter";
import {TaxIdType} from "@/core/enums/TaxIdType";

export class Formatters {
    public static Default() {
        return new DefaultFormatter();
    }

    public static TaxId(taxIdType: TaxIdType) {
        return new TaxIdFormatter(taxIdType && taxIdType.name);
    }

    public static EIN() {
        return new EINFormatter();
    }

    public static MoneyFormatter() {
        return new MoneyFormatter();
    }

    public static SensitiveDataFormatter() {
        return new SensitiveDataFormatter();
    }

    public static Date() {
        return new DateFormatter();
    }
}
