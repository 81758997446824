import {FloteResponse, ResponseModel} from "@/lib/api/response/FloteResponse";

export class ResponseField {
    public responseClass: ResponseModel;
    public key: string;
    public descriptor?: PropertyDescriptor;

    constructor(responseClass: ResponseModel, key: string, descriptor?: PropertyDescriptor) {
        this.responseClass = responseClass;
        this.key = key;
        this.descriptor = descriptor;
    }

    public copy() {
        return new ResponseField(this.responseClass, this.key, this.descriptor);
    }

    public static Register() {
        return (target: FloteResponse, key?: string, descriptor?: PropertyDescriptor): any => {
            const response = target.constructor as ResponseModel;
            const meta = response.getOrInitMeta();
            const member = new this(response, key, descriptor);
            meta.addField(member);
            return {
                ...descriptor,
                get() {
                    return this.data[key];
                }
            }
        }
    }
}
