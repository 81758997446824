







import loader from '../../sponsor/ui/loaders/DotLoader.vue';
import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {
        loader,
    }
})
export default class SectionLoader extends Vue {

}
