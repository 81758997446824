<template>
    <div class="input-container" :class="{'active-text': inputValue && inputValue.length}">
        <label class="placeholder" :for="id">{{ placeholder }} <span class="required" v-if="isRequired">*</span>
        </label>
        <input
            v-model="inputValue"
            @blur="handleInputBlurState"
            @focus="handleInputState"
            class="input-box"
            :type="type ? type : 'text'"
            :id="id"
            :class="error ? 'error' : ''"
            :disabled="isDisabled"
            @input="maskInput"
        />
        <div class="error-message" v-if="error">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
import BaseTextInput from "@/components/sponsor/ui/baseComponents/BaseTextInput";

export default {
    extends: BaseTextInput,
    props: {
        formatter: Object
    },
    data() {
        return {
            inputValue: null,
        }
    },

    created() {
        this.inputValue = this.formatter.format(this.value);
    },
    methods: {
        maskInput() {
            this.inputValue = this.formatter.mask(this.inputValue)
            this.$emit('input', this.formatter.unmask(this.inputValue));
        }
    },
};
</script>
