// noinspection JSUnfilteredForInLoop
export class FloteEnum {
    public readonly displayValue: string;
    public name: string;

    public get value() {
        return this.name;
    }

    public is(other: FloteEnum) {
        return this.name === other.name;
    }

    public static getDefaultValueText() {
        return "";
    }

    public static getDefault() {
        const value = new FloteEnum(this.getDefaultValueText());
        value.setName(null);
        return value;
    }

    constructor(displayValue: string) {
        this.displayValue = displayValue;
    }

    public setName(name: string) {
        this.name = name;
    }

    public static getByName(name: string): FloteEnum {
        return this[name];
    }

    public static getAll(): FloteEnum[] {
        let res = [];
        for (let key in this) {
            if (this[key] instanceof FloteEnum) {
                res.push(this[key]);
            }
        }
        return res;
    }
}
