import {TransactionFilters} from "@/services/store/modules/vehicles/TransactionFilters";
import {BasicFilter} from "@/lib/store/state/filters/BasicFilter";
import {TransactionTypeFilter} from "@/services/store/lib/filters/TransactionTypeFilter";
import {VehicleTransactionType} from "@/core/enums/TransactionType";

export class VehicleTransactionFilters extends TransactionFilters {
    constructor() {
        super();
        this.vehicleId.isPredefined = true;
    }

    public vehicleId = new BasicFilter("f_vehicle_id");
    public transactionType = new TransactionTypeFilter("f_transaction_type", VehicleTransactionType);
}
