import RequestManager from '../../../../helpers/investor/managers/requestManager';
import {
   SET_OFFERING_DOCUMENTS,
} from '../../config/mutationTypes';
import {
  DOCUMENTS,
} from '../../config/actionUrls';
import O_o from '../../../../helpers/investor/promiseHandler';
import { GET } from '../../config/actionMethods';

export default {
  async getOfferingsDocuments({ commit }, data) {
    const securityId =
      data && data.securityId && data.securityId.length
        ? `f_security_id=${data.securityId}`
        : '';
    const [error, response] = await O_o(
      RequestManager.send(GET(`${DOCUMENTS}?${securityId}`)),
    );
    if (error) {
      throw error;
    }
    commit(SET_OFFERING_DOCUMENTS, response.items);
  },
};
