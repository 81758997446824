import {guidGenerator} from "../../../../helpers/sponsor/randomId";

function sortTransactionByOwnerName(transactions) {
    return transactions.sort((a, b) => a.positionState.entity.owner.firstName.localeCompare(b.positionState.entity.owner.firstName));
}

export default {
    getCapitalCall(state) {
        let transaction = state.transaction;
        if (transaction.capitalCalls) {
            transaction.capitalCalls = transaction.capitalCalls.map(item => {
                let position = {isChecked: false};
                if (!item.positionState) {
                    position.positionState = {};
                }
                position = {...item, ...position};
                return position;
            });
            return sortTransactionByOwnerName(transaction.capitalCalls);
        }
        return [];
    },
    getContribution(state) {
        return sortTransactionByOwnerName(state.transaction.contributions);
    },
    getDistribution(state) {
        for (const [index, item] of state.transaction.positionTransactions.entries()) {
            item["index"] = index;
        }

        return sortTransactionByOwnerName(state.transaction.positionTransactions);
    },
    getSelectedCapitalCallContribution(state) {
        return state.transaction.capitalCalls.filter(item => {
            return item.isChecked === true;
        });
    },
    getContributionModalData(state, getters) {
        return sortTransactionByOwnerName(getters.getSelectedCapitalCallContribution.map(item => {
            return {
                effectiveDate: null,
                receivedDate: null,
                recordedAmount: "0",
                existingBalance: item.existingBalance,
                requestedAmount: item.recordedAmount,
                contributedBeforeInThisCapCall: item.contributedAmount,
                positionState: item.positionState,
            };
        }));
    },
    getCommitmentGroups(state, getters, rootState, rootGetters) {
        const groupsSet = {};

        if (state.transaction.positionTransactions) {
            for (const [index, positionTransaction] of state.transaction.positionTransactions.entries()) {
                const group = positionTransaction.positionState.security;
                if (!groupsSet[group.id]) {
                    groupsSet[group.id] = {
                        name: {value: group.name},
                        id: guidGenerator(),
                        items: [],
                    };
                }
                positionTransaction["index"] = index;
                groupsSet[group.id].items.push(positionTransaction);
                sortTransactionByOwnerName(groupsSet[group.id].items);
            }
        }
        state.transaction.groups = Object.values(groupsSet);
        return state.transaction;
    },
};
