
































import {HIDE_MODALS} from "@/services/store/sponsor/storeConfigs/mutationTypes";
import constants from "../../../../config/sponsor/constants";
import {guidGenerator} from "@/services/helpers/sponsor/randomId";
import {Component, Prop, Vue} from "vue-property-decorator";
import Modal from "../baseComponents/BaseModal.vue";
import BaseMap from "../baseComponents/BaseMap.vue";
import SectionLoader from "../../../ui/loaders/SectionLoader.vue";

@Component({
    components: {
        Modal, BaseMap, SectionLoader
    }
})
export default class MapModal extends Vue {
    public showModal: boolean = true;
    public isMapDataReady: boolean = false;
    public markers = {
        Owned: [],
    };
    public mapZoom: number = 18;
    public maxZoom: number = this.mapZoom;
    public mapTypeControl: boolean = true;
    lng: number = null;
    lat: number = null;

    @Prop({default: () => guidGenerator()})
    public id!: string;

    @Prop({default: []})
    public coordinates;

    created() {
        this.setActiveCoordinates();
    }

    get hasCoordinateNullValues() {
        return this.coordinates[0] === null && this.coordinates[1] === null;
    }

    get isLocationPresent() {
        return this.lng !== null && this.lat !== null;
    }

    public setActiveCoordinates() {
        if (this.coordinates && !this.hasCoordinateNullValues) {
            this.markers.Owned.push({
                id: this.id,
                position: {
                    lng: this.coordinates[0],
                    lat: this.coordinates[1],
                },
            });
        } else {
            this.mapZoom = 10;
            this.markers.Owned.push({
                id: this.id,
                position: {
                    lng: -122.431297,
                    lat: 37.773972,
                },
            });
        }
        this.isMapDataReady = true;
    }

    public getMarkerPosition(e) {
        let {lat, lng} = e.latLng;
        this.lng = lng();
        this.lat = lat();
    }

    public closeModal() {
        this.$store.commit(HIDE_MODALS, constants.modals.mapModal);
    }

    public cancelEdit() {
        this.$store.commit(HIDE_MODALS, constants.modals.mapModal);
    }

    public updateLocation() {
        if (this.isLocationPresent) {
            this.$emit("change", [this.lng, this.lat]);
            this.closeModal();
        }
    }
}
