import store from "../../store";

export function updateVehicleSinglePage(vehicleId) {
    store.dispatch("getVehicleById", vehicleId);
    store.dispatch("getTransactionByVehicleId", {id: vehicleId});
    store.dispatch("getPositions", {f_vehicle_id: vehicleId});
    store.dispatch('getCommonEquityList', {
        vehicleId: vehicleId
    });
    store.dispatch("getStatesList");
}
