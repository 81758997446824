import {Route, RouteClass} from "@/lib/router/Route";
import {RouteConfig} from "vue-router";

export class Router {
    private readonly routes: Route[] = [];

    public register() {
        const router = this;
        return function (Route: RouteClass) {
            router.addRoute(Route.getInstance());
        }
    }

    public addRoute(route: Route) {
        this.routes.push(route);
    }

    public getRoutes() {
        return this.routes;
    }

    public getVueRoutes(): RouteConfig[] {
        return this.routes.map(r => r.toVueRoute());
    }
}
