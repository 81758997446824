import actions from "./etcActions";
import getters from "./etcGetters";
import state from "./etcState";
import mutations from "./etcMutations";

const etc = {
  actions,
  getters,
  state,
  mutations,
};

export default etc;
