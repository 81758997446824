import RequestManager from '../../../../helpers/investor/managers/requestManager';
import {
    SET_LINE_CHART_DATA,
    SET_DOUGHNUT_DATA,
    REMOVE_IS_LOADED,
    SET_IS_LOADED,
} from '../../config/mutationTypes';

import {
    DOUGHNUT_CHART_BY_VEHICLE,
    LINE_CHART_BY_DATE,
} from '../../config/actionUrls';
import O_o from '../../../../helpers/investor/promiseHandler';
import {GET} from '../../config/actionMethods';

export default {
    async getVehicleDoughnutChartData({commit}, data) {
        commit(REMOVE_IS_LOADED, 'DOUGHNUT');
        const [error, response] = await O_o(
            RequestManager.send(GET(DOUGHNUT_CHART_BY_VEHICLE))
        );
        if (error) {
            throw error;
        }

        commit(SET_DOUGHNUT_DATA, response);
        commit(SET_IS_LOADED, 'DOUGHNUT');
    },
    async getVehicleLineChartData({commit}, data) {
        commit(REMOVE_IS_LOADED, 'LINE');

        const [error, response] = await O_o(
            RequestManager.send(GET(LINE_CHART_BY_DATE))
        );
        if (error) {
            throw error;
        }
        commit(SET_LINE_CHART_DATA, response);
        commit(SET_IS_LOADED, 'LINE');
    },
};
