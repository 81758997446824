import {BaseModule} from "@/lib/store/modules/BaseModule";
import {Action} from "@/lib/store/modules/Action";
import {
    REMOVE_IS_REQUEST_PROCESSING,
    SET_IS_REQUEST_PROCESSING
} from "@/services/store/sponsor/storeConfigs/mutationTypes";

export class BasicModule<S = {}> extends BaseModule<S> {

    @Action.Register()
    public showGlobalLoader(p, {commit}) {
        commit(SET_IS_REQUEST_PROCESSING, null, { root:true });
    }

    @Action.Register()
    public hideGlobalLoader(p, {commit}) {
        commit(REMOVE_IS_REQUEST_PROCESSING, null, { root:true });
    }
}
