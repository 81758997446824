import {NotificationChannel} from "@/services/store/sponsor/modules/notifications/enums";

export function getNotificationStatusData(data) {
    return data.latestNotification.statuses.find(i => i.channel === NotificationChannel.Email);
}

export function getNotificationStatus(data) {
    const status = getNotificationStatusData(data).status;

    if (!status) {
        return NotificationStatus.Blank;
    }
    return NotificationStatus.getByName(status);
}

export class NotificationStatus {
    public readonly name: string;
    public readonly className: string;

    constructor(name: string, className: string) {
        this.name = name;
        this.className = className;
    }

    static Sending = new NotificationStatus("Pending", "orange-text");
    static Processed = new NotificationStatus("Processing", "orange-text");
    static Deferred = new NotificationStatus("Deferred", "orange-text");
    static Sent = new NotificationStatus("Sent", "black-text");
    static Delivered = new NotificationStatus("Delivered", "green-text");
    static Opened = new NotificationStatus("Opened", "blue-text");
    static Clicked = new NotificationStatus("Clicked", "blue-text");
    static ErrorSending = new NotificationStatus("Failed", "red-text");
    static Bounced = new NotificationStatus("Bounced", "red-text");
    static Dropped = new NotificationStatus("Dropped", "red-text");
    static ReportedAsSpam = new NotificationStatus("Reported as spam", "red-text");
    static Unsubscribed = new NotificationStatus("Unsubscribed", "red-text");
    static GroupUnsubscribed = new NotificationStatus("Group unsubscribed", "red-text");
    static GroupResubscribed = new NotificationStatus("Group resubscribed", "green-text");
    static Blank = new NotificationStatus("", "");

    public static getByName(name: string): NotificationStatus {
        if (this[name] instanceof NotificationStatus) {
            return this[name];
        }

        return this.Blank;
    }
}
