import {Constructor, Pojo} from '@/lib/types';
import {Metadata} from "@/lib/api/response/meta/ResponseMetadataManager";
import {ResponseMetadata} from "@/lib/api/response/meta/ResponseMetadata";
import {AxiosResponse} from "axios";

export class FloteResponse {
    protected readonly data: Pojo;
    protected readonly headers: Pojo;
    protected readonly status: number;

    constructor(payload: Partial<AxiosResponse>) {
        this.data = payload.data;
        this.headers = payload.headers;
        this.status = payload.status;
    }

    public getRawData() {
        return this.data;
    }

    public static getMetaData(): ResponseMetadata {
        return Metadata.getForModel(this);
    }

    public static initMeta() {
        return Metadata.initForModel(this);
    }

    public static getOrInitMeta() {
        return this.getMetaData() || this.initMeta();
    }
}

export type ResponseModel = typeof FloteResponse & Constructor<FloteResponse>;
