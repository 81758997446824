export function isSubClass(derived, base) {
    while (derived) {
        if (derived === base) {
            return true;
        }

        derived = Object.getPrototypeOf(derived);
    }

    return false;
}
