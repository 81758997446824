import RequestManager from '../../../../helpers/investor/managers/requestManager';
import constants from '../../../../../config/investor/constants';
import { SET_CONTACTS, SET_ENTITIES, SET_OFFERINGS } from '../../config/mutationTypes';
import { ENTITIES, ENTITY_CONTACTS } from '../../config/actionUrls';
import O_o from '../../../../helpers/investor/promiseHandler';
import { openCloseModals } from '../../../../helpers/investor/openCloseModals';
import { GET, PATCH, POST } from '../../config/actionMethods';

export default {
  async entityCreationEmailRequest({ commit }, data) {
    const [error, response] = await O_o(RequestManager.send(
      POST(`${ENTITY_CREATION}`),
      data,
    ));
    if(error){
      throw error;
    }
  },
  async getEntities({ commit }, data) {
      const [error, response] = await O_o(RequestManager.send(
        GET(`${ENTITIES}`, constants.isLoadingStates.getAllEntities), data,
      ));
      if(error) {
        throw error;
      }
      commit(SET_ENTITIES, response.items);
      commit('removeIsLoading', constants.isLoadingStates.getAllEntities);
  },
  async getEntityById({ commit }, data) {
      const [error, response] = await O_o(RequestManager.send(await GET(`${ENTITIES}/${data.entityId}`)));
      if(error) {
        throw error;
      }
      return response;
  },
  async getContactsList({ commit }, data) {
      const [error, response] = await O_o(RequestManager.send(
        GET(ENTITY_CONTACTS,),
        data,
      ));
      if(error){
        throw error;
      }
      commit(SET_CONTACTS, response.items);
  },
  async createEntity({ commit }, data) {
    const [error, response] = await O_o(
      RequestManager.send(POST(ENTITIES),
        data.entityInfo)
    );
    if (error) {
      commit('setErrorHandler', { data: {message: 'Entity with this name already exists.'} });
      throw error;
    }
    let investing = {
      amount: data.investment.amount,
      entityId: response.id,
      inviteId: data.investment.inviteId
    };
    commit('setInvestment', investing);
    commit('setErrorHandler', { data: { message: '' } });
    openCloseModals(data.modals.open, data.modals.close);
  },
  async editEntity({commit}, data) {
    const [error, response] = await O_o(
      RequestManager.send(PATCH(`${ENTITIES}/${data.entityId}`),
        data.entityInfo)
    );
    if (error) {
      commit('setErrorHandler', { data: {message: 'Entity with this name already exists.'} });
      throw error;
    }
    let investing = {
      amount: data.investment.amount,
      entityId: response.id,
      inviteId: data.investment.inviteId
    };
    commit('setErrorHandler', { data: { message: '' } });
    commit('setInvestment', investing);
    if(data.success){
      commit('setSignatoryAdded', true)
    }
    if(data.modals){
      openCloseModals(data.modals.open, data.modals.close);
    }
    return response;
  }
};
