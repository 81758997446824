export class FloteError extends Error {

}

export class NotImplementedError extends FloteError {

}

export class AccessingMetaFromNotWrongModel extends FloteError {

}

export class TokenExpiredError extends FloteError {

}
