<template>
  <div
    :class="wrapperClass"
    class="image-background"
    :style="{ 'background-image': 'url(' + Image + ')' }"></div>
</template>

<script>
import Cloud from './cloudinaryHelper';

export default {
  props: {
    publicId: String,
    height: Number,
    gravity: String,
    crop: String,
    width: Number,
    quality: String,
    radius: Number,
    dpr: String,
    wrapperClass: {
      type: String,
      default: 'image',
    },
  },
  data() {
    return {
      Image: '',
    };
  },
  watch: {
    publicId(value) {
      this.Image = Cloud.Image(
        value,
        this.height,
        this.width,
        this.gravity,
        this.crop,
        this.quality,
        this.dpr,
        this.radius,
      );
    },
  },
  created() {
    this.Image = Cloud.Image(
      this.publicId,
      this.height,
      this.width,
      this.gravity,
      this.crop,
      this.quality,
      this.dpr,
      this.radius
    );
  },
};
</script>
<style lang="scss">
.image-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
