export default {
  countriesListForInvestor(state) {
    const countries = state.countriesList.map(item => ({
      text: item.country,
      value: item.shortCode,
      disabled: false,
    }));
    return [{ text: 'Country', value: null, disabled: true }, ...countries];
  },
  entityStatesList(state) {
    const states = state.entityStatesList.map(item => ({
      text: item.name,
      value: item.shortCode,
      disabled: false,
    }));
    return [{ text: 'State', value: null, disabled: true }, ...states];
  },
  bankStatesList(state) {
    const states = state.bankStatesList.map(item => ({
      text: item.name,
      value: item.shortCode,
      disabled: false,
    }));
    return [{ text: 'State', value: null, disabled: true }, ...states];
  },
  signatoryStatesList(state) {
    const states = state.signatoryStatesList.map(item => ({
      text: item.name,
      value: item.shortCode,
      disabled: false,
    }));
    return [{ text: 'State', value: null, disabled: true }, ...states];
  },
};
