import {Route} from "@/lib/router/Route";
import {Router} from "@/lib/router/Router";

export const PanelRouter = new Router();

@PanelRouter.register()
export class AdminPanelRoute extends Route {
    getComponent() {
        return import("@/views/FloteAdmin.vue");
    }

    getPath(): string {
        return "admin";
    }
}

