import {BasicFilter} from "@/lib/store/state/filters/BasicFilter";

export interface OptionFilterItem {
    text: string;
    value: string|boolean|number;
}

export interface DisplayOptionFilterItem extends OptionFilterItem{
    disabled: boolean;
}

export class OptionFilter extends BasicFilter {
    public options: OptionFilterItem[] = [];
    public defaultValue: string = null;
    public defaultText: string = "";

    constructor(propName: string, options: OptionFilterItem[]) {
        super(propName);
        this.options = options;
    }

    public get displayOptions(): DisplayOptionFilterItem[] {
        return [
            { value: this.defaultValue, text: this.defaultText, disabled: true },
            ...this.options.map(i => ({
                value: i.value,
                text: i.text,
                disabled: false
            }))
        ];
    }
}
