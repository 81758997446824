import {FormField} from "@/lib/forms/fields/FormField";
import {EmptyValidator} from "@/lib/forms/validators/EmptyValidator";
import {DefaultFormatter} from "@/lib/formatters/DefaultFormatter";
import {FloteEnum} from "@/lib/enums/FloteEnum";

export class EnumField extends FormField<string> {
    public validator = new EmptyValidator<string>();
    public formatter = new DefaultFormatter();
    public enumClass: typeof FloteEnum;

    constructor(name: string, enumClass: typeof FloteEnum) {
        super(name, null);
        this.enumClass = enumClass;
    }

    public initValue(value: any) {
        super.initValue(value && value.value);
    }

    public get enumValue() {
        if (this.value && this.enumClass[this.value]) {
            return this.enumClass[this.value];
        }

        return null;
    }
}
