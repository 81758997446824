import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {Document} from "@/services/store/modules/documents/Document";
import {Document as DocumentModel} from "@/core/documents/Document";

export class DocumentsApi extends FloteEndpointGroup {
    public relativeUri: string = "documents";

    public getDocumentViewUrl(document: Document | DocumentModel) {
        return this.withAccessToken(this.getFullUrl(document.id, "file", encodeURIComponent(document.originalFileName)));
    }

    public downloadMultiple(ids: string[]) {
        return this.download(this.getFullUrl("download")).filter({
            ids: ids
        });
    }
}

export class DraftDocumentsApi extends DocumentsApi {
    public relativeUri: string = "draft-documents";
}
