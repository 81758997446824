import http from '../../../../helpers/sponsor/managers/requestManager';
import {POSITIONS} from '../../storeConfigs/actionUrls';
import {
    REMOVE_IS_LOADING,
    SET_COMMON_EQUITY, SET_IS_EDITABLE,
    SET_IS_LOADING,
    SET_NEW_POSITION,
    SET_POSITIONS,
} from "../../storeConfigs/mutationTypes";
import constants from '../../../../../config/sponsor/constants';
import O_o from '../../../../helpers/sponsor/promiseHandler';
import {GET, POST, LIST} from '@/services/helpers/sponsor/actionMethods';
import {CommitmentTransactionRoute} from '@/services/router/sponsor/transactions';
import {routerNavigateTo} from '@/lib/router/Navigator';
import {PositionTransactionType} from "@/core/enums/TransactionType";

export default {
    async getPositions({commit}, query) {
        commit(SET_IS_LOADING, constants.isLoadingStates.commonEquityLoading);
        const [error, response] = await O_o(http.send(LIST(POSITIONS, query)));
        if (error) {
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.commonEquityLoading);
            throw error;
        }
        commit(SET_POSITIONS, response);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.commonEquityLoading);
    },
    async getCommonEquityList({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.commonEquityLoading);
        const [error, response] = await O_o(http.send(GET(`${POSITIONS}?f_vehicle_id=${data.vehicleId}&f_capital_type=CommonEquity`)));
        if (error) {
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.commonEquityLoading);
            throw error;
        }
        commit(SET_COMMON_EQUITY, response);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.commonEquityLoading);
    },
    async parsePositionData({commit, dispatch}, data) {
        const [error, response] = await O_o(http.send(POST(`${POSITIONS}/parse`), data));
        if (error) {
            throw error;
        }
        commit(SET_NEW_POSITION, response);
        return response;
    },
    async reValidatePositionData({commit, dispatch}, data) {
        const [error, response] = await O_o(http.send(POST(`${POSITIONS}/revalidate`), data));
        if (error) {
            throw error;
        }
        commit(SET_NEW_POSITION, response);
        return response;
    },
    async createPosition({commit, dispatch}, data) {
        dispatch('setLoaderForRequests');
        const [error, response] = await O_o(http.send(POST(`${POSITIONS}`), data));
        dispatch('removeLoaderForRequests');
        if (error) {
            throw error;
        }

       commit(SET_IS_EDITABLE, false);
        routerNavigateTo(CommitmentTransactionRoute.Name, {
            vehicleId: data.vehicleId,
            transactionType: PositionTransactionType.Commitment.name,
            transactionId: response.id,
        });
        return response;
    },
};
