function replacerSSN(match, part1, part2, part3, offset, string) {
  return [part1, part2, part3].join('-');
}

function replacerEIN(match, part1, part2, offset, string) {
  return [part1, part2].join('-');
}

export {
  replacerSSN,
  replacerEIN
}
