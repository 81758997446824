import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import constants from "../../../../config/sponsor/constants";
import http from '../../../helpers/sponsor/managers/requestManager';
import {DOCUMENT_GROUPS, DOCUMENTS, DRAFT_DOCUMENT_GROUPS, DRAFT_DOCUMENTS,} from '../storeConfigs/actionUrls';
import O_o from '../../../helpers/sponsor/promiseHandler';
import {getDocumentQuery} from '../../../helpers/sponsor/getDocumentQuery'
import {DELETE, GET, PATCH, POST} from '../../../helpers/sponsor/actionMethods';
import {
    REMOVE_IS_LOADING,
    SET_DOCUMENT_GROUP_DATA,
    SET_DOCUMENT_UPLOAD_TYPE,
    SET_DOCUMENTS_REQUEST,
    SET_ERROR_HANDLER,
    SET_IS_LOADING,
    SET_OFFERING_DOCUMENTS,
    SET_VEHICLE_DOCUMENTS
} from "@/services/store/sponsor/storeConfigs/mutationTypes";
import store from "@/services/store";
import {Document} from "@/services/store/modules/documents/Document";

function sortDocumentsByOwnerName(documents) {
    return documents.sort((a, b) => a.entity.owner.firstName.localeCompare(b.entity.owner.firstName));
}

const documentQuery = (data) => {
    return getDocumentQuery({
        search: data && data.searchQuery,
        vehicleId: data && data.vehicleId,
        vehicleIdArray: data && data.vehicleIds,
        projectId: data && data.selectedProjects && data.selectedProjects.value,
        startDate: data && data.buttonDateOne && data.buttonDateOne,
        endDate: data && data.buttonDateTwo && data.buttonDateTwo,
        groupStartDate: data && data.groupStartDate,
        groupEndDate: data && data.groupEndDate,
        typesArray: data && data.documentTypes,
        types: data && data.types,
        tags: data && data.tags,
        onlyRelatedToVehicle: data && data.relation && (data.relation === "vehicle"),
        onlyRelatedToPosition: data && data.relation && (data.relation === "position"),
        onlyRelatedToProject: data && data.relation && (data.relation === "project"),
        perPage: 1000,
    });
};

export interface DraftGroup {
    id: string,
    isPublished: boolean,
    description: string,
    type: string,
    effectiveDate: string,
    documents: Document[],
    vehicleTransaction: object,
    vehicle: object,
    isMultidocument: boolean,
    isVisible: boolean,
}

export interface DocumentUploadPayload {
    payload: object,
    groupId: string,
    documentId?: string,
}

export interface DocumentGroupSingleDocument {
    effectiveDate: string,
    positionId?: string,
    positionTransactionId?: string,
}

export interface DocumentGroupSavePayload {
    groupId: string,
    payload: {
        save: boolean,
        description: string,
        effectiveDate: string,
        type: string,
        documents: DocumentGroupSingleDocument[],
    }
}

@Module({namespaced: true})
class Documents extends VuexModule {
    public draftGroup: DraftGroup = {
        id: '',
        isPublished: null,
        description: '',
        type: '',
        effectiveDate: '',
        documents: [],
        vehicleTransaction: {},
        vehicle: {},
        isMultidocument: null,
        isVisible: null,
    };
    public offeringDocuments: Document[] = [];
    public vehicleDocuments: Document[] = [];
    public documentsRequestBody: object = {};
    public uploadType: '' = '';

    @Mutation
    public [SET_DOCUMENT_GROUP_DATA](data: DraftGroup) {
        this.draftGroup = data;
    };

    @Mutation
    public [SET_OFFERING_DOCUMENTS](data: Document[]) {
        this.offeringDocuments = data;
    };

    @Mutation [SET_DOCUMENTS_REQUEST](data) {
        this.documentsRequestBody = data;
    };

    @Mutation [SET_DOCUMENT_UPLOAD_TYPE](data) {
        this.uploadType = data;
    };

    @Action
    async createDraftDocumentGroup(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.createDocumentGroup, {root: true});
        const [error, response] = await O_o(http.send(POST(DRAFT_DOCUMENT_GROUPS), data));
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.createDocumentGroup, {root: true});
        if (error) {
            throw error;
        }
        return response;
    }

    @Action
    async getDraftDocumentGroup(groupId) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
        const [error, response] = await O_o(http.send(GET(`${DRAFT_DOCUMENT_GROUPS}/${groupId}`)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
        return response;
    }

    @Action
    async getDocumentGroup(groupId) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
        const [error, response] = await O_o(http.send(GET(`${DOCUMENT_GROUPS}/${groupId}`)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
        return response;
    }

    @Action({rawError: true})
    async uploadDraftGroupDocument(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(POST(`${DRAFT_DOCUMENT_GROUPS}/${data.groupId}/upload`), data.payload));
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        return response;
    }

    @Action({rawError: true})
    async uploadGroupDocument(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(POST(`${DOCUMENT_GROUPS}/${data.groupId}/upload`), data.payload));
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        return response;
    }

    @Action({rawError: true})
    async uploadDraftDocument(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(POST(`${DRAFT_DOCUMENTS}/${data.documentId}/upload`), data.payload));
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        await this.context.dispatch('getDraftDocumentGroup', data.groupId);
        return response;
    }

    @Action({rawError: true})
    async uploadDocument(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(POST(`${DOCUMENTS}/${data.documentId}/upload`), data.payload));
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        await this.context.dispatch('getDocumentGroup', data.groupId);
        return response;
    }

    @Action({rawError: true})
    async deleteDocumentGroup(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(DELETE(`${DOCUMENT_GROUPS}/${data.groupId}`)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        return response;
    }

    @Action({rawError: true})
    async deleteDraftGroupDocument(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(DELETE(`${DRAFT_DOCUMENT_GROUPS}/${data.groupId}/file`)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        return response;
    }

    @Action({rawError: true})
    async deleteGroupDocument(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(DELETE(`${DOCUMENT_GROUPS}/${data.groupId}/file`)));
        if (error) {
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        return response;
    }

    @Action({rawError: true})
    async deleteDraftDocument(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(DELETE(`${DRAFT_DOCUMENTS}/${data.documentId}/file`)));
        if (error) {
            this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        return response;
    }

    @Action({rawError: true})
    async deleteDocument(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        const [error, response] = await O_o(http.send(DELETE(`${DOCUMENTS}/${data.documentId}/file`)));
        if (error) {
            this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
            throw error;
        }
        this.context.commit(SET_DOCUMENT_GROUP_DATA, response);
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.documentsUpload, {root: true});
        return response;
    }

    @Action({rawError: true})
    async saveDraftDocumentGroup(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
        const [error, response] = await O_o(http.send(PATCH(`${DRAFT_DOCUMENT_GROUPS}/${data.groupId}`), data.payload));
        if (error) {
            this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
            throw error;
        }
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
        return response;
    }

    @Action({rawError: true})
    async saveDocumentGroup(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
        store.dispatch('setLoaderForRequests')
        const [error, response] = await O_o(http.send(PATCH(`${DOCUMENT_GROUPS}/${data.groupId}`), data.payload));
        store.dispatch('removeLoaderForRequests')
        if (error) {
            this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
            throw error;
        }
        this.context.commit(REMOVE_IS_LOADING, constants.isLoadingStates.draftGroup, {root: true});
        return response;
    }

    @Mutation
    public [SET_VEHICLE_DOCUMENTS](data: Document[]) {
        this.vehicleDocuments = data;
    };

    @Action
    async getAllDocumentGroups(data) {
        this.context.commit(SET_IS_LOADING, constants.isLoadingStates.documentsTable, {root: true});
        try {
            const documents = await http.send({
                url: `${DOCUMENT_GROUPS}${documentQuery(data)}`,
                method: "GET",
                loading: data.loadingState,
            });
            this.context.commit(`set${data.name}`, documents.items);
            this.context.commit(REMOVE_IS_LOADING, data.documentsTable, {root: true});
        } catch (error) {
            this.context.commit(REMOVE_IS_LOADING, data.documentsTable, {root: true});
        }
    };

    @Action
    async getOfferingsDocuments(data) {
        const securityId =
            data && data.securityId && data.securityId.length
                ? `f_security_id=${data.securityId}`
                : "";
        const [error, response] = await O_o(
            http.send({
                url: `${DOCUMENTS}?${securityId}`,
                method: "GET",
            })
        );
        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        this.context.commit(SET_OFFERING_DOCUMENTS, response.items);
    };

    get sortedDocumentGroupDocs() {
        return this.draftGroup.documents ? sortDocumentsByOwnerName(this.draftGroup.documents) : {};
    };
}

export default Documents;
