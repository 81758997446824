import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

export class NotificationsApi extends FloteEndpointGroup {
    relativeUri: string = "notifications";

    public offerings(offeringId: string) {
        return new OfferingNotificationsApi(this, offeringId);
    }
}

export class BaseNotificationsApi extends FloteEndpointGroup {
    public send(triggerIds: string[]) {
        return this.post(this.getFullUrl("send")).data({
            triggerIds: triggerIds,
        })
    }

    public sendTest(triggerId: string, email: string) {
        return this.post(this.getFullUrl("test", triggerId)).data({
            email: email,
        });
    }

    public getPreview(triggerId: string) {
        return this.request(this.getFullUrl("preview", triggerId)).method("get");
    }
}


export class OfferingNotificationsApi extends BaseNotificationsApi {
    public relativeUri: string = "offering-invites";
    constructor(parent: FloteEndpointGroup, offeringId: string) {
        super(parent);
        this.relativeUri = `offering-invites/${offeringId}`;
    }
}
