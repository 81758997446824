import {RequestInterceptor} from "@/lib/api/request/RequestInterceptor";
import {AxiosResponse} from "axios";
import {BaseTokenManager} from "@/lib/api/auth/TokenManager";

export class RenewTokenInterceptor extends RequestInterceptor {
    public readonly tokenManager: BaseTokenManager;
    constructor(tokenManager: BaseTokenManager) {
        super();
        this.tokenManager = tokenManager;
    }
    public onResponse(response: AxiosResponse) {
        if (response.headers) {
            if (response.headers['x-renew-access-token']) {
                this.tokenManager.setAccessToken(response.headers['x-renew-access-token']);
            }
            if (response.headers['x-renew-refresh-token']) {
                this.tokenManager.setRefreshToken(response.headers['x-renew-refresh-token']);
            }
        }
        return response;
    }
}
