export function GET(url: string, loading?) {
    return {
        url,
        method: 'GET',
        ...(loading ? {loading} : {}),
    };
}

export function LIST(url: string, query: object, loading?) {
    return {
        url,
        query,
        method: 'GET',
        ...(loading ? {loading} : {}),
    };
}

export function POST(url: string, loading?) {
    return {
        url,
        method: 'POST',
        ...(loading ? {loading} : {}),
    };
}

export function PATCH(url: string, loading?) {
    return {
        url,
        method: 'PATCH',
        ...(loading ? {loading} : {}),
    };
}

export function DELETE(url: string, loading?) {
    return {
        url,
        method: 'DELETE',
        ...(loading ? {loading} : {}),
    };
}

export function PUT(url: string, loading?) {
    return {
        url,
        method: 'PUT',
        ...(loading ? {loading} : {}),
    };
}
