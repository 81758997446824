import http from '../../../../helpers/sponsor/managers/requestManager';
import {
    SET_TRANSACTION,
    SET_TRANSACTION_LIST,
    SET_ERROR_HANDLER,
    SET_IS_LOADING,
    REMOVE_IS_LOADING,
    HIDE_MODALS,
    SET_DELETING_CONTRIBUTION_ID,
    SET_EDITING_CONTRIBUTION_ID,
} from '../../storeConfigs/mutationTypes';
import {DELETE, GET, PATCH, POST} from '../../../../helpers/sponsor/actionMethods';
import O_o from '../../../../helpers/sponsor/promiseHandler';
import {
    CAPITAL_CALLS,
    COMMITMENTS,
    CONTRIBUTIONS,
    DISTRIBUTIONS,
    POSITIONS,
    POSITIONS_AVERAGED,
    VEHICLE_TRANSACTIONS
} from '../../storeConfigs/actionUrls';
import router from '../../../../router/router';
import constants from '../../../../../config/sponsor/constants';
import {
    CapitalCallTransactionRoute,
    CommitmentTransactionRoute,
    ContributionTransactionRoute,
    DistributionTransactionRoute
} from '../../../../router/sponsor/transactions';
import {SingleVehicleRoute} from '../../../../router/sponsor/vehicles';
import {routerNavigateTo} from '../../../../../lib/router/Navigator';
import {PositionTransactionType} from "@/core/enums/TransactionType";

export default {
    async getCashTransaction({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        const [error, response] = await O_o(http.send(GET(`${POSITIONS_AVERAGED}?f_vehicle_id=${data.vehicleId}&f_capital_type=${data.capitalType}&f_period_start=${data.periodStart}&f_period_end=${data.periodEnd}`)));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        commit(SET_TRANSACTION_LIST, response);
        return response;
    },
    async getCapitalTransaction({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        const [error, response] = await O_o(http.send(GET(`${POSITIONS}?f_vehicle_id=${data.vehicleId}&f_capital_type=${data.capitalType}&f_as_of=${data.asOfDate}`)));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        if (data.isCapitalCall) {
            response.isCapitalCall = data.isCapitalCall;
        }
        if (data.isContribution) {
            response.isContribution = data.isContribution;
        }
        commit(SET_TRANSACTION_LIST, response);
        return response;
    },
    async transactionDelete({commit}, data) {
        const [error, response] = await O_o(http.send(DELETE(`${VEHICLE_TRANSACTIONS}/${data.transactionId}`)));
        if (error) {
            let err = {...error};
            err.data.type = 'Delete';
            commit(SET_ERROR_HANDLER, err);
            commit(HIDE_MODALS, constants.modals.deleteTransaction);
            throw err;
        }
        commit(HIDE_MODALS, constants.modals.deleteTransaction);
        await router.push({
            name: SingleVehicleRoute.Name,
            params: {
                vehicleId: data.vehicleId,
            },
        });
    },
    async transactionPublish({commit, dispatch}, data) {
        commit(HIDE_MODALS, constants.modals.publishTransaction);
        dispatch('setLoaderForRequests');
        const [error, response] = await O_o(http.send(POST(`${VEHICLE_TRANSACTIONS}/${data.transactionId}/publish`)));
        dispatch('removeLoaderForRequests');
        if (error) {
            let err = {...error};
            err.data.type = 'Publish';
            commit(SET_ERROR_HANDLER, err);
            throw err;
        }
        commit(HIDE_MODALS, constants.modals.publishTransaction);
        return response;
    },
    async transactionUnPublish({commit, dispatch}, data) {
        dispatch('setLoaderForRequests');
        const [error, response] = await O_o(http.send(POST(`${VEHICLE_TRANSACTIONS}/${data.transactionId}/unpublish`)));
        dispatch('removeLoaderForRequests');
        if (error) {
            let err = {...error};
            err.data.type = 'Unpublish';
            commit(SET_ERROR_HANDLER, err);
            throw err;
        }
        return response;
    },

    async createDistribution({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(POST(`${DISTRIBUTIONS}`), data.payload));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        routerNavigateTo(DistributionTransactionRoute.Name, {
            vehicleId: data.payload.vehicleId,
            transactionType: PositionTransactionType.Distribution.name,
            transactionId: response.id,
        });
    },
    async getDistribution({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(GET(`${DISTRIBUTIONS}/${data.transactionId}`)));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        commit(SET_TRANSACTION, response);
        return response;
    },
    async downloadDistributionCSV({commit}, data) {
        const [error, response] = await O_o(
            http.send({
                url: `${DISTRIBUTIONS}/${data.distributionId}/download`,
                method: "GET",
                responseType: "arraybuffer",
            })
        );
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            throw error;
        }
        const url = window.URL.createObjectURL(
            new Blob([response.data], {type: "application/csv"})
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${data.fileName}.csv`);
        //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    },
    async editDistribution({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(PATCH(`${DISTRIBUTIONS}/${data.transactionId}`), data.payload));
        if (error) {
            let err = {...error};
            error.data.type = 'Edit';
            commit(SET_ERROR_HANDLER, err);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw err;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
    },

    async createCommitment({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(POST(`${COMMITMENTS}`), data.payload));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        routerNavigateTo(CommitmentTransactionRoute.Name, {
            vehicleId: data.payload.vehicleId,
            transactionType: PositionTransactionType.Commitment.name,
            transactionId: response.id,
        });
    },
    async getCommitment({commit, rootState}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(GET(`${COMMITMENTS}/${data.transactionId}`)));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        commit(SET_TRANSACTION, {
            ...response,
        });
        return response;
    },
    async editCommitment({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(PATCH(`${COMMITMENTS}/${data.transactionId}`), data.payload));
        if (error) {
            let err = {...error};
            err.data.type = 'Edit';
            commit(SET_ERROR_HANDLER, err);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw err;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
    },
    async deleteCommitment({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(DELETE(`${COMMITMENTS}/${data.transactionId}`)));
        if (error) {
            let err = {...error};
            err.data.type = 'Delete';
            commit(SET_ERROR_HANDLER, err);
            commit(HIDE_MODALS, constants.modals.deleteTransaction);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw err;
        }
        commit(HIDE_MODALS, constants.modals.deleteTransaction);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        await router.push({
            name: SingleVehicleRoute.Name,
            params: {
                vehicleId: data.vehicleId,
            },
        });
    },

    async createCapitalCall({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(POST(`${CAPITAL_CALLS}`), data.payload));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        routerNavigateTo(CapitalCallTransactionRoute.Name, {
            vehicleId: data.payload.vehicleId,
            transactionType: PositionTransactionType.CapitalCall.name,
            transactionId: response.id,
        });
    },
    async getCapitalCall({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(GET(`${CAPITAL_CALLS}/${data.transactionId}`)));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        commit(SET_TRANSACTION, response);
        return response;
    },
    async editCapitalCall({commit, dispatch}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(PATCH(`${CAPITAL_CALLS}/${data.transactionId}`), data.payload));
        if (error) {
            let err = {...error};
            err.data.type = 'Edit';
            commit(SET_ERROR_HANDLER, err);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw err;
        }
        commit(SET_TRANSACTION, response);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
    },

    async createContribution({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(POST(`${CAPITAL_CALLS}/${data.transactionId}${CONTRIBUTIONS}`), data.payload));
        if (error) {
            commit(SET_ERROR_HANDLER, error);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw error;
        }
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        commit(SET_TRANSACTION, response);
        routerNavigateTo(ContributionTransactionRoute.Name, {
            vehicleId: data.payload.vehicleId,
            transactionType: PositionTransactionType.CapitalCall.name,
            transactionId: response.id,
        });
    },
    async editContribution({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(PATCH(`${CAPITAL_CALLS}/${data.transactionId}${CONTRIBUTIONS}`), data.payload));
        if (error) {
            let err = {...error};
            err.data.type = 'Edit';
            commit(SET_ERROR_HANDLER, err);
            commit(SET_EDITING_CONTRIBUTION_ID, null);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw err;
        }
        commit(SET_EDITING_CONTRIBUTION_ID, null);
        commit(SET_TRANSACTION, response);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
    },
    async deleteContributionRow({commit}, data) {
        commit(SET_IS_LOADING, constants.isLoadingStates.transactionTableLoading);

        const [error, response] = await O_o(http.send(DELETE(`${CAPITAL_CALLS}/${data.transactionId}${CONTRIBUTIONS}/${data.positionId}`)));
        if (error) {
            let err = {...error};
            err.data.type = 'Delete';
            commit(SET_ERROR_HANDLER, err);
            commit(SET_DELETING_CONTRIBUTION_ID, null);
            commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
            throw err;
        }
        commit(SET_DELETING_CONTRIBUTION_ID, null);
        commit(SET_TRANSACTION, response);
        commit(REMOVE_IS_LOADING, constants.isLoadingStates.transactionTableLoading);
        return response;
    },
};
