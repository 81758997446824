import cloudinary from "cloudinary-core";
import {config} from "@/lib/config";

class CloudinaryVue {
    constructor() {
        this.cloudinary = new cloudinary.Cloudinary({
            cloud_name: config.CLOUDINARY_CLOUD_NAME,
            secure: true,
        });
        this.cloudinary.responsive();
    }

    Image(publicId, height, width, gravity, crop, quality, dpr, radius) {
        return this.cloudinary
            .imageTag(publicId)
            .transformation()
            .gravity(gravity)
            .quality(quality)
            .width("auto")
            .dpr(dpr ? dpr : "auto")
            .crop("fill")
            .radius(radius ? radius : 0)
            .toHtml();
    }
}

export default new CloudinaryVue();
