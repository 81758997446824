import {
  SET_SINGLE_OFFER,
  SET_OFFERINGS,
  SET_RISK_FACTORS,
  SET_IS_AMOUNT_ENTERED, SET_NDA,
} from '../../config/mutationTypes';

export default {
  [SET_OFFERINGS](state, data) {
    state.offerings = data;
  },
  [SET_SINGLE_OFFER](state, data) {
    state.singleOffer = data;
  },
  [SET_IS_AMOUNT_ENTERED](state, data) {
    state.isAmountEntered = data;
  },
  [SET_RISK_FACTORS](state, data) {
    state.riskFactors = data;
  },
  [SET_NDA](state, data) {
    state.ndaInfo = data;
  },
};
