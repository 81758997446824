import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";
import {formatPhoneNumber} from "@/services/helpers/sponsor/filters/phoneNumberFormatter";
import {DefaultFormatter} from "@/lib/formatters/DefaultFormatter.ts";
import {config} from "@/lib/config";

export class PhoneNumberFormatter extends DefaultFormatter {
    private asYouType = new AsYouType(config.DEFAULT_COUNTRY);
    public indexOfCloseScope = null;

    public mask(value: string): string {
        const formatted = super.mask(value);
        let valueOnType: string = formatted;

        this.asYouType.reset();
        if (valueOnType.length > this.indexOfCloseScope) {
            valueOnType = this.asYouType.input(formatted);
        }
        this.indexOfCloseScope = valueOnType.indexOf(")");

        return valueOnType;
    };

    public format(value: string): string {
        return formatPhoneNumber(super.format(value));
    }

    public unmask(value): string {
        value = value.replace(/[^\d+]/, "");
        const parsed = parsePhoneNumberFromString(value, config.DEFAULT_COUNTRY);
        if (parsed) {
            value = parsed.formatInternational();
            value = parsed.number;
        }
        return super.unmask(value);
    };
}
