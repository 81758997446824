import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {TokensResponse} from "@/lib/api/auth/TokensResponse";
import {AbstractAuthApi} from "@/lib/api/auth/AbstractAuthApi";

export class AuthApi extends FloteEndpointGroup implements AbstractAuthApi {
    public relativeUri: string = "auth";

    public refreshTokens(refreshToken: string) {
        return this.post(this.getFullUrl("refresh-token")).data({
            refreshToken
        }).asResponse(TokensResponse);
    }

    public confirmResetPassword(password: string) {
        return this.post(this.getFullUrl("reset-password"))
            .query({
                token: this.rootTokenManager.getResetToken(),
            }).data({
                password: password
            })
    }

    public requestResetPassword(email: string) {
        return this.post(this.getFullUrl("request-reset-password"))
            .data({
                email: email
            });
    }

    public request(url: string) {
        return super.unauthorizedRequest(url);
    }
}
