import {OptionFilter} from "@/lib/store/state/filters/OptionFilter";
import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

export abstract class LoadableFilter<T> extends OptionFilter {
    public api: FloteEndpointGroup;
    public abstract displayFieldName: keyof T;

    constructor(propName: string, api: FloteEndpointGroup) {
        super(propName, []);
        this.api = api;
    }

    public async loadOptions() {
        const data = await this.api.list();
        this.options = data.items.map(i => ({
            text: i[this.displayFieldName],
            value: i.id,
        }));
    }
}
