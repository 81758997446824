import {FloteEnum} from "@/lib/enums/FloteEnum";
import {Enum} from "@/core/enums/Enum";

export class TransactionType extends FloteEnum {
}

export class VehicleTransactionType extends TransactionType {
    @Enum()
    public static Commitment = new TransactionType("Commitment");
    @Enum()
    public static Distribution = new TransactionType("Distribution");
    @Enum()
    public static CapitalCall = new TransactionType("Capital Call");
    @Enum()
    public static Transfer = new TransactionType("Transfer");
}

export class PositionTransactionType extends VehicleTransactionType {
    @Enum()
    public static Contribution = new TransactionType("Contribution");
}
