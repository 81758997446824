import O_o from '../../../../helpers/investor/promiseHandler';
import { OFFERINGS, OFFERING_INVITES, NDA } from '../../config/actionUrls';
import { SET_OFFERINGS, SET_SINGLE_OFFER, SET_RISK_FACTORS, SET_NDA } from '../../config/mutationTypes';
import RequestManager from '../../../../helpers/investor/managers/requestManager';
import { openCloseModals } from '../../../../helpers/investor/openCloseModals';
import { GET, POST } from '../../config/actionMethods';

export default {
  async getInvestorOffers({ commit }, data) {
    const [error, response] = await O_o(
      RequestManager.send(GET(OFFERINGS))
    );
    if (error) {
      throw error;
    }
    commit(SET_OFFERINGS, response.items);
  },
  async getNda({ commit }, data) {
    const [error, response] = await O_o(RequestManager.send(GET(`${OFFERINGS}/${data.offeringId}${NDA}`)));
    if (error) {
      throw error;
    }
    commit(SET_NDA, response);
  },
  async getInvestorSingleOffer({ commit }, data) {
    const [error, response] = await O_o(
        RequestManager.send(GET(`${OFFERINGS}/${data}`))
    );
    if (error) {
      throw error;
    }
    commit(SET_SINGLE_OFFER, response);
    return response;
  },
  async sendInvest({ commit, dispatch }, data) {
    const [error, response] = await O_o(
      RequestManager.send(POST(`${OFFERING_INVITES}/${data.id}/request-investment`),
        data.sentData)
    );
    if (error) {
      throw error;
    }
    openCloseModals(data.modals.open, data.modals.close);
  },
  async getRiskFactors({commit, dispatch}, data) {
    const  [error, response] = await O_o(RequestManager.send(GET(`${OFFERINGS}/${data}/risk-factors`)));
    if(error) {
      throw error;
    }
    commit(SET_RISK_FACTORS, response);
  }
};
