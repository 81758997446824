import {BasicModule} from "@/lib/store/modules/BasicModule";
import {FlotePortalApi} from "@/services/api/PortalApi";
import {AuthModuleState} from "@/services/store/modules/auth/AuthModuleState";
import {Pojo} from "@/lib/types";
import {initEntity} from "@/services/entities/sponsor/BaseFloteEntity";
import {User} from "@/services/store/modules/auth/User";
import {Action} from "@/lib/store/modules/Action";
import {Mutation} from "@/lib/store/modules/Mutation";

export class AuthModule extends BasicModule<AuthModuleState> {
    APIType: FlotePortalApi;

    public get api(): this["APIType"] {
        return this.portalApi;
    }

    public state: AuthModuleState = new AuthModuleState();

    @Mutation.Register()
    public setCurrentUser(data: Pojo) {
        this.state.currentUser.data = initEntity(User, data);
    }

    @Action.Register()
    public async loadCurrentUser() {
        this.state.currentUser.loader.startTask();
        const data = await this.api.user.current();
        this.setCurrentUser(data.getRawData());
        this.state.currentUser.loader.completeTask();
    }

    @Action.Register()
    public async confirmResetPassword(p, ctx) {
        this.showGlobalLoader(p, ctx);
        await this.api.auth.confirmResetPassword();
        this.hideGlobalLoader(p, ctx);
    }

    @Action.Register()
    public async requestResetPassword({email}: { email: string }, ctx) {
        this.showGlobalLoader(null, ctx);
        await this.api.auth.requestResetPassword(email);
        this.hideGlobalLoader(null, ctx);
    }
}
