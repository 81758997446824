<template>
    <div class="investor-root-floating-label">
        <input
            v-model="displayValue"
            @blur="handleInputBlurState"
            @focus="handleInputState"
            :placeholder="placeholder"
            :type="inputType"
            :id="id"
        />
        <label :for="id">{{ placeholder }}</label>
        <div class="toggle"
             v-if="isToggleVisible"
             @click="toggleInputTextVisibility"
        >
            {{ showInputText ? 'HIDE' : 'SHOW' }}
        </div>
    </div>
</template>

<script>
import masks from '../../../../services/helpers/investor/formatter/BaseInputHelper';
import {guidGenerator} from "@/services/helpers/sponsor/randomId";

export default {
    props: {
        value: String,
        placeholder: String,
        type: {type: String, default: 'text'},
        maskType: String,
        blurEffect: Function,
        focusEffect: Function,
        isToggleVisible: Boolean,
        id: {
            type: String,
            default: () => guidGenerator(),
        },
    },
    data() {
        return {
            inputFocused: false,
            showInputText: false,
            inputType: 'password',
        };
    },
    computed: {
        displayValue: {
            get() {
                if (this.inputFocused) {
                    return this.value;
                }
                if (this.maskType) {
                    return this.mask(this.value);
                } else {
                    return this.value;
                }
            },
            set(value) {
                if (this.maskType) {
                    this.$emit('input', this.unmask(value))
                } else {
                    this.$emit('input', value);
                }
            },
        },
    },
    mounted() {
        this.inputType = this.type;
    },
    methods: {
        initialType() {
            this.inputType = this.inputType === "password" ? "text" : "password";
        },
        handleInputState(event) {
            if (this.focusEffect) {
                this.focusEffect();
            }
            this.inputFocused = event.type === 'focus';
        },
        handleInputBlurState(event) {
            if (this.blurEffect) {
                this.blurEffect();
            }
            this.inputFocused = event.type === 'focus';
        },
        unmask(value) {
            return masks[this.maskType].unmask(value);
        },
        mask(value) {
            return masks[this.maskType].mask(value);
        },
        toggleInputTextVisibility() {
            this.showInputText = !this.showInputText;
            this.initialType();
        },
    },
};
</script>

<style scoped lang="scss">

.investor-root-floating-label {
    position: relative;
    margin-bottom: 10px;
    width: 100%;

    label {
        position: absolute;
        top: calc(50% - 7px);
        left: 0;
        opacity: 0;
        transition: all 0.3s ease;
        padding-left: 15px;
        font-size: 10px;
        font-family: 'SF_Regular', sans-serif;
    }

    input {
        text-indent: 13px;
        height: 45px;
        width: 100%;
        display: flex;
        border: 1px solid $color-grey-semi-dark;
        border-radius: 3px;
        @include font(16px, 400, SF_Light);
        font-family: SF_Light, sans-serif;
        color: #2a363d;
        outline: none;
    }

    ::placeholder {
        @include font(16px, 400, SF_Light);
        font-family: SF_Light, sans-serif;
        color: #777777;
    }

    input:not(:placeholder-shown) {
        padding: 25px 0 5px 0 !important;
        @include font(16px, 400, SF_Light);
        font-family: SF_Light, sans-serif;
    }

    input:not(:placeholder-shown) + label {
        transform: translateY(-10px);
        opacity: 0.7;
    }

    .toggle {
        @include font(10px, 300, SF_Light);
        font-family: SF_Light, sans-serif;
        color: $color-black-semi-strong;
        position: absolute;
        top: 16px;
        right: 15px;
        cursor: pointer;
    }
}
</style>
