import actions from './positionActions';
import getters from './positionGetters';
import state from './positionState';
import mutations from './positionMutations';

const investorPosition = {
  actions,
  getters,
  state,
  mutations,
};

export default investorPosition;
