import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";
import {ConfigResponse} from "@/services/api/response/ConfigResponse";
import {Cached} from "@/lib/api/other/Cached";

export class ConfigApi extends FloteEndpointGroup {
    public relativeUri: string = "etc/config";

    @Cached()
    public get() {
        return super.get("").asResponse(ConfigResponse);
    }
}
