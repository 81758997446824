export default {
    errors: {
        internalServerError: 'INTERNAL_SERVER_ERROR',
        unexpected: 'UNEXPECTED',
        wrongListOfTransactionItems: 'WRONG_LIST_OF_VEHICLE_TRANSACTION_ITEMS',
        publishConflict: 'VEHICLE_TRANSACTION_PUBLISH_CONFLICT',
        uniqueConstraint: 'UNIQUE_CONSTRAINT_ERROR',
        notFound: 'NOT_FOUND',
        invalidInput: 'INVALID_INPUT',
        fileMissingFromDocument: 'FILE_MISSING_FROM_DOCUMENT',
        wrongCredentials: 'WRONG_CREDENTIALS',
    },
    isLoadingStates: {
        imageProcesses: "IMAGE_PROCESSES",
        galleryImagesProcesses: "GALLERY_IMAGES_PROCESSES",
        // TODO this state is used wrong change to asset
        portfolio: "PORTFOLIO_SECTION",
        getVehicleById: "GET_VEHICLE_BY_ID",
        getAssetById: "GET_ASSET_BY_ID",
        deleteAsset: "DELETE_ASSET",
        getUsersList: "GET_USERS_LIST",
        getUserSingle: "GET_USER_SINGLE",
        editUser: "EDIT_USER",
        getEntitiesList: "GET_ENTITIES_LIST",
        getAllVehicles: "GET_ALL_VEHICLES",
        getAllProjects: "GET_ALL_PROJECTS",
        documentsTable: "DOCUMENTS_TABLE",
        isDocumentUploading: "IS_DOCUMENT_UPLOADING",
        vehicleDocuments: "VEHICLE_DOCUMENTS",
        signInProcess: 'SIGN_IN_PROCESS',
        commonEquityLoading: 'COMMON_EQUITY_LOADING',
        getSingleOffer: 'GET_SINGLE_OFFER',
        transactionTableLoading: 'TRANSACTION_TABLE_LOADING',
        transactionHistoryTable: 'TRANSACTION_HISTORY_TABLE',
        documentsUpload: 'DOCUMENTS_UPLOAD',
        createDocumentGroup: 'CREATE_DOCUMENT_GROUP',
        draftGroup: 'DRAFT_GROUP',
        notificationTable: 'NOTIFICATION_TABLE',
        assetSinglePageMap: 'ASSET_SINGLE_PAGE_MAP',
        projectPageMap: 'PROJECT_PAGE_MAP',
    },
    modals: {
        infoModal: "INFO_MODAL",
        deleteVehicle: "DELETE_VEHICLE",
        deletionWarning: "DELETION_WARNING",
        documentsUploadModal: "DOCUMENTS_UPLOAD_MODAL",
        generalErrorOccurred: "GENERAL_ERROR_OCCURRED",
        transferPosition: "TRANSFER_POSITION",
        offeringRequestModal: "OFFERING_REQUEST_MODAL",
        commitmentAmountApproval: "COMMITMENT_AMOUNT_APPROVAL",
        galleryModal: 'GALLERY_MODAL',
        editMinMaxAmount: 'EDIT_MIN_MAX_AMOUNT',
        sentInvitationModal: 'SENT-INVITATION-MODAL',
        deleteDocuments: 'DELETE_DOCUMENTS',
        uploadDocument: 'UPLOAD_DOCUMENT',
        deleteTransaction: 'DELETE_TRANSACTION',
        cancelTransaction: 'CANCEL_TRANSACTION',
        saveTransaction: 'SAVE_TRANSACTION',
        publishTransaction: 'PUBLISH_TRANSACTION',
        documentInfo: 'DOCUMENT_INFO',
        documentUpload: 'DOCUMENT_UPLOAD',
        contribution: 'CONTRIBUTION',
        createPositionsModal: 'CREATE_POSITIONS_MODAL',
        entitiesListSwitcherModal: 'ENTITIES_LIST_SWITCHER_MODAL',
        mapModal: "MAP_MODAL",
        vehiclePublishModal: 'VEHICLE_PUBLISH_MODAL',
        previewNotification: 'PREVIEW_NOTIFICATION',
        testNotification: 'TEST_NOTIFICATION',
        editNotification: 'EDIT_NOTIFICATION',
        notificationSendingWarning: 'NOTIFICATION_SENDING_WARNING',
        documentGroupDelete: 'DOCUMENT_GROUP_DELETE',
    },
};
