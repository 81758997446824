import {FloteEndpointGroup} from "@/lib/api/endpoints/FloteEndpointGroup";

const CACHE_PROPERTY = "_cache";

export function Cached() {
    return (api: FloteEndpointGroup, key: string, descriptor: PropertyDescriptor) => {
        return {
            ...descriptor,
            value: function (...args: any[]) {
                const cached = descriptor.value[CACHE_PROPERTY];
                if (cached) {
                    return descriptor.value.call(this, ...args).resolve(cached);
                }
                return descriptor.value.call(this, ...args).then((res) => {
                    descriptor.value[CACHE_PROPERTY] = res;
                    return res;
                })
            }
        }
    }
}
