import {BaseFloteEntity} from "@/services/entities/sponsor/BaseFloteEntity";
import {NotificationStatuses} from "@/services/store/sponsor/modules/notifications/interfaces";
import {AccountStatus, getAccountStatus} from "@/lib/contact/AccountStatus";
import {getNotificationStatus, NotificationStatus} from "@/lib/notifications/NotificationStatus";

export class User extends BaseFloteEntity {
    private updateValues(data) {
        this.accountStatus = getAccountStatus(data);
        if (data.latestNotification) {
            this.notificationStatus = getNotificationStatus(data);
        }
    }

    public set(data: {}) {
        super.set(data);
        this.updateValues(data);
    }

    address: string;
    addressCity: string;
    addressCountry: string;
    addressPostalCode: string;
    addressState: string;
    addressStreetAddress: string;
    birthDate: string;
    defaultTimezone: string;
    displayName: string;
    email: string;
    firstName: string;
    fullName: string;
    id: string;
    isEmailConfirmed: boolean;
    lastName: string;
    nickname: string;
    phone: string;
    profilePhoto: string;
    latestNotification: NotificationStatuses;
    notificationStatus: NotificationStatus = NotificationStatus.Blank;
    accountStatus: AccountStatus = AccountStatus.NONE;
    isAccredited: boolean;
    isChecked: boolean;
    isActive: boolean;
    isRegistered: boolean;
    justCopied: boolean = false;
}
