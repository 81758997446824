import actions from './documentsActions';
import getters from './documentsGetters';
import state from './documentsState';
import mutations from './documentsMutations';

const documents = {
  actions,
  getters,
  mutations,
  state,
};

export default documents;
