<template>
    <label class="checkbox-container"
           :class="`${className ? className : ''}`"
           @click.stop>
        <input type="checkbox"
               hidden
               :checked="isChecked"
               v-model="isChecked"
               @input="handleChange"/>
        <span class="tick-container" :class="`${isBig ? 'big' : ''}`"/>
        <slot></slot>
    </label>
</template>

<script>
export default {
    name: "Checkbox",
    data() {
        return {
            isChecked: this.value,
        };
    },
    props: {
        value: Boolean,
        isBig: {
            type: Boolean,
            default: false,
        },
        className: String,
    },
    watch: {
        value: {
            handler: function () {
                this.isChecked = this.value ? this.value : false;
            },
            immediate: true,
        },
    },
    methods: {
        handleChange(e) {
            this.$emit("check", e.target.checked);
        },
    }
}
</script>
