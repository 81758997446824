import getByQuarters from "../../../../helpers/investor/filterChartData";
import TrimText from "@/services/helpers/sponsor/trimText";

export default {
    getLineChartCumulatives(state) {
        return {
            commitment: getByQuarters(state.lineChartData).map(item => item.cumulativeTotalCommitment),
            contribution: getByQuarters(state.lineChartData).map(item => item.cumulativeTotalContribution),
            distribution: getByQuarters(state.lineChartData).map(item => item.cumulativeTotalDistribution)
        }
    },
    getTransactionMaxValue(state) {
        const lastQuarterData = state.lineChartData[state.lineChartData.length - 1];
        const lastQuarterTotals = [
            lastQuarterData.cumulativeTotalCommitment,
            lastQuarterData.cumulativeTotalContribution,
            lastQuarterData.cumulativeTotalDistribution
        ];
        return Math.max.apply(Math, lastQuarterTotals);
    },
    getLineChartLabels(state) {
        let labels = state.lineChartData.map(
            item => `Q${item.quarter}'${item.year.toString().substr(-2)}`
        );
        return [...new Set(["", ...labels])]
    },
    getDoughnutChartLabels(state, getters) {
        const filteredData = getters.getFilteredDoughnutData;
        return {
            trimmedLabels: filteredData.map(item => TrimText(item.legalName, 20)),
            labels: filteredData.map(item => (item.legalName))
        }
    },
    getNewDoughnutBackgroundData(state, getters) {
        const filteredData = getters.getFilteredDoughnutData;

        if (filteredData.length > 5) {
            const additional = 5 - filteredData.length;
            for (let i = 0; i < additional; i++) {
                const r = Math.floor(Math.random() * 255);
                const g = Math.floor(Math.random() * 255);
                const b = Math.floor(Math.random() * 255);
                const c = `rgb(${r}, ${g}, ${b})`;
                const h = `rgb(${r + 20}, ${g + 20}, ${b + 20})`;
                state.newStateData.push(c);
                state.hoveredColorData.push(h);
            }
        }
        return state.newStateData;
    },
    getFilteredDoughnutData(state) {
        return state.doughnutData.filter(item => item.percent !== '0');
    },
    getDoughnut(state, getters) {
        const filteredData = getters.getFilteredDoughnutData;

        return [
            {
                data: filteredData.map(item => item.percent),
                totalAmount: filteredData.map(item => item.totalCommitment),
                backgroundColor: getters.getNewDoughnutBackgroundData,
                hoverBackgroundColor: state.hoveredColorData,
            },
        ];
    },
};
