import {ListResponse} from "@/lib/api/response/ListResponse";
import {Mutation} from "@/lib/store/modules/Mutation";
import {Pagination} from "@/lib/store/state/list/Pagination";
import Vue from "vue";
import {Action} from "@/lib/store/modules/Action";
import {ListState, SelectableItem} from "@/services/store/lib/ListState";
import {FiltersState} from "@/lib/store/state/filters/FiltersState";
import {Getter} from "@/lib/store/modules/Getter";
import {BasicModule} from "@/lib/store/modules/BasicModule";
import {FloteApi} from "@/services/api/FloteApi";

export default class ResourceListModule<Resource, AdditionalListState = {}> extends BasicModule<ListState<Resource, AdditionalListState>> {
    public state = new ListState<Resource, AdditionalListState>(this.getAdditionalListData([]), this.getFilters());
    public defaultExportFileName: string = "export.csv";

    public getFilters(): FiltersState {
        return new FiltersState();
    }

    public getSorter(): (a: Resource, b: Resource) => number {
        return null;
    }

    public getAdditionalListData(items: Resource[]): AdditionalListState {
        return {} as AdditionalListState;
    }

    public getListItem(item: Resource): SelectableItem<Resource> {
        item["isSelected"] = false;
        return item as SelectableItem<Resource>;
    }

    @Getter.Register()
    public get areAllSelected() {
        if (this.state.allItems.length > 0) {
            const hasUnSelectedItem = !!this.state.allItems.find(i => i.isSelected === false);
            return !hasUnSelectedItem;
        }
        return false;
    }

    @Getter.Register()
    public get hasAnySelectedItems() {
        return !!this.state.allItems.find(i => i.isSelected);
    }

    @Getter.Register()
    public get selectedItems() {
        return this.state.allItems.filter(i => i.isSelected);
    }

    @Mutation.Register()
    public unSelectAll() {
        for (const item of this.state.allItems) {
            item.isSelected = false;
        }
    }

    @Mutation.Register()
    public selectAll() {
        for (const item of this.state.allItems) {
            item.isSelected = true;
        }
    }

    @Mutation.Register()
    public toggleAll() {
        const allSelected = this.areAllSelected;
        for (const item of this.state.allItems) {
            item.isSelected = !allSelected;
        }
    }

    @Mutation.Register()
    public toggleItem(item) {
        item.isSelected = !item.isSelected;
    }

    @Mutation.Register()
    public setData(data: ListResponse<Resource>) {
        let items = data.items;

        const sorter = this.getSorter();
        if (sorter) {
            items = items.sort(sorter);
        }

        this.state.allItems = items.map(i => this.getListItem(i));
        this.state.pagination = new Pagination(data);

        const additional = this.getAdditionalListData(items);
        for (const key of Object.keys(additional)) {
            Vue.set(this.state.additional, key, additional[key]);
        }
    }

    @Action.Register()
    public async loadData() {
        this.state.loader.startTask();
        const data = await this.api.list().filter(this.state.filters.toParams());
        this.setData(data);
        this.state.loader.completeTask();
    }

    @Action.Register()
    public async loadFilterData() {
        await this.state.filters.loadAll(this.portalApi.rootApi as FloteApi, this.portalApi);
    }

    @Action.Register()
    public async loadDataWithFilters() {
        await this.loadData();
        await this.loadFilterData();
    }

    @Action.Register()
    public async downloadExported(fileName?: string) {
        this.state.exportDownloadLoader.startTask();
        const res = await this.api.downloadCsv().filter(this.state.filters.toParams());
        res.save(fileName || this.defaultExportFileName);
        this.state.exportDownloadLoader.completeTask();
    }
}
