import {FloteApiError} from "@/lib/api/errors/FloteApiError";
import {Constructor} from "@/lib/types";
import {FloteRequest} from "@/lib/api/request/FloteRequest";
import {FloteResponse} from "@/lib/api/response/FloteResponse";
import {AxiosError} from "axios";

export class RequestErrorHandler<T extends FloteApiError, R extends FloteResponse> {
    public readonly errorClass: Constructor<T>;
    public readonly handler: (request: FloteRequest<R>, error: T) => Promise<R>;
    constructor(errorClass: Constructor<T>, handler: (request: FloteRequest<R>, error: T) => Promise<R>) {
        this.errorClass = errorClass;
        this.handler = handler;
    }

    public async handle(request: FloteRequest<R>, error: AxiosError): Promise<R> {
        if (error instanceof this.errorClass) {
            return this.handler(request, error);
        }
        return Promise.reject(error);
    }
}
