import {taxTypes} from "@/config/sponsor/taxTypes";
import {DefaultFormatter} from "@/lib/formatters/DefaultFormatter.ts";

export abstract class BaseTaxIdFormatter extends DefaultFormatter {
    public format(value: string): string {
        let formatted = this.safeValue(value);
        formatted = formatted.slice(0, -4).replace(/\d/g, "*")
            + formatted.slice(-4);
        return this.maskWIthAsterisks(formatted);
    }

    public mask(value: string): string {
        return this.maskWIthAsterisks(value).replace(/\*/g, "");
    }

    public unmask(value: string): string {
        return super.unmask(value.replace(/\D/g, ""));
    }

    public abstract maskWIthAsterisks(value: string): string;
}

export class EINFormatter extends BaseTaxIdFormatter {
    public maskWIthAsterisks(value: string): string {
        return this.format(value);
    }

    public format(value: string): string {
        value = this.safeValue(value);
        const parts = [value.slice(0, 2)];
        if (value.length > 2) {
            parts.push(value.slice(2));
        }
        return parts.join("-");
    }
}

export class SSNFormatter extends BaseTaxIdFormatter {
    public maskWIthAsterisks(value: string): string {
        const formatted = this.safeValue(value).replace(/[^\d*]/g, "");
        const parts = [formatted.slice(0, 3)];
        if (formatted.length > 3) {
            parts.push(formatted.slice(3, 5));
        }
        if (formatted.length > 5) {
            parts.push(formatted.slice(5));
        }
        return parts.join("-");
    }
}

export class TaxIdFormatter extends DefaultFormatter {
    private formatter: DefaultFormatter;

    constructor(taxIdType: string) {
        super();
        if (taxIdType === taxTypes.SSN) {
            this.formatter = new SSNFormatter();
        } else if (taxIdType === taxTypes.EIN) {
            this.formatter = new EINFormatter();
        } else {
            this.formatter = new DefaultFormatter();
        }
    };

    public mask(value: string): string {
        return this.formatter.mask(value);
    };

    public unmask(value: string): string {
        return this.formatter.unmask(value);
    };

    public format(value: string): string {
        return this.formatter.format(value);
    }
}
