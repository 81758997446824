import {DefaultFormatter} from "@/lib/formatters/DefaultFormatter";
import {ValueFormatter} from "@/lib/formatters/ValueFormatter";

export class SensitiveDataFormatter extends DefaultFormatter {
    private formatter: ValueFormatter;

    constructor(formatter?: ValueFormatter) {
        super();
        this.formatter = formatter || new DefaultFormatter();
    }

    public mask(value: string): string {
        return this.format(value);
    }

    public unmask(value: string): string {
        return this.formatter.unmask(value);
    }

    public format(value: string): string {
        let formatted = this.formatter.format(value);
        formatted = formatted.slice(0, -4).replace(/[A-Za-z0-9]/g, "*")
            + formatted.slice(-4);
        return formatted;
    }
}
