// eslint-disable-next-line import/no-cycle
import RequestManager from '../../../../helpers/investor/managers/requestManager';
import { COUNTRIES_LIST, ETC, STATE } from '../../config/actionUrls';
import O_o from '../../../../helpers/investor/promiseHandler';
import { GET } from '../../config/actionMethods';
import {
  SET_COUNTRIES_LIST,
} from '../../config/mutationTypes';

export default {
  async getInvestorCountriesList({ commit }, data) {
    const [error, response] = await O_o(
      RequestManager.send(GET(COUNTRIES_LIST)),
    );
    if (error) {
      throw error;
    }
    commit(SET_COUNTRIES_LIST, response);
    return response;
  },
  async getInvestorStatesList({commit}, data) {
    const [error, response] = await O_o(
      RequestManager.send(GET(`${ETC}/${data.country}${STATE}`))
    );
    if(error) {
      throw error;
    }
    commit(`set${data.tag}StatesList`, response);
  },
};
