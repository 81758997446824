import {FloteResponse} from "@/lib/api/response/FloteResponse";

export class FileResponse extends FloteResponse {
    protected data: Blob;

    public save(fileName: string) {
        const url = window.URL.createObjectURL(this.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    }
}
