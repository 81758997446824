import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {NotificationPreview} from "@/services/store/sponsor/modules/notifications/interfaces";
import {
    SET_CONTACT_NOTIFICATION_PREVIEW,
    SET_ERROR_HANDLER,
} from "@/services/store/sponsor/storeConfigs/mutationTypes";
import O_o from "@/services/helpers/sponsor/promiseHandler";
import http from "@/services/helpers/sponsor/managers/requestManager";
import {GET, POST} from "@/services/helpers/sponsor/actionMethods";
import {
    NOTIFICATIONS,
    PREVIEW,
    WELCOME
} from "@/services/store/sponsor/storeConfigs/actionUrls";
import {NotificationChannel} from "@/services/store/sponsor/modules/notifications/enums";

@Module({namespaced: true})
class ContactNotificationModule extends VuexModule {
    public contactNotificationPreview: NotificationPreview = {
        notSendingReasons: [],
        payloads: [],
    }

    @Mutation
    public [SET_CONTACT_NOTIFICATION_PREVIEW](data) {
        this.contactNotificationPreview = data;
    }

    @Action
    public async getContactNotificationPreview(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});
        const [error, response] = await O_o(http.send(GET(`${NOTIFICATIONS}${WELCOME}${PREVIEW}/${data.id}`)));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

        if (error) {
            this.context.commit(SET_ERROR_HANDLER, error, {root: true});
            throw error;
        }
        this.context.commit(SET_CONTACT_NOTIFICATION_PREVIEW, response);
        return response;
    }

    @Action
    public async notify(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});

        const [error, response] = await O_o(http.send(POST(`${NOTIFICATIONS}${WELCOME}/send`), {
            triggerIds: data.ids,
            attachDocs: data.attachDocs
        }));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

        return response;
    }

    @Action
    public async sendTestNotification(data) {
        await this.context.dispatch("setLoaderForRequests", null, {root: true});

        const [error, response] = await O_o(http.send(POST(`${NOTIFICATIONS}${WELCOME}/test/${data.contactId}`), {
            email: data.email,
            attachDocs: data.attachDocs
        }));

        await this.context.dispatch("removeLoaderForRequests", null, {root: true});

        return response;

    }

    get contactPreview() {
        return this.contactNotificationPreview.payloads.find(i => i.channel === NotificationChannel.Email)
    }
}

export default ContactNotificationModule;
