<template>
    <div :class="`${isDisabled ? 'not-disabled' : 'active-text'}`"
         class="input-container select-wrapper">
        <label class="placeholder" :for="id" :class="{'transparent-text': !selectedOption}">
            {{ optionPlaceholder }} <span class="required" v-if="isRequired">*</span>
        </label>
        <select v-model="selectedOption"
                @input="event => emitValue(event.target.value)"
                @change="execute"
                :data-selected="selectedOption"
                class="select-box"
                :class="{'error': error, 'disabled-select': isInputDisabled}"
                :disabled="isInputDisabled"
                :id="id">
            <option
                v-for="(option, index) in options"
                :value="option.value"
                :key="index">
                {{ option.text }}
            </option>
        </select>
        <div class="error-message" v-if="error">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
import {guidGenerator} from '../../../../services/helpers/sponsor/randomId';
import {validationErrorMessages} from "@/config/sponsor/validationErrorConfigs";

export default {
    props: {
        value: String,
        changeEffect: Function,
        isInputDisabled: {
            type: Boolean,
            default: false,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        prefill: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        error: String,
        id: {
            type: String,
            default: () => guidGenerator(),
        },
    },
    data() {
        return {
            selectedOption: null,
            isDisabled: false,
            optionPlaceholder: null,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler: function (newValue) {
                if (newValue === '') {
                    newValue = null
                }
                this.selectedOption = newValue;
                this.checkIsDisabled();
            },
        },
        options: {
            immediate: true,
            handler: function () {
                if (this.prefill && this.options.length === 2) {
                    this.emitValue(this.options[1].value);
                }
            },
        },
    },
    computed: {
        errorMessage() {
            if (this.value === null && this.isRequired && this.error) {
                return validationErrorMessages.required
            }
            if (this.value === '' && this.error) {
                return this.error;
            }
            return null;
        }
    },
    methods: {
        emitValue(val) {
            this.$emit('input', val);
        },
        execute() {
            if (this.changeEffect) {
                this.$emit("changed");
                this.changeEffect();
            } else {
                this.$emit("changed");
            }
        },
        checkIsDisabled() {
            this.options.find(item => {
                if (item.value === this.selectedOption) {
                    this.updatePlaceholder();
                    return this.isDisabled = item.disabled;
                }
            })
        },
        updatePlaceholder() {
            this.options.find(item => {
                if (item.disabled) {
                    return this.optionPlaceholder = item.text;
                }
                return this.optionPlaceholder = null;
            })
        }
    },
};
</script>

<style scoped lang="scss">
.select-wrapper {
    &:after {
        $arrowSize: 10px;
        content: "";
        background: url("~@/assets/sponsor/images/arrow-left copy.svg") no-repeat center/ 10px;
        display: block;
        width: $arrowSize;
        height: $arrowSize;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    .select-box {
        @include removeAppearance();
        padding-right: 25px;

        option:first-child {
            color: $color-grey-dark;
        }
    }

    .disabled-select {
        opacity: 0.5;
    }
}
</style>
