import {BasicNavigator} from "@/core/navigation/BasicNavigator";
import {EntityEditSponsorRoute} from "@/services/router/sponsor/entities";
import {EntityEditInvestorRoute} from "@/services/router/investor/entities";

export class EntitiesSponsorNavigator extends BasicNavigator {
    public edit(contactId: string, id: string) {
        return this.route(EntityEditSponsorRoute, {contactId: contactId, id: id});
    }
}

export class EntitiesInvestorNavigator extends BasicNavigator {
    public edit(id: string) {
        return this.route(EntityEditInvestorRoute, {id: id});
    }
}
