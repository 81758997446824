import {RouteClass} from "@/lib/router/Route";
import router from "@/services/router/router";
import {Pojo} from "@/lib/types";

export function navigateTo(route: RouteClass, params?: Pojo) {
    return router.push({
        name: route.Name,
        ...(params ? {params: params} : {}),
    });
}

/**
 * @deprecated use Route.Navigate() method
 */
export function routerNavigateTo(name: string, routerParams?: Pojo) {
    router.push({
        name,
        ...(routerParams ? {params: routerParams} : {}),
    });
}

export function goBack() {
    router.go(-1);
}
