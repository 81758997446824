import {Router} from "@/lib/router/Router";
import {Route} from "@/lib/router/Route";
import {ProjectsRoute, ProjectsRouter} from "@/services/router/sponsor/projects";
import {AssetsRouter} from "@/services/router/sponsor/assets";
import {ContactsRouter} from "@/services/router/sponsor/contacts";
import {DocumentsRouter} from "@/services/router/sponsor/documents";
import {NotificationsRouter} from "@/services/router/sponsor/notifications";
import {OfferingsRouter} from "@/services/router/sponsor/offerings";
import {TransactionsRouter} from "@/services/router/sponsor/transactions";
import {VehiclesRouter} from "@/services/router/sponsor/vehicles";
import {AffiliatesSponsorRouter} from "@/services/router/sponsor/affiliates";
import {EntitiesSponsorRouter, EntityEditSponsorRoute} from "@/services/router/sponsor/entities";

export const SponsorRouter = new Router();

@SponsorRouter.register()
export class LoginRoute extends Route {
    getComponent() {
        return import("@/views/Login.vue");
    }

    getPath(): string {
        return "/admin/login";
    }
}

@SponsorRouter.register()
export class SponsorResetPasswordRoute extends Route {
    getComponent() {
        return import("@/views/SponsorAuth/ResetPassword.vue");
    }

    getPath(): string {
        return "/admin/reset-password";
    }
}

@SponsorRouter.register()
export class SponsorChangePasswordRoute extends Route {
    getComponent() {
        return import("@/views/SponsorAuth/ChangePassword.vue");
    }

    getPath(): string {
        return "/admin/change-password";
    }
}

@SponsorRouter.register()
export class SponsorAccountSetupRoute extends Route {
    getComponent() {
        return import("@/components/investor/Common/AuthResetPassword.vue");
    }

    getPath(): string {
        return "/admin/auth/setup";
    }
}

@SponsorRouter.register()
export class SponsorResetPasswordConfirmRoute extends Route {
    getComponent() {
        return import("@/components/investor/Common/AuthResetPassword.vue");
    }

    getPath(): string {
        return "/admin/auth/reset-password";
    }
}

@SponsorRouter.register()
export class HomeRoute extends Route {
    getComponent() {
        return import("@/components/sponsor/Home.vue");
    }

    getPath(): string {
        return "/admin";
    }

    getConfig(): {} {
        return {
            redirect: {name: ProjectsRoute.Name}
        };
    }
}

HomeRoute.addRouter(AssetsRouter);
HomeRoute.addRouter(ContactsRouter);
HomeRoute.addRouter(DocumentsRouter);
HomeRoute.addRouter(NotificationsRouter);
HomeRoute.addRouter(OfferingsRouter);
HomeRoute.addRouter(ProjectsRouter);
HomeRoute.addRouter(TransactionsRouter);
HomeRoute.addRouter(VehiclesRouter);
HomeRoute.addRouter(AffiliatesSponsorRouter);
HomeRoute.addRouter(EntitiesSponsorRouter);

export const DefaultRoute = ProjectsRoute;
