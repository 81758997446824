import moment from "moment-timezone";
import {config} from "@/lib/config";

export function formatDate(value: Date | string): string {
    if (!value) {
        return null;
    }

    return moment(value)
        .tz(config.DEFAULT_TIMEZONE)
        .format("ll");
}
