import initialStates from '../../../../../config/sponsor/initialStates';

export default {
    isEditingTransaction: false,
    editingTransactionId: null,
    deletingContributionId: null,
    transaction: initialStates.transactionInitialState,
    createContribution: {
        items: [],
    },
    transactionDocUploadData: {
        description: "",
        date: "",
        type: "",
    },
};
